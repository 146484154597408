import React, {useEffect, useState} from "react";
import {TournamentSummaryDetails} from "./TournamentSummaryDetails";
import {PlayerList} from "./PlayerList";
import {CrossTable} from "./Crosstable";
import isUUID from "validator/es/lib/isUUID";
import {Tab, TabSet} from "../core/Tabs";
import {ResultsEditor} from "../../pages/TournamentEditPage/ResultsEditor";
import {StartTournamentButton} from "./StartTournamentButton";
import {EditableTableCell} from "../core/table/EditableTableCell";
import {LoggedInUserLozenge} from "../LoggedInUserLozenge";
import {TournamentImportModal} from "../TournamentImportModal";
import {TournamentSettings} from "./TournamentSettings/TournamentSettings";
import {LoadingSpinner} from "../LoadingSpinner";
import {isBlank, tournamentSize} from "../../util/GeneralUtil";
import {PlayerResultsModal} from "./PlayerResultsModal/PlayerResultsModal";
import {RemovePlayerButton} from "./RemovePlayerButton";
import {Button} from "../core/Button/Button";
import {executeRequest} from "../../util/RequestUtil";
import axios from "axios";
import {findLink, findSelfLink} from "../../util/HateoasUtil";
import {VegaUploadButton} from "./VegaUploadButton";
import {EditTournamentPlayerModal} from "./EditTournamentPlayerModal";

const SwapTeamButton = ({member, onUpdated}) => {


    const [, setSaving] = useState(false);
    const [, setError] = useState(null);

    const onSwapTeam = () => {
        executeRequest(axios.get(findLink(member, "swapTeam")), setSaving, setError, () => {
            onUpdated();
        });
    }

    return <Button className="mr-2" compact={true} onClick={onSwapTeam} color="gray">Swap Team</Button>
}

const EditTournament = ({history, tournament, refreshTournament, onUpdated, view}) => {

    const [showImportModal, setShowImportModal] = useState(false);

    const [selectedPlayerId, setSelectedPlayerId] = useState(null);

    // const tournament = useSelector(selectEditingTournament);
    const status = tournament.status;
    const type = tournament.type ?? "SWISS_SYSTEM";
    const editMode = tournament.userCanEdit;
    const layoutSize = tournament.layoutSize;
    const teamA = tournament.teamA;
    const teamB = tournament.teamB;
    const fideMode = tournament.fideMode == null ? true : tournament.fideMode;


    useEffect(() => {
        const PREFIX = view ? "/view/" : "/tournaments/edit/";
        const existingUri = history.location.pathname.replace(PREFIX, "");
        if (!isUUID(existingUri)) {
            history.replace(PREFIX + tournament.uuid)
        }

    }, [history, tournament, view]);

    const isStarted = status === "STARTED" || status === "COMPLETE"
    const isSwiss = type === "SWISS_SYSTEM";
    const isTeamVsTeam = type === "TEAM_VS_TEAM";

    const renderHeader = () => {

        const sizeStyles = tournamentSize(view, layoutSize,
            " px-2 py-1 text-xs text-center",
            " px-2 py-1 text-sm text-center",
            " px-4 py-2 text-center");


        return <thead>
        <tr>
            {/*{fideMode && <th className={"border border-gray-500" + sizeStyles}>FIDE Id</th>}*/}
            <th className={"border border-gray-500" + sizeStyles}>Name</th>
            {/*{fideMode && <th className={"border border-gray-500" + sizeStyles}>FIDE Rating</th>}*/}
            <th className={"border border-gray-500 w-24" + sizeStyles}>Rating </th>
            <th className={"border border-gray-500 w-24" + sizeStyles}>Score</th>
            {editMode && <th className={"border border-gray-500 w-24" + sizeStyles}>Manage</th>}
        </tr>
        </thead>
    }

    const renderMemberRow = (member, refreshMembers, playerOptions) => {

        const generatedRounds = tournament.generatedRounds;
        const tooLateToEdit = generatedRounds >= 4 && isSwiss && fideMode;

        const sizeStyles = tournamentSize(view, layoutSize,
            " px-2 py-1 text-xs text-center",
            " px-2 py-1 text-sm text-center",
            " px-4 py-2 text-base text-center");

        return <tr className={"bg-white "} key={member.uuid} onClick={() => {
            if (!editMode) {
                setSelectedPlayerId(member.uuid);
            }
        }}>
            {/*{fideMode && <td className={"border border-gray-500 w-12" + sizeStyles}>{member.fideId}</td>}*/}
            <EditableTableCell
                view={view}
                size={layoutSize}
                editMode={false}
                entity={member}
                field={"name"}>
                <span className="ml-2"><LoggedInUserLozenge loggedInUser={member.loggedInUser} /></span>
            </EditableTableCell>

            {/*{fideMode && <td className={"border border-gray-500 w-12" + sizeStyles}>{member.fideRating}</td>}*/}

            <EditableTableCell
                view={view}
                center={true}
                size={layoutSize}
                editMode={false}
                entity={member}
                field={"rating"} />

            <td className={"border border-gray-500 w-12" + sizeStyles}>{member.score}</td>
            {editMode && <td className={"border border-gray-500 " + sizeStyles}>
                <div className="flex flex-row">
                    {playerOptions && playerOptions}
                    <>
                        <EditTournamentPlayerModal tournament={tournament} player={member} onUpdated={refreshMembers} tooLateToEdit={tooLateToEdit} />
                        <RemovePlayerButton className="ml-2" player={member} onUpdated={refreshTournament} />
                    </>
                </div>
            </td>}
        </tr>
    }

    const publicUri = window.location.href.replace("/tournaments/edit/", "/view/");

    const showTournamentName = (!view || (view && tournament.showTournamentName));

    const downloadTrf16 = () => {
        axios.get(findSelfLink(tournament) + "/trf16", {responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.txt'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
    }

    if (!tournament) {
        return <div>
            <LoadingSpinner title="Loading tournament..."/>
        </div>
    }

    const defaultViewTab = isBlank(tournament.defaultTab) || tournament.defaultTab === "Details" ? "Players" : tournament.defaultTab;

    return <>

        <div className={"w-full " + (showTournamentName ? "pt-2" : "-ml-4 -mt-4")}>

            <PlayerResultsModal
                view={view}
                tournament={tournament}
                playerId={selectedPlayerId}
                onClose={() => setSelectedPlayerId(null)} />

            {showTournamentName && <div className="text-2xl ">
                {tournament?.name}
            </div>}

            {!view && <div className="text-sm mt-1">
                <span className="bold ml-2">Public link:</span>
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <span className="font-light italic ml-1"><a href={publicUri} target="_blank">{publicUri}</a></span>
            </div>}
            {!view && <div className="mt-2 flex flex-row">
                <StartTournamentButton tournament={tournament} refreshTournament={refreshTournament} />
                <VegaUploadButton tournament={tournament} />
                <Button className="ml-2" compact={true} onClick={downloadTrf16} color="gray">Download TRF16</Button>
            </div>}

            <div className={"" + (showTournamentName ? "mt-2" : "")}>

                <TournamentImportModal
                    tournament={tournament}
                    visible={showImportModal}
                    onUpdated={() => {
                        refreshTournament();
                        setShowImportModal(false)
                    }}
                    onClose={() => {
                        setShowImportModal(false)
                    }}  />
            </div>

            <div className="w-full pr-2">
                <TabSet defaultTab={view ? defaultViewTab : null}>

                    {!view && <Tab name="Details">
                        <TournamentSummaryDetails tournament={tournament} viewMode={view} refreshTournament={refreshTournament} onUpdated={onUpdated} />
                    </Tab>}

                    {tournament.type === "TEAM_VS_TEAM" && <Tab name={isBlank(teamA) ? "Team A" : teamA}>
                        <div className="shadow-lg">
                            <PlayerList
                                entity={tournament}
                                team={"A"}
                                renderHeader={renderHeader}
                                renderRow={(m, refreshMembers) => renderMemberRow(m, refreshMembers, <SwapTeamButton member={m} onUpdated={refreshMembers}/>)}
                                canAddPlayers={isSwiss || isTeamVsTeam || !isStarted}
                                editMode={editMode}
                                size={layoutSize}
                                viewMode={view}/>
                        </div>
                    </Tab>}
                    {tournament.type === "TEAM_VS_TEAM" && <Tab name={isBlank(teamB) ? "Team B" : teamB}>
                        <div className="shadow-lg">
                            <PlayerList
                                entity={tournament}
                                team={"B"}
                                renderHeader={renderHeader}
                                renderRow={(m, refreshMembers) => renderMemberRow(m, refreshMembers, <SwapTeamButton member={m} onUpdated={refreshMembers}/>)}
                                canAddPlayers={isSwiss || isTeamVsTeam || !isStarted}
                                editMode={editMode}
                                size={layoutSize}
                                viewMode={view}/>
                        </div>
                    </Tab>}
                    {tournament.type !== "TEAM_VS_TEAM" && <Tab name="Players">
                        <div className="shadow-lg">
                            <PlayerList
                                entity={tournament}
                                renderHeader={renderHeader}
                                renderRow={(m, refreshMembers) => renderMemberRow(m, refreshMembers)}
                                canAddPlayers={isSwiss || isTeamVsTeam || !isStarted}
                                editMode={editMode}
                                size={layoutSize}
                                viewMode={view}/>
                        </div>
                    </Tab>}

                    {isStarted && <Tab name="Pairings">
                        <ResultsEditor tournament={tournament} viewMode={view} />
                    </Tab>}

                    {isStarted && <Tab name="Crosstable">
                        <CrossTable tournament={tournament} viewMode={view} />
                    </Tab>}

                    {!view && <Tab name="Settings">
                        <TournamentSettings tournament={tournament} />
                    </Tab>}
                </TabSet>
            </div>
        </div>
    </>
}

export default EditTournament;
