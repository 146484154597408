import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class Button extends React.Component {
    render() {
        const { icon, children, compact = false, color, className, disabled, ...props } = this.props;
        let classes = (className || '').split(' ');
        classes.push('font-bold', 'rounded');

        if (compact) {
            classes.push('px-3', 'py-1');
        } else {
            classes.push('px-4', 'py-2');
        }

        if (disabled) {
            classes.push('bg-gray-400', 'text-white')
        } else {
            switch (color) {
                default:
                case 'green':
                    classes.push('bg-green-600', 'hover:bg-green-400', 'text-white');
                    break;
                case 'blue':
                    classes.push('bg-blue-600', 'hover:bg-blue-400', 'text-white');
                    break;
                case 'teal':
                    classes.push('bg-teal-700', 'hover:bg-teal-500', 'text-white');
                    break;
                case 'gray':
                    classes.push('bg-gray-600', 'hover:bg-gray-400', 'text-white');
                    break;
                case 'purple':
                    classes.push('bg-purple-800', 'hover:bg-purple-900', 'text-white');
                    break;
                case 'black':
                    classes.push('bg-black', 'hover:bg-gray-600', 'text-white');
                    break;
                case 'red':
                    classes.push('bg-red-700', 'hover:bg-red-500', 'text-white');
                    break;
            }
        }

        let wrapperClasses = "whitespace-no-wrap ";
        if (icon && children) {
            wrapperClasses = "ml-2"
        }

        return (
            <button
                className={classes.join(' ')}
                {...props}>
                {icon && <FontAwesomeIcon icon={icon} />}
                <span className={wrapperClasses}>{children}</span>
            </button>
        );
    }
}
