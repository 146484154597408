import React from "react";

// Adapted from here ("Modern with Badge"): https://tailwindcss.com/components/alerts
export const UserMessage = ({visible, lozengeMessage, message, showNext, backgroundClass, lozengeClass}) => {
    return visible ? <div className={"p-1 items-center leading-none lg:rounded-full flex lg:inline-flex " + backgroundClass}
         role="alert">
        {lozengeMessage && <span className={"flex rounded-full uppercase px-2 py-1 text-xs font-bold mr-3 " + lozengeClass}>{lozengeMessage}</span>}
        <span className="font-semibold mr-3 text-left flex-auto">{message}</span>
    </div>  : null;
}
