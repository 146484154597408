import React from "react";
import {Menu, MenuItem, SubMenu} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/core.css';
import "./clubWebsiteStyles.css"
import MenuIcon from "@mui/icons-material/Menu";

const ClubWebsiteMenuItem = ({item, onItemSelected}) => {
    return <>
        {item.children.length === 0 && <MenuItem onClick={() => onItemSelected(item)}>{item.label}</MenuItem>}
        {item.children.length > 0 && <SubMenu label={item.label}>
            {item.children.map(c => <ClubWebsiteMenuItem key={c.uuid} item={c} />)}
        </SubMenu>}
    </>
}

export const ClubWebsiteMenu = ({menu, onPageSelected, hamburger = false}) => {

    const onItemSelected = (item) => {
        onPageSelected(item.pageId);
    }

    if (menu.children.length === 0) {
        return <div className="font-bold" onClick={() => onItemSelected(menu)}>{menu.label}</div>
    } else {
        return (
            <Menu menuClassName="noBorder semiTransparent" menuButton={hamburger ? <MenuIcon /> : <div className="font-bold">{menu.label}</div>}>
                {menu.children.map(c => <ClubWebsiteMenuItem key={c.uuid} item={c} onItemSelected={onItemSelected} />)}
            </Menu>
        );
    }

}
