import {Button} from "../core/Button/Button";
import React, {useState} from "react";
import {isVegaTournament} from "../../util/EntityUtil";
import {TournamentImportModal} from "../TournamentImportModal";
import {showSuccessToast} from "../../util/Alerts";

export const VegaUploadButton = ({tournament}) => {

    const [showingUpload, setShowingUpload] = useState(false);

    return isVegaTournament(tournament) ? <div>
        <TournamentImportModal
            visible={showingUpload}
            tournament={tournament}
            onClose={() => setShowingUpload(false)}
            onUpdated={() => {
                setShowingUpload(false);
                showSuccessToast("Tournament updated");
            }}/>
        <Button className="ml-2" compact={true} color="gray" onClick={() => setShowingUpload(true)}>Update from file</Button>
    </div> : null;

}
