import React from "react";
import {Button} from "../../../../components/core/Button/Button";
import {tournamentSize} from "../../../../util/GeneralUtil";
import {findSelfLink} from "../../../../util/HateoasUtil";

export const RoundSelector = ({tournament, viewMode, rounds, selectedRound, onRoundChanged}) => {

    const layoutSize = tournament.layoutSize;

    const titleSize = tournamentSize(viewMode, layoutSize, "text-base", "text-lg", "text-2xl");
    const buttonSize = tournamentSize(viewMode, layoutSize, "text-xs", "text-sm", "text-base");

    return <div className="p">
        <span className={"mr-2 " + titleSize}>Round</span>
        {rounds?.map((r, idx) => <span key={findSelfLink(r)} className={"mr-1 " + buttonSize}>
            <Button
                onClick={() => onRoundChanged(idx)}
                color={selectedRound === idx ? "purple" : "gray"}>
                {r.name}
            </Button>
        </span>)}
    </div>
}
