import {PageContainer} from "../components/layout/PageContainer/PageContainer";
import React from "react";


export const ToSPage = () => {
    return <PageContainer>

        <div className="flex align-middle justify-center content-center">

            <div className="font-light p-4 w-6/12">
                <div className="text-3xl mb-2">Terms of Service</div>
                <p>By using the MoveKeep app, you agree to the terms set out in this document, and the Privacy Policy.</p>

                <p className="mt-2">If you do not agree to these terms, you may not use the app or its associated services, and must uninstall the app from your devices.</p>

                <div className="text-2xl mb-2 mt-4">Software license</div>
                We grant you a non-transferrable, non-perpetual, non-exclusive license to use the MoveKeep app and its associated services.

                <div className="text-2xl mb-2 mt-4">Restrictions</div>
                <p>You must not distribute or reverse engineer any software components or assets.</p>

                <p className="mt-2">You must not systematically download data held by the platform.</p>

                <p className="mt-2">You must not sell or commercialise any part of the platform.</p>

                <p className="mt-2">You must not use the app in a way that is likely to impact the security or performance of the system for other users.</p>

                <p className="mt-2">You must not use any features of the app in a way that is likely to cause distress or harass other users.</p>

                <p className="mt-2">You must not transfer your account to any other person.</p>

                <div className="text-2xl mb-2 mt-4">Termination of this agreement</div>
                <p>You may terminate this agreement at any time for any reason. You may notify us if you require us to delete data we hold about you.</p>

                <p className="mt-2">We may at our sole discretion terminate this agreement without notice, and retains the right to block your access to the app.</p>

                <div className="text-2xl mb-2 mt-4">Limitation of liability</div>
                <p>We provides the app and its related services as is.</p>

                <p className="mt-2">We receive data from a number of third party sources, and are unable to guarantee it’s accuracy or fitness for purpose.</p>

                <p className="mt-2">We make no guarantee as to the availability of the services.</p>

                <p className="mt-2">You are required to validate the correctness of any data presented within the app. You agree not to hold MoveKeep liable for any loss (monetary or otherwise) as a result of using the app or its associated services.</p>

                <div className="text-2xl mb-2 mt-4">Contacting us</div>
                <p>You may contact the creators of MoveKeep by emailing support@movekeep.com.</p>
            </div>
        </div>
    </PageContainer>
}
