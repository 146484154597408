import {Helmet} from "react-helmet";
import React from "react";

export const Header = () => {
    return <Helmet>
        {/*<link rel="stylesheet" href="https://unpkg.com/react-table@latest/react-table.css"/>*/}
        {/*      integrity="sha512-puBpdR0798OZvTTbP4A8Ix/l+A4dHDD0DGqYW6RQ+9jxkRFclaxxQb/SJAWZfWAkuyeQUytO7+7N4QKrDh+drA=="*/}
        {/*      crossOrigin=""/>*/}
    </Helmet>
}
