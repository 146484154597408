import React, {useCallback, useEffect, useState} from "react";
import {findLink} from "../../util/HateoasUtil";
import axios from "axios";
import {Modal} from "../core/Modal/Modal";
import {LoadingSpinner} from "../LoadingSpinner";
import {ErrorUserMessage} from "../core/UserMessage";
import {AssignCustomRoleOption} from "./AssignCustomRoleOption";

export const AssignCustomRolesModal = ({visible, parent, membership, onClose}) => {

    const [allRoles, setAllRoles] = useState(null);
    const [usersRoles, setUsersRoles] = useState(null);

    const [fetchingAllRoles, setFetchingAllRoles] = useState(false);
    const [errorFetchingAllRoles, setErrorFetchingAllRoles] = useState(null);

    const [fetchingUserRoles, setFetchingUserRoles] = useState(false);
    const [errorFetchingUserRoles, setErrorFetchingUserRoles] = useState(null);

    const fetchAllRoles = useCallback(() => {
        setFetchingAllRoles(true);
        setErrorFetchingAllRoles(null);
        axios.get(findLink(parent, "customRoles"))
            .then(res => setAllRoles(res.data.roles))
            .catch(() => setErrorFetchingAllRoles("Error fetching roles"))
            .finally(() => setFetchingAllRoles(false));
    }, [parent]);

    const fetchUsersRoles = useCallback(() => {
        setFetchingUserRoles(true);
        setErrorFetchingUserRoles(null);
        axios.get(findLink(membership, "customRoles"))
            .then(res => setUsersRoles(res.data.roles))
            .catch(() => setErrorFetchingUserRoles("Error fetching roles"))
            .finally(() => setFetchingUserRoles(false));
    }, [membership]);

    useEffect(() => {

        if (visible) {
            fetchAllRoles();
            fetchUsersRoles();
        }

    }, [fetchAllRoles, fetchUsersRoles, visible])

    return <Modal
        isOpen={visible}
        title={"Assign " + membership?.name + " roles"}
        saveText="Save"
        onSave={null}
        onClose={onClose}>
        <div className="flex flex-col">

            {allRoles != null && usersRoles != null && allRoles
                .map(r => <AssignCustomRoleOption role={r} currentRoles={usersRoles} membership={membership} />)}

            <LoadingSpinner
                visible={fetchingAllRoles || fetchingUserRoles}
                title="Fetching..." compact={true}
                className="mt-2" />
            <ErrorUserMessage
                visible={errorFetchingAllRoles ?? errorFetchingUserRoles}
                message={errorFetchingAllRoles ?? errorFetchingUserRoles}
                className="mt-2" />
        </div>
    </Modal>
}
