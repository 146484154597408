import React, {useCallback, useEffect, useState} from "react";
import EditTournament from "../../components/EditTournament";
import {PageContainer} from "../../components/layout/PageContainer/PageContainer";
import {useSelector} from "react-redux";
import {axiosInstance, executeRequest} from "../../util/RequestUtil";
import {selectServerUri} from "../../store/reducers/config";


const TournamentPublicViewPage = (props) => {

    const [tournament, setTournament] = useState({});

    const [, setFetching] = useState(false);
    const [, setError] = useState(null);

    const serverUri = useSelector(selectServerUri);

    const refreshTournament = useCallback(() => {

        const urlParts = window.location.href.split("/");
        const id = urlParts[urlParts.length - 1];

        executeRequest(axiosInstance(true).get(serverUri + "/open/tournaments/" + id), setFetching, setError, data => {
            setTournament(data);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        refreshTournament();
    }, [refreshTournament]);

    return <PageContainer>
        <EditTournament
            {...props}
            tournament={tournament}
            refreshTournament={refreshTournament}
            view={true} />
    </PageContainer>
}

export default TournamentPublicViewPage;
