import React, {useCallback, useEffect, useState} from "react";
import {PageContainer} from "../../components/layout/PageContainer/PageContainer";
import {useSelector} from "react-redux";
import {selectServerUri} from "../../store/reducers/config";
import * as axios from "axios";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import {Button} from "../../components/core/Button/Button";
import {findSelfLink} from "../../util/HateoasUtil";
import {InputTextField} from "../../components/core/InputTextField/InputTextField";
import {isBlank} from "../../util/GeneralUtil";

export const AdminPage = ({history}) => {

    const [vegaFiles, setVegaFiles] = useState([]);

    const [sources, setSources] = useState([]);
    const [fetching, setFetching] = useState(false);


    const [tournamentId, setTournamentId] = useState("");

    const serverUri = useSelector(selectServerUri);



    const createPlayerSource = useCallback((name, source, file) => {

        const eventUri = serverUri + "/api/admin/playerSources";
        axios.post(eventUri, {name, source, file})
            .then(res => {
            })
            .catch(err => console.log("Error fetching event: " + err))
            .finally(() => setFetching(false));
    }, [serverUri]);


    const copyTournament = useCallback((name, source, file) => {

        const copyTournamentUrl = serverUri + "/api/tournaments/" + tournamentId + "/copy";
        axios.get(copyTournamentUrl)
            .then(res => {
                console.log("Copy done")
            })
            .catch(err => console.log("Error copying tournament: " + err))
            .finally(() => setFetching(false));
    }, [serverUri, tournamentId]);

    const deletePlayerSource = useCallback((source) => {

        axios.delete(findSelfLink(source))
            .then(res => {
                fetchSources();
            })
            .catch(err => console.log("Error fetching event: " + err))
            .finally(() => setFetching(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serverUri]);

    const fetchSources = useCallback(() => {

        const eventUri = serverUri + "/api/admin/playerSources";
        axios.get(eventUri)
            .then(res => {
                setSources(res.data);
            })
            .catch(err => console.log("Error fetching event: " + err))
            .finally(() => setFetching(false));
    }, [serverUri]);

    const fetchVegaFiles = useCallback(() => {

        if (isBlank(tournamentId)) {
            console.log("Tournament id is blank");
            return;
        }

        const queryUri = serverUri + "/api/admin/vegaFiles?tournamentId=" + tournamentId;
        axios.get(queryUri)
            .then(res => {
                setVegaFiles(res.data);
            })
            .catch(err => console.log("Error fetching vega files: " + err))
            .finally(() => setFetching(false));
    }, [serverUri, tournamentId]);

    useEffect(() => {
        fetchSources();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <PageContainer>
        <LoadingSpinner visible={fetching} compact={true} title="Creating source" />
        <div className="">
            <Button className="" onClick={() => {
                createPlayerSource("ACF Classic", "ACF_CLASSIC", "/tmp/acf.txt");
            }} color="gray">Create ACF</Button>
        </div>
        <div className="mt-2">
            <Button className="" onClick={() => {
                createPlayerSource("FIDE Classic", "FIDE_CLASSIC", "/tmp/fide.txt");
            }} color="gray">Create FIDE</Button>
        </div>

        <div className="mt-2">
            {sources.map(s => <div className="bg-gray-400 p-2 mb-2">
                {s.name}

                <Button className="ml-10" onClick={() => {
                    deletePlayerSource(s);
                }} color="red">DELETE</Button>
            </div>)}
        </div>

        <div className="mt-4">
            <InputTextField label="Tournament id to copy" value={tournamentId} onChange={setTournamentId} />

            <Button className="mt-2" onClick={copyTournament} color="blue">Copy tournament</Button>

            <Button className="mt-2" onClick={fetchVegaFiles} color="blue">Show Vega files</Button>
        </div>


        {vegaFiles.length > 0 && <div className="mt-4">
            {vegaFiles.map(f => <div className="m-2 p-2 bg-gray-900 text-white">
               <div className="font-bold text-xs mb-4">{f.createdTime}</div>
               <div>
                   <pre>
                       {f.content}
                   </pre>
               </div>
            </div>)}
        </div>}

    </PageContainer>
}
