import {faCheck, faPen, faTimes} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Button} from "../core/Button/Button";
import {Lozenge} from "../core/Lozenge/Lozenge";

export const RSVP_STATUSES = {
    PENDING: "PENDING",
    ACCEPTED: "ACCEPTED",
    DECLINED: "DECLINED"
}

export const RsvpBaseButton = ({status, onClick, textOverride, editable = false}) => {

    let color = null;
    let icon = null;
    let text = null;
    switch (status) {
        case RSVP_STATUSES.PENDING:
            color = "gray";
            icon = faPen;
            text = "RSVP";
            break;
        case RSVP_STATUSES.ACCEPTED:
            color = "green";
            icon = faCheck;
            text = "Attending";
            break;
        case RSVP_STATUSES.DECLINED:
            color = "red";
            icon = faTimes;
            text = "Declined";
            break;
        default:
            return null;
    }

    if (!text) {
        return null;
    } else if (editable) {
        return <Button onClick={onClick} icon={icon} color={color}>{textOverride ?? text}</Button>;
    } else {
        return <Lozenge title={text} color={color} compact={true} />
    }


}
