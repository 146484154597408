import {connect} from 'react-redux';
import {setCurrentPage} from "../../store/actions/userContext";
import {Routes} from "./Routes";

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onPageChange: (name) => dispatch(setCurrentPage(name))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Routes);
