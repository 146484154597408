import React, {useCallback, useEffect, useState} from "react";
import {findLink} from "../../util/HateoasUtil";
import axios from "axios";
import {UserMessage} from "./UserMessage";
import {Button} from "../core/Button/Button";
import {CreateMessageModal} from "./CreateMessageModal";
import {LoadingSpinner} from "../LoadingSpinner";
import {ErrorUserMessage} from "../core/UserMessage";
import {RefreshButton} from "../RefreshButton";

const MessageList = ({horde, editMode}) => {

    const [messages, setMessages] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);

    const [showCreateModal, setShowCreateModal] = useState(false);

    const fetchMessages = useCallback(() => {

        if (horde == null) {
            return;
        }

        setError(null);
        setFetching(true);
        axios.get(findLink(horde, "messages"))
            .then(res => setMessages(res.data.messages))
            .catch(err => setError("Error fetching messages"))
            .finally(() => setFetching(false));

    }, [horde]);

    useEffect(() => {
        fetchMessages();
    }, [fetchMessages]);

    const onModalSave = () => {
        fetchMessages();
        setShowCreateModal(false);
    }

    const closeModal = () => setShowCreateModal(false);
    const showModal = () => setShowCreateModal(true);

    return <div className="pt-2 w-full bg-gray-200 shadow-lg p-4 ">

        {false && <div className="flex flex-row">
            {editMode  && <Button onClick={showModal}>Add Message</Button>}
            <RefreshButton className="ml-2" onClick={fetchMessages} />
            <LoadingSpinner visible={fetching} title="Fetching..." compact={true} />
        </div>}

        <CreateMessageModal horde={horde} visible={showCreateModal} onClose={closeModal} onSave={onModalSave} />

        <div>
            <ErrorUserMessage visible={error} message={error} className="mt-2" />
        </div>

        <div>
            {messages.map(m => <UserMessage message={m} />)}
        </div>


    </div>
}

export default MessageList;
