import React, {useCallback, useEffect, useState} from "react";
import {PageContainer} from "../../components/layout/PageContainer/PageContainer";
import isUUID from "validator/lib/isUUID";
import {useSelector} from "react-redux";
import {selectServerUri} from "../../store/reducers/config";
import * as axios from "axios";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import {AdhocEventSummaryDetails} from "./AdhocEventSummaryDetails";
import {PlayerList} from "../../components/EditTournament/PlayerList";
import {Button} from "../../components/core/Button/Button";
import {hasManagementRole} from "../../util/PermissionUtil";
import {Tab, TabSet} from "../../components/core/Tabs";
import {FieldLabel} from "../../components/core/FieldLabel/FieldLabel";
import MessageList from "../../components/MessageList/MessageList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {AssignCustomRolesModal} from "../../components/CustomRoles/AssignCustomRolesModal";
import {MemberRolesCell} from "./MemberRolesCell";
import {RsvpButton} from "../../components/Rsvp/RsvpButton";

export const AdhocEventDetailPage = ({history}) => {

    const [eventId, setEventId] = useState(null);
    const [event, setEvent] = useState(null);
    const [fetching, setFetching] = useState(false);

    const [editMode, setEditMode] = useState(false);

    const [editingRolesMember, setEditingRolesMember] = useState(null);

    const serverUri = useSelector(selectServerUri);

    const onStopEditingRoles = () => {
        setEditingRolesMember(null);
        refreshEvent();
    }
    const onStartEditingRoles = member => setEditingRolesMember(member);

    const refreshEvent = useCallback(() => {
        if (eventId == null) {
            setEvent(null);
            return;
        }

        setFetching(true);

        const eventUri = serverUri + "/api/adhocEvents/" + eventId;
        axios.get(eventUri)
            .then(res => {
                setEvent(res.data);
                setEditMode(hasManagementRole(res.data.roles))
            })
            .catch(err => console.log("Error fetching event: " + err))
            .finally(() => setFetching(false));
    }, [eventId, serverUri]);

    useEffect(() => {
        const urlParts = window.location.href.split("/");
        const id = urlParts[urlParts.length - 1];
        if (isUUID(id)) {
            setEventId(id);
        } else {
            history.push("/events")
        }
    }, [history]);

    useEffect(() => {
        refreshEvent();
    }, [eventId, refreshEvent, serverUri]);

    const goBack = () => history.goBack();

    const renderHeader = () => {
        return <thead>
            <tr>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2 w-48">Attending</th>
                <th className="px-4 py-2">Jobs / Roles</th>
            </tr>
        </thead>
    }

    const renderMemberRow = (member) => {

        const canEdit = member.loggedInUser || editMode;

        return <tr className="bg-white" key={member.uuid}>
            <td className="border px-4 py-2">{member.name}</td>
            <td className="border px-4 py-2 text-center">
                <RsvpButton entity={member} onUpdated={refreshEvent} editable={canEdit} />
                {/*<RsvpStatusLozenge status={member.rsvpStatus} />*/}
            </td>
            <td className="border px-4 py-2">
                <MemberRolesCell roles={member.customRoles} onEditClicked={() => onStartEditingRoles(member)} editable={canEdit}  />
                {/*{member.customRoles.map(r => <Lozenge compact={true} color="orange" title={r} />)}*/}
                {/*<EditButton onClick={} />*/}
            </td>
        </tr>
    }

    return <PageContainer>
        <LoadingSpinner compact={true} visible={fetching} title="Fetching event details..." />
        <AssignCustomRolesModal
            visible={editingRolesMember}
            parent={event}
            membership={editingRolesMember}
            onClose={onStopEditingRoles}/>
        <div className="flex flex-row">
            <div className="">
                <Button className="" onClick={goBack} color="gray">Back</Button>
            </div>
            {event?.runByName && <>
                <div className="ml-4">
                    <FieldLabel label="Organiser" mb={false} />
                    <p className="text-l">{event.runByName}</p>
                </div>
                <div className="mx-3 flex justify-items-center">
                    <FontAwesomeIcon size={20} className="m-auto" icon={faArrowRight} />
                </div>
            </>}

            <div>
                <FieldLabel label="Event name" mb={false} />
                <p className="text-l">{event?.name}</p>
            </div>
        </div>


        <div className="w-full pr-2">
            <TabSet>

                <Tab name="Details">
                    <AdhocEventSummaryDetails canEdit={editMode} event={event} onUpdated={refreshEvent} />
                </Tab>

                <Tab name="Attending">
                    <PlayerList
                        entity={event}
                        entityName="event"
                        memberDesc="attendee"
                        renderHeader={renderHeader}
                        renderRow={renderMemberRow}
                        editMode={editMode}
                        canAddPlayers={true} />
                </Tab>
                <Tab name="Messages">
                    <MessageList horde={event} editMode={editMode} />
                </Tab>
            </TabSet>
        </div>
    </PageContainer>
}
