import React, {useEffect, useState} from "react";
import {axiosInstance, executeRequest} from "../../util/RequestUtil";
import {useSelector} from "react-redux";
import {selectServerUri} from "../../store/reducers/config";
// import MenuIcon from '@mui/icons-material/Menu';
import {ClubWebsiteToolbar} from "./ClubWebsiteToolbar";
// import AdbIcon from '@mui/icons-material/Adb';
import {ClubWebsitePage} from "./ClubWebsitePage";


export const ClubWebsite = ({clubId = "0c9cc268-acd6-4ab9-8982-bab5d1e5ae63"}) => { //"4d2b049b-eb6e-4420-96fa-4ae611120c4f"}) => {

    const [menu, setMenu] = useState([]);
    const [page, setPage] = useState(null);

    const [, setFetching] = useState(false);
    const [, setError] = useState(null);

    const serverUri = useSelector(selectServerUri);

    const fetchMenu = () => {
        executeRequest(axiosInstance(true).get(serverUri + `/open/sites/${clubId}/menu`), setFetching, setError, data => {
            setMenu(data);
        })
    }

    const fetchPage = (pageId) => {
        executeRequest(axiosInstance(true).get(serverUri + `/open/sites/${clubId}/pages/${pageId}`), setFetching, setError, data => {
            setPage(data);
        })
    }

    const onPageSelected = (pageUuid) => {
        fetchPage(pageUuid)
    }

    useEffect(() => {
        fetchMenu();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div>

        <ClubWebsiteToolbar menu={menu} onPageSelected={onPageSelected} />

        <ClubWebsitePage page={page} />


    </div>
}
