import React, {useEffect, useState} from "react";
import axios from "axios";
import {findLink} from "../../../util/HateoasUtil";
import {InputTextField} from "../InputTextField/InputTextField";
import {tournamentSize} from "../../../util/GeneralUtil";

export const EditableTableCell = ({size = "LARGE", view = false, center = false, editMode = true, entity, field, children}) => {

    const [value, setValue] = useState(entity[field]);
    const [editing, setEditing] = useState(false);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);


    useEffect(() => {
        setValue(entity[field]);
    }, [entity, field]);

    const onSave = () => {
        setEditing(false);
        setSaving(true);
        setError(false);
        axios.post(findLink(entity, field), {value: value})
            .catch(setError)
            .finally(() => setSaving(false));
    }

    const onStartEditing = () => {
        if (editMode) {
            setEditing(true);
        }
    }

    const onKeyDown = event => {

        if (event.key === "Enter") {
            onSave();
        }
    }


    const bgColor = saving ? "bg-gray-300" : error ? "bg-red-400" : "";

    const textSize = tournamentSize(view, size, " text-xs", " text-sm", " text-base");
    const padding = tournamentSize(view, size, "p-1", "p-2", "px-4 py-2");

    const textCentered = center ? " text-center" : "";

    if (!editing) {
        return <td className={"border border-gray-500 " + padding + " " + bgColor + textSize + textCentered} onClick={onStartEditing}>
            {value}
            {children}
        </td>
    } else {
        return <td className={"border border-gray-500 " + padding + " " + bgColor + textSize + textCentered}>
            <InputTextField  autoFocus value={value} onKeyDown={onKeyDown} onChange={setValue} onBlur={onSave} inputOnly={true} />
            {children}
        </td>
    }
}
