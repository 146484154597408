import {Button} from "./Button";
import React from "react";


export const OptionButton = ({option, selectedOption, title, className, onClick}) => {
    return <Button
        className={className}
        compact={true}
        onClick={() => onClick(option)}
        color={option === selectedOption ? "green" : "gray"}>

        {title}
    </Button>
}