import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchAnalysis, selectAnalysingGames, selectAnalysingUsername} from "./GameAnalysisPageSlice";
import {CartesianGrid, Line, LineChart, XAxis, YAxis} from "recharts";
// import {Pgn} from 'cm-pgn'

var kokopu = require('kokopu');

const formatEvaluations = (evaluations) => {
    return evaluations.map(e => {
        return {
            ev: e
        }
    });
}

const UserGame = ({game}) => {

    const [pgn, setPgn] = useState(null);
    const [evaluations, setEvaluations] = useState(null);

    useEffect(() => {

        var database = kokopu.pgnRead(game.gameDetail);
        setPgn(database.game(0));

        setEvaluations(formatEvaluations(JSON.parse(game.stats["EVAL_PER_MOVE"])));

    }, [game]);

    if (!pgn) {
        return <div>Loading...</div>
    }

    return <div style={{border: 1}}>
        {pgn.playerName("w")} - {pgn.playerName("b")}
        <br/>
        {pgn.result()}
        <br/>
        {pgn.mainVariation().nodes().map((n, idx) => (idx % 2 === 0 ? "" + (idx / 2 + 1) + ". " : "" ) + n.notation() + " ")}
        <br/>
        <LineChart width={400} height={400} data={evaluations}>
            <Line type="monotone" dataKey="ev" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis />
            <YAxis />
        </LineChart>
    </div>
}

export const UserGameList = () => {

    const dispatch = useDispatch();

    const games = useSelector(selectAnalysingGames);
    const username = useSelector(selectAnalysingUsername);

    useEffect(() => {
        dispatch(fetchAnalysis());
    }, [username, dispatch])

    return <div>
        {games && games.map((g, idx) => idx < 20 ? <UserGame game={g} /> : null )}
    </div>
}
