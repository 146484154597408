import React, {useCallback, useEffect, useState} from "react";
import {Button} from "../../../components/core/Button/Button";
import {InputTextField} from "../../../components/core/InputTextField/InputTextField";
import {TeamMemberList} from "./TeamMemberList";
import {findSelfLink} from "../../../util/HateoasUtil";
import axios from "axios";
import {ErrorUserMessage} from "../../../components/core/UserMessage";
import {LoadingSpinner} from "../../../components/LoadingSpinner";
import {SwitchField} from "../../../components/core/SwitchField";

export const EditClubTeam = ({club, team, onEditDone}) => {

    const [name, setName] = useState("");

    const [selfRegistration, setSelfRegistration] = useState(false);
    const [clubMembersOnly, setClubMembersOnly] = useState(false);

    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setName(team?.name ?? "");
    }, [team])

    const onNameUpdated = useCallback((text) => {
        setName(text);

        setSaving(true);
        setError(null);
        axios.post(findSelfLink(team), {name: text, description: team.description ?? ""})
            .then(() => {})
            .catch(() => setError("Error updating team"))
            .finally(() => setSaving(false));

    }, [team]);


    return team != null ? <div>

        <div className="flex flex-row">
            <Button onClick={onEditDone} color="gray">Back</Button>

            <div className="ml-4 self-center">
                {club.name} > {name}
            </div>

            <LoadingSpinner visible={saving} compact={true} size={30} />
        </div>


        <div className="mt-2">
            <InputTextField editMode={true} label="Team name" onChange={onNameUpdated} value={name} />
        </div>

        <div className="mt-2">
            <SwitchField editMode={true} label="Enable self registration?" onChange={setSelfRegistration} value={selfRegistration} />
        </div>

        {selfRegistration && <div className="mt-2">
            <SwitchField editMode={true} label="Club members only?" onChange={setClubMembersOnly} value={clubMembersOnly} />
        </div>}

        <ErrorUserMessage visible={error} message={error} />

        <div>
            <TeamMemberList team={team} />
        </div>

    </div> : null;
}
