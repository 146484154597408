import React, {useEffect, useState} from "react";
import {selectPairingAllocatedByePoints} from "../../../store/slices/editingTournament";
import {axiosInstance, toOpenResource} from "../../../util/RequestUtil";
import {findSelfLink} from "../../../util/HateoasUtil";
import {Cell} from "./index";
import {formatResult} from "../../../util/GeneralUtil";

const flipResult = (result) => {
    if (result === "WHITE") {
        return "BLACK";
    } else if (result === "BLACK") {
        return "WHITE";
    } else {
        return result;
    }
}

const teamOrientedPairing = (p) => {

    if (p.white.teamId === "A") {
        return p;
    } else {
        return {
            white: p.black,
            black: p.white,
            result: flipResult(p.result)
        }
    }
}


export const TeamVsTeamCrosstable = ({tournament, viewMode}) => {

    //http://localhost:8080/api/tournaments/b098acce-05d1-4401-b6f3-1fc2324d9039/rounds/0/pairings

    const layoutSize = tournament.layoutSize;
    const pairingByePoints = selectPairingAllocatedByePoints(tournament);

    const [teamAScore, setTeamAScore] = useState("");
    const [teamBScore, setTeamBScore] = useState("");

    const [pairings, setPairings] = useState([]);

    const fetchPairings = () => {
        axiosInstance(viewMode).get(toOpenResource(findSelfLink(tournament) + "/rounds/0/pairings", viewMode))
            .then(res => {

                const pairingsByTeam = res.data.pairings
                    .map(p => teamOrientedPairing(p));

                let aScore = 0;
                let bScore = 0;

                pairingsByTeam.forEach(p => {
                    // white's score is team A's score
                    if (p.result === "WHITE") {
                        aScore++;
                    } else if (p.result === "BLACK") {
                        bScore++;
                    } else if (p.result === "DRAW") {
                        aScore += 0.5;
                        bScore += 0.5;
                    }
                })
                setTeamAScore(aScore);
                setTeamBScore(bScore);

                setPairings(pairingsByTeam);
            })
            .catch(err => console.log("Error fetching tournament crosstable: " + err))
    }

    useEffect(fetchPairings, []);

    return <div className="p-4 pt-2 w-full bg-white shadow-lg">
        {pairings && <table>
            <thead>
            <tr>
                <Cell view={viewMode} size={layoutSize}>{tournament.teamA ?? "Team A"} <span className="ml-2 bg-gray-700 text-white rounded-full font-bold px-2 py-1">{teamAScore}</span></Cell>
                <Cell view={viewMode} size={layoutSize}>Rating</Cell>
                <Cell view={viewMode} size={layoutSize}>Result</Cell>
                <Cell view={viewMode} size={layoutSize}>{tournament.teamB ?? "Team B"} <span className="ml-2 bg-gray-700 text-white rounded-full font-bold px-2 py-1">{teamBScore}</span></Cell>
                <Cell view={viewMode} size={layoutSize}>Rating</Cell>
            </tr>
            </thead>
            <tbody>
            {pairings
                .map((p, idx) => <tr>
                    <Cell view={viewMode} size={layoutSize}>{p.white.name}</Cell>
                    <Cell view={viewMode} size={layoutSize}>{p.white.rating}</Cell>
                    <Cell view={viewMode} size={layoutSize}>{formatResult(p.result, pairingByePoints)}</Cell>
                    <Cell view={viewMode} size={layoutSize}>{p.black.name}</Cell>
                    <Cell view={viewMode} size={layoutSize}>{p.black.rating}</Cell>
                </tr>)}
            </tbody>
        </table>}
    </div>
}
