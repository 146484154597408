import React from "react";
import Button from "../Button";

export const Modal = ({isOpen, title, onSave, onClose, children, saveText = "Save", closeText}) => {


    // const width = inIframe() ? document.documentElement.clientWidth + "px" : null;
    // const height = inIframe() ? document.documentElement.clientHeight + "px" : null;

    return isOpen ? <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {title && <div className="flex items-start justify-between p-5 pb-0 border-b border-solid border-gray-300 rounded-t">
                        <h3 className="text-3xl font-semibold text-black">
                            {title}
                        </h3>
                    </div>}
                    <div className="relative p-6 flex-auto">
                        {children}
                    </div>
                    <div
                        className="flex items-center justify-end pb-6 pl-6 pr-6 rounded-b">
                        {onClose && <Button
                            color="gray"
                            className="mr-4"
                            type="button"
                            style={{transition: "all .15s ease"}}
                            onClick={onClose}>
                            {closeText ?? "Close"}
                        </Button>}
                        {onSave && <Button
                            color="green"
                            type="button"
                            style={{transition: "all .15s ease"}}
                            onClick={onSave}>
                            {saveText}
                        </Button>}
                    </div>
                </div>
            </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"/>
    </> : null;
}
