import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectCurrentPage} from "../../store/reducers/userContext";

export const MenuItem = ({title, icon, page, onClick}) => {

    const history = useHistory();
    const currentPage = useSelector(selectCurrentPage);

    const gotoPage = () => {
        history.push(page.path);
    }

    const selectedStyles = "bg-gray-600 bg-opacity-50 shadow-inner";


    const selected = page?.menuLocation === currentPage;

    return <div
        className={"text-white self-center p-2 px-3 mx-2 rounded-md " + (selected ? selectedStyles : "")}
        onClick={onClick ? onClick : gotoPage}>
        <span className="pr-3">
            <FontAwesomeIcon icon={icon} />
        </span>
        <span className="hover:underline cursor-pointer font-light text-sm md:text-base">{title}</span>
    </div>
}
