// import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
// import {selectServerUri} from "../reducers/config";
// import axios from "axios";
// import {findLink, findSelfLink} from "../../util/HateoasUtil";
// import {query} from "../../util/RequestUtil"
//
// const newTournamentDetails = {
//     uuid: null,
//     name: "My tournament",
//     rounds: "5",
//     teamA: "",
//     teamB: "",
//     firstWhite: "A",
//     sport: "CHESS",
//     fideMode: true,
//     ratingSource: "INTERNATIONAL",
//     chiefArbiter: "",
//     timeControlType: "CLASSICAL",
//     byeCountsAs: "WIN",
//     tiebreaks: [],
//     ratingSources: [],
//     city: "",
//     federeation: "",
//     accelerated: false,
//     type: "SWISS_SYSTEM",
//     playerType: "INDIVIDUAL",
//     players: [],
//     userCanEdit: true,
//     created: null,
//     links: null,
//     generatedRounds: 0,
//     savingResults: {}
// }
//
// const getTournament = (state) => state.editingTournament.tournament;
//
//
// export const startEditingTournament = createAsyncThunk(
//     'tournament/edit/start',
//     async (arg, {dispatch, getState}) => {
//         // show a blank page until we get the actual response
//         //dispatch(editingTournament.actions.initialiseNewTournament());
//
//         const resourceArea = arg.view ? "open" : "api";
//
//         let instance = axios;
//         if (arg.view) {
//             instance = axios.create();
//         }
//
//         if (arg.uuid) {
//             const result = await query(instance.get(selectServerUri(getState()) + "/" + resourceArea + "/tournaments/" + arg.uuid));
//             dispatch(fetchPlayers({tournament: result}));
//             return result;
//         } else {
//             return await query(instance.post(selectServerUri(getState()) + "/" + resourceArea + "/tournaments", {name: "My tournament"}));
//         }
//     });
//
// export const fetchPlayers = createAsyncThunk(
//     'tournament/players/fetch',
//     async (arg, {dispatch, getState}) => {
//
//         const tournament = arg.tournament ? arg.tournament : getTournament(getState());
//
//         return await query(axios.get(findLink(tournament, "members").replace("{?team}", "")));
//     });
//
// export const saveTournament = createAsyncThunk(
//     'tournament/save',
//     async (arg, {dispatch, getState}) => {
//
//         const tournament = getTournament(getState());
//
//         const data = {
//             name: tournament.name ?? "My tournament",
//             type: tournament.type ?? "SWISS_SYSTEM",
//             numRounds: tournament.rounds,
//             teamA: tournament.teamA,
//             teamB: tournament.teamB,
//             firstWhite: tournament.firstWhite,
//             clubUri: tournament.clubUri,
//             fideMode: tournament.fideMode,
//             ratingSource: tournament.ratingSource,
//             chiefArbiter: tournament.chiefArbiter,
//             timeControlType: tournament.timeControlType,
//             tiebreaks: tournament.tiebreaks != null && tournament.tiebreaks.length > 0 ? tournament.tiebreaks.join(":") : null,
//             ratingSources: tournament.ratingSources != null && tournament.ratingSources.length > 0 ? tournament.ratingSources.join(":") : null,
//             byeCountsAs: tournament.byeCountsAs,
//             city: tournament.city,
//             federation: tournament.federation,
//             accelerated: tournament.accelerated,
//             sport: tournament.sport
//         }
//
//         return await query(axios.post(findSelfLink(tournament), data));
//     });
//
//
// export const updateNumRounds = createAsyncThunk(
//     'tournament/save',
//     async (arg, {dispatch, getState}) => {
//
//         const tournament = getTournament(getState());
//
//         return await query(axios.get(findSelfLink(tournament) + "/numRounds?rounds=" + tournament.rounds));
//     });
//
//
// // export const addPlayer = createAsyncThunk(
// //     'tournament/players/add',
// //     async (playerData, {dispatch, getState}) => {
// //         return await query(axios.post(findLink(getTournament(getState()), "players"),
// //             {name: playerData.name}));
// //     });
//
// export const setTournamentStatus = createAsyncThunk(
//     'tournament/start',
//     async (arg, {dispatch, getState}) => {
//         return await query(axios.post(findLink(getTournament(getState()), "status"), {status: arg.status}));
//     });
//
// export const savePairingResult = createAsyncThunk(
//     'tournament/pairing/result',
//     async (arg, {dispatch, getState}) => {
//
//         const pairing = await query(axios.post(findLink(arg.pairing, "result"), {result: arg.result}));
//
//         return {pairing: pairing};
//     });
//
// const editingTournament = createSlice({
//     name: 'editingTournament',
//     initialState: {tournament: null, saving: false, savingPlayer: false, errorStarting: false},
//     reducers: {
//         cancelEdit: state => {
//             state.tournament = null;
//         },
//         initialiseNewTournament: (state, action) => {
//             state.tournament = {...newTournamentDetails};
//         },
//         tournamentNameUpdated: (state, action) => {
//             state.tournament.name = action.payload;
//         },
//         tournamentTypeUpdated: (state, action) => {
//             state.tournament.type = action.payload;
//         },
//         playerTypeUpdated: (state, action) => {
//             state.tournament.playerType = action.payload;
//         },
//         tournamentRoundsUpdated: (state, action) => {
//             state.tournament.rounds = action.payload;
//         },
//         tournamentTeamAUpdated: (state, action) => {
//             state.tournament.teamA = action.payload;
//         },
//         tournamentFirstWhiteUpdated: (state, action) => {
//             state.tournament.firstWhite = action.payload;
//         },
//         tournamentTeamBUpdated: (state, action) => {
//             state.tournament.teamB = action.payload;
//         },
//         tournamentSportUpdated: (state, action) => {
//             state.tournament.sport = action.payload;
//         },
//         tournamentFideModeUpdated: (state, action) => {
//             state.tournament.fideMode = action.payload;
//         },
//         tournamentRatingSourceUpdated: (state, action) => {
//             state.tournament.ratingSource = action.payload;
//         },
//         tournamentChiefArbiterUpdated: (state, action) => {
//             state.tournament.chiefArbiter = action.payload;
//         },
//         tournamentTimeControlTypeUpdated: (state, action) => {
//             state.tournament.timeControlType = action.payload;
//         },
//         tournamentTiebreaksUpdated: (state, action) => {
//             state.tournament.tiebreaks = action.payload;
//         },
//         tournamentRatingSourcesUpdated: (state, action) => {
//             state.tournament.ratingSources = action.payload;
//         },
//         tournamentByeCountsAsUpdated: (state, action) => {
//             state.tournament.byeCountsAs = action.payload;
//         },
//
//         tournamentCityUpdated: (state, action) => {
//             state.tournament.city = action.payload;
//         },
//         tournamentFederationUpdated: (state, action) => {
//             state.tournament.federation = action.payload;
//         },
//
//         tournamentAcceleratedUpdated: (state, action) => {
//             state.tournament.accelerated = action.payload
//         },
//         playerAdded: (state, action) => {
//             state.tournament.players.push(action.payload)
//         },
//         clubSelected: (state, action) => {
//             state.tournament.clubUri = action.payload;
//         }
//     },
//     extraReducers: {
//         [startEditingTournament.pending]: (state, action) => {
//             state.saving = true;
//             state.roundsError = false;
//         },
//         [startEditingTournament.fulfilled]: (state, action) => {
//
//             if (!state.tournament) {
//                 state.tournament = {...newTournamentDetails};
//             }
//
//             state.tournament.uuid = action.payload.uuid;
//             state.tournament.created = action.payload.created;
//             state.tournament.name = action.payload.name;
//             state.tournament.generatedRounds = action.payload.generatedRounds;
//             state.tournament.type = action.payload.type ?? "SWISS_SYSTEM";
//             state.tournament.sport = action.payload.sport ?? "CHESS";
//             state.tournament.fideMode = action.payload.fideMode == null ? true : action.payload.fideMode;
//             state.tournament.ratingSource = action.payload.ratingSource;
//             state.tournament.chiefArbiter = action.payload.chiefArbiter;
//             state.tournament.timeControlType = action.payload.timeControlType;
//             state.tournament.tiebreaks = action.payload.tiebreaks;
//             state.tournament.ratingSources = action.payload.ratingSources;
//             state.tournament.accelerated = action.payload.accelerated;
//             state.tournament.byeCountsAs = action.payload.byeCountsAs;
//             state.tournament.city = action.payload.city;
//             state.tournament.federation = action.payload.federation;
//             state.tournament.source = action.payload.source;
//             state.tournament.status = action.payload.status;
//             state.tournament.rounds = action.payload.totalRounds;
//             state.tournament.teamA = action.payload.teamA;
//             state.tournament.teamB = action.payload.teamB;
//             state.tournament.firstWhite = action.payload.firstWhite;
//             state.tournament.userCanEdit = action.payload.userCanEdit;
//             state.tournament.clubName = action.payload.clubName;
//
//             state.tournament.layoutSize = action.payload.layoutSize;
//             state.tournament.defaultTab = action.payload.defaultTab;
//             state.tournament.showTournamentName = action.payload.showTournamentName;
//
//             state.saving = false;
//             state.savingPlayer = false;
//             state.errorStarting = false;
//
//             state.tournament.links = action.payload["_links"];
//             state.tournament.clubUri = findLink(action.payload, "club");
//
//             state.saving = false;
//         },
//         [startEditingTournament.rejected]: (state, action) => {
//             state.saving = false;
//         },
//
//         [fetchPlayers.pending]: (state) => {
//             state.fetchingPlayers = true;
//         },
//         [fetchPlayers.fulfilled]: (state, action) => {
//             state.fetchingPlayers = false;
//             state.tournament.players = action.payload.players;
//         },
//         [fetchPlayers.rejected]: (state, action) => {
//             state.fetchingPlayers = false;
//         },
//
//         [saveTournament.pending]: (state) => {
//             state.saving = true;
//         },
//         [saveTournament.fulfilled]: (state) => {
//             state.saving = false;
//         },
//         [saveTournament.rejected]: (state) => {
//             state.saving = false;
//         },
//
//
//         [updateNumRounds.pending]: (state) => {
//             state.saving = true;
//             state.roundsError = false;
//         },
//         [updateNumRounds.fulfilled]: (state) => {
//             state.saving = false;
//             state.roundsError = false;
//         },
//         [updateNumRounds.rejected]: (state) => {
//             state.saving = false;
//             state.roundsError = true;
//         },
//
//
//         [setTournamentStatus.pending]: (state) => {
//             state.saving = true;
//             state.errorStarting = false;
//         },
//         [setTournamentStatus.fulfilled]: (state, action) => {
//             state.tournament.status = action.payload.status;
//             state.saving = false;
//         },
//         [setTournamentStatus.rejected]: (state) => {
//             state.saving = false;
//             state.errorStarting = true;
//         },
//
//         [savePairingResult.pending]: (state, action) => {
//             const args = action.meta.arg;
//             const pairingUri = findSelfLink(args.pairing);
//
//             state.tournament.savingResults[pairingUri] = {
//                 requestId: action.meta.requestId,
//                 result: args.result,
//                 complete: false,
//                 error: false
//             }
//         },
//         [savePairingResult.fulfilled]: (state, action) => {
//             const pairingUri = findSelfLink(action.meta.arg.pairing);
//             state.tournament.savingResults[pairingUri].complete = true;
//         },
//         [savePairingResult.rejected]: (state, action) => {
//             const pairingUri = findSelfLink(action.meta.arg.pairing);
//             state.tournament.savingResults[pairingUri].complete = true;
//             state.tournament.savingResults[pairingUri].error = true;
//         }
//     }
// })
//
// export const { initNewTournament, cancelEdit,
//     tournamentNameUpdated, tournamentTypeUpdated, playerTypeUpdated, tournamentRoundsUpdated, tournamentTeamAUpdated, tournamentTeamBUpdated, tournamentFirstWhiteUpdated,
//     playerAdded, clubSelected, tournamentFideModeUpdated, tournamentSportUpdated, tournamentRatingSourceUpdated, tournamentAcceleratedUpdated, tournamentByeCountsAsUpdated,
//     tournamentCityUpdated, tournamentFederationUpdated, tournamentChiefArbiterUpdated, tournamentTimeControlTypeUpdated, tournamentTiebreaksUpdated, tournamentRatingSourcesUpdated } = editingTournament.actions
//
// export default editingTournament.reducer
//
//
// // Selectors
// export const selectIsEditingTournament = state => state.editingTournament.tournament != null;
// export const selectSavingTournament = state => state.editingTournament.saving
// export const selectSavingTournamentRoundsError = state => state.editingTournament.roundsError
//
// export const selectEditingTournament = state => state.editingTournament.tournament;
// export const selectEditingTournamentId = state => state.editingTournament.tournament?.uuid;
// export const selectEditingTournamentName = state => state.editingTournament.tournament?.name;
// export const selectEditingTournamentStatus = state => state.editingTournament.tournament?.status;
// export const selectEditingTournamentType = state => state.editingTournament.tournament?.type;
// export const selectEditingTournamentSport = state => state.editingTournament.tournament?.sport;
// export const selectEditingTournamentFideMode = state => state.editingTournament.tournament?.fideMode;
// export const selectEditingTournamentRatingSource = state => state.editingTournament.tournament?.ratingSource;
// export const selectEditingTournamentAccelerated = state => state.editingTournament.tournament?.accelerated;
// export const selectEditingTournamentByeCountsAs = state => state.editingTournament.tournament?.byeCountsAs;
// export const selectEditingTournamentCity = state => state.editingTournament.tournament?.city;
// export const selectEditingTournamentFederation = state => state.editingTournament.tournament?.federation;
// export const selectEditingTournamentChiefArbiter = state => state.editingTournament.tournament?.chiefArbiter;
// export const selectEditingTournamentTimeControlType = state => state.editingTournament.tournament?.timeControlType;
// export const selectEditingTournamentTiebreaks = state => state.editingTournament.tournament?.tiebreaks;
// export const selectEditingTournamentRatingSources = state => state.editingTournament.tournament?.ratingSources;
// export const selectEditingTournamentPlayerType = state => state.editingTournament.tournament?.playerType;
// export const selectEditingTournamentRounds = state => state.editingTournament.tournament?.rounds;
// export const selectEditingTournamentTeamA = state => state.editingTournament.tournament?.teamA;
// export const selectEditingTournamentTeamB = state => state.editingTournament.tournament?.teamB;
// export const selectEditingTournamentFirstWhite = state => state.editingTournament.tournament?.firstWhite;
// export const selectEditingTournamentPlayers = state => state.editingTournament.tournament?.players;
// export const selectEditingTournamentSavingResults = state => state.editingTournament.tournament?.savingResults;
// export const selectEditingTournamentClub = state => state.editingTournament.tournament?.clubUri;
// export const selectEditingTournamentEditMode = state => state.editingTournament.tournament?.userCanEdit;
// export const selectErrorStartingTournament = state => state.editingTournament.errorStarting;
//
// export const selectTournamentLayoutSize = state => state.editingTournament.tournament?.layoutSize;
//
export const selectPairingAllocatedByePoints = tournament => {
    const byeCountsAs = tournament.byeCountsAs ?? "WIN";
    if (byeCountsAs === "WIN") {
        return 1;
    } else if (byeCountsAs === "DRAW") {
        return 0.5;
    } else {
        return 0;
    }
}
