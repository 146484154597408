import React, {useCallback, useEffect, useState} from "react";
import {AddPlayer} from "../../../components/AddPlayer";
import axios from "axios";
import {findLink, findSelfLink} from "../../../util/HateoasUtil";
import {ErrorUserMessage} from "../../../components/core/UserMessage";
import {DeleteButton} from "../../../components/DeleteButton";
import {Modal} from "../../../components/core/Modal/Modal";
import {LoadingSpinner} from "../../../components/LoadingSpinner";

const SelectedMemberModal = ({visible, member, onClose, onUpdated}) => {

    const [removing, setRemoving] = useState(false);
    const [error, setError] = useState(null);

    const onRemove = () => {
        setError(null);
        setRemoving(true);
        axios.delete(findSelfLink(member))
            .then(() => onUpdated())
            .catch(() => setError("Error removing team member"))
            .finally(() => setRemoving(false));
    }

    return <Modal
            isOpen={visible}
            title="Remove team member"
            saveText="Confirm"
            onSave={onRemove}
            onClose={onClose}>
        <div>
            <div className="text-center">
                <div>Are you sure you want to remove the following member from the team?</div>
                <div className="font-bold mt-3">{member?.name}</div>
            </div>

            <LoadingSpinner visible={removing} compact={true} title="Removing..." />
            <ErrorUserMessage visible={error} message={error} className="mt-2" />
        </div>
    </Modal>

}

export const TeamMemberList = ({team}) => {

    const [members, setMembers] = useState([]);

    const [selectedMember, setSelectedMember] = useState(null);


    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);

    const fetchMembers = useCallback(() => {
        setFetching(true);
        setError(null);
        axios.get(findLink(team, "members").replace("{?team}", ""))
            .then(res => setMembers(res.data))
            .catch(() => setError("Error fetching members"))
            .finally(() => setFetching(false));
    }, [team]);

    useEffect(() => {
        fetchMembers();
    }, [fetchMembers]);

    const appendPlayer = player => {
        fetchMembers();
    }

    const onSelectedMemberUpdated = () => {
        fetchMembers();
        setSelectedMember(null);
    }

    return <div className="mt-4">

        <div className="text-xl font-bold">Team members</div>

        <div className="mt-2">
            <AddPlayer
                entity={team}
                excludeUri={findSelfLink(team)}
                entityName="member"
                placeholder="Search club members..."
                userOnly={true}
                onPlayerAdded={appendPlayer} />
            <div className="italic text-sm">Search for a club player to add or add a member just to this team</div>
        </div>

        <table className="table-auto bg-white mt-4">
            <thead>
            <tr>
                <th className="px-4 py-2">Current members</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {members?.map(m => <tr key={m.uuid}>
                <td className="border px-4 py-2 text-center">
                    {m.name}
                </td>
                <td className="border px-4 py-2 text-center">
                    <DeleteButton onClick={() => setSelectedMember(m)} />
                </td>
            </tr>)}
            </tbody>
        </table>
        {!fetching && members?.length === 0 && <div className="ml-4">No members in team yet</div>}

        <SelectedMemberModal visible={selectedMember != null} member={selectedMember} onUpdated={onSelectedMemberUpdated} onClose={() => setSelectedMember(null)} />

        <ErrorUserMessage visible={error} message={error} />
    </div>
}
