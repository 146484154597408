import React from "react";
import {useSelector} from "react-redux";
import {selectIsAnonymousUser} from "../../store/slices/authentication";
import {LoggedOutMenuOptions} from "./LoggedOutMenuOptions";

import logo from "../../logo.png"
import {selectIsStaticContent} from "../../store/reducers/userContext";

const Menu = (props) => {

    const anonymousUser = useSelector(selectIsAnonymousUser);
    const isStaticContent = useSelector(selectIsStaticContent);



    return <nav id="header" className="w-full z-30 text-white gradient">
        <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
            <div className="pl-4 flex items-center">
                <img src={logo} width={50} className="mr-2" alt="Move Keep Logo" />
                    <a className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                   href="/">
                    Move Keep
                </a>
            </div>
            {/*<div className="block lg:hidden pr-4">*/}
            {/*    <button id="nav-toggle"*/}
            {/*            className="flex items-center p-1 text-pink-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">*/}
            {/*        <svg className="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
            {/*            <title>Menu</title>*/}
            {/*            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>*/}
            {/*        </svg>*/}
            {/*    </button>*/}
            {/*</div>*/}
            {/*<StaticContentMenuOptions visible={isStaticContent} />*/}
            {/*<LoggedInMenuOptions visible={currentPage !== "PRIVACY" && !anonymousUser} />*/}
            <LoggedOutMenuOptions visible={!isStaticContent && anonymousUser} {...props} />
            {/*<div*/}
            {/*    className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"*/}
            {/*    id="nav-content">*/}
            {/*    <ul className="list-reset lg:flex justify-end flex-1 items-center">*/}
            {/*        <li className="mr-3">*/}
            {/*            <a className="inline-block text-black no-underline hover:text-gray-800 hover:text-underline py-2 px-4"*/}
            {/*               href="#">Login</a>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*    <PrimaryCtaButton title="Create Tournament" onClick={() => console.log("Clicked!")} />*/}
            {/*</div>*/}
        </div>
        <hr className="border-b border-gray-100 opacity-25 my-0 py-0"/>
    </nav>
    //     {/*<MenuLogo />*/}
    //     <div className="inline-block text-gray-100 text-3xl">Tournament Manager</div>
    //
    //     <div className="inline-block ml-4">
    //         <MenuItem title="My Tournaments" icon={faHome} page={PAGE.CREATE_TOURNAMENT} {...props} />
    //         {/*<MenuItem title="Find Tournament" icon={faSearch} page={PAGE.FIND_TOURNAMENT} {...props} />*/}
    //         <MenuItem title="Clubs" icon={faUsers} page={PAGE.CLUBS_HOME} {...props} />
    //
    //         {anonymousUser && <MenuItem title="Login" icon={faUser} page={PAGE.LOGIN} {...props} />}
    //     </div>
    //
    //     {(!anonymousUser) && <div className="absolute right-0 inline-block mt-1">
    //         {/*<div className="flex items-center bg-gray-700 pl-2 py-3 shadow-2xl">*/}
    //         {/*    /!*<Avatar/>*!/*/}
    //         {/*    <p className="text-gray-100 leading-none">{user?.name}</p>*/}
    //         {/*</div>*/}
    //         <MenuItem title="Logout" icon={faSignOutAlt} onClick={onLogout} {...props} />
    //     </div>}
    // </nav>
}

export default Menu;
