import React from "react";
import {FieldLabel} from "../FieldLabel/FieldLabel";


// Error example
// <input
//     className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
//     id="password" type="password" placeholder="******************"/>
// <p className="text-red-500 text-xs italic">Please choose a password.</p>

export const InputTextField = ({editMode = true, label, onChange, inputOnly = false, ...props}) => {


    // this element is exactly the same as the one below, just couldn't get reusing it to work
    if (inputOnly) {
        return <input
            {...props}
            className="flex-grow shadow appearance-none border border-gray-400 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
            type="text"
            disabled={!editMode}
            onChange={e => onChange(e.target.value)}/>
    }

    return <div className={"flex flex-row items-center " + props.className}>
        <div style={{width: "150px"}}>
            <FieldLabel label={label}/>
        </div>

        <input
            {...props}
            className="flex-grow shadow appearance-none border border-gray-400 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
            type="text"
            disabled={!editMode}
            onChange={e => onChange(e.target.value)}/>
    </div>

    // if (editMode) {
    //
    // } else {
    //     return <div className={"" + props.className}><ReadonlyField name={label} value={props.value} /></div>
    // }
}
