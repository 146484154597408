
export const RoundEditOptions = ({tournament, rounds, selectedRoundIdx, onMarkedComplete, onEditRound}) => {

    // const [visible, setVisible] = useState(false);
    // const [enabled, setEnabled] = useState(false);
    // const [saving, setSaving] = useState(false);
    // const [error, setError] = useState(false);
    //
    // const canEdit = useSelector(selectEditingTournamentEditMode);
    //
    // // const onMarkRoundComplete = () => {
    // //
    // //     if (!enabled && !saving) {
    // //         return;
    // //     }
    // //
    // //     setSaving(true);
    // //     setError(false);
    // //     axios.post(findLink(rounds[selectedRoundIdx], "status"), {status: "COMPLETE"})
    // //         .then(() => onMarkedComplete())
    // //         .catch(() => setError(true))
    // //         .finally(() => setSaving(false));
    // // }
    //
    // useEffect(() => {
    //     setVisible(tournament.type === "SWISS_SYSTEM");
    //     if (rounds) {
    //
    //         // first round that isn't complete is the
    //
    //         setEnabled(
    //             rounds[selectedRoundIdx].status !== "COMPLETE" &&
    //             selectedRoundIdx + 1 === rounds.length)
    //     }
    //
    // }, [tournament, rounds, selectedRoundIdx])

    return null;

    // return (visible && canEdit) ? <div className="mt-2">
    //     {/*{!isVegaTournament(tournament) && <SaveButton title="Complete Round" onSave={onMarkRoundComplete} disabled={!enabled} saving={saving} />}*/}
    //     {/*{!isVegaTournament(tournament) && <Button className="ml-2" color="blue" title="Edit pairings" onClick={onEditRound} disabled={!enabled} saving={saving}>Edit pairings</Button>}*/}
    //     {error && <div className="text-red-600 italic text-sm">Error completing round - have all results been entered?</div>}
    // </div> : null;
}
