import React, {useCallback, useEffect, useState} from "react";
import ClubList from "./ClubList";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import axios from "axios";
import {addPaginationParams} from "../../util/RequestUtil";
import {useSelector} from "react-redux";
import {selectServerUri} from "../../store/reducers/config";
import {ErrorUserMessage} from "../../components/core/UserMessage";
import {Button} from "../../components/core/Button/Button";

const MyClubsList = ({history}) => {

    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [clubs, setClubs] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const serverUri = useSelector(selectServerUri);

    const fetchClubs = useCallback(() => {
        setError(false);
        setLoading(true);
        axios.get(serverUri + addPaginationParams("/api/clubs", page))
            .then(res => {
                setTotalPages(res.data.totalPages);
                setClubs(res.data.page);
            })
            .catch(err => {
                setError(true);
                console.log("Error fetching clubs: ", err);
            })
            .finally(() => setLoading(false));
    }, [page, serverUri]);

    const canGoNext = () => page + 1 < totalPages;
    const canGoPrev = () => page > 0;

    const nextPage = () => {
        if (canGoNext()) {
            setPage(page + 1);
        }
    }

    const prevPage = () => {
        if (canGoPrev()) {
            setPage(page - 1);
        }
    }


    useEffect(() => {
        fetchClubs();
    }, [fetchClubs, page]);

    return <div>
        <p className="text-2xl mt-4">My Clubs / Teams</p>
        {error && <ErrorUserMessage title="Error loading clubs list" />}

        <div className="mt-2 ">
            <Button disabled={!canGoPrev()} color="gray" onClick={prevPage}>Prev Page</Button>
            <Button disabled={!canGoNext()} className="ml-2" color="gray" onClick={nextPage}>Next Page</Button>
        </div>

        <LoadingSpinner title="Loading clubs" visible={loading} />

        {clubs && <ClubList clubs={clubs} history={history} />}
    </div>
}

export default MyClubsList;
