import {Modal} from "../core/Modal/Modal";
import {InputTextField} from "../core/InputTextField/InputTextField";
import {ErrorUserMessage} from "../core/UserMessage";
import React, {useEffect, useState} from "react";
import {EditButton} from "../EditButton";
import {executeRequest} from "../../util/RequestUtil";
import axios from "axios";
import {findSelfLink} from "../../util/HateoasUtil";

export const EditTournamentPlayerModal = ({tournament, player, onUpdated, tooLateToEdit}) => {

    const [showModal, setShowModal] = useState(false);

    const [name, setName] = useState("");
    const [fideId, setFideId] = useState("");
    const [federation, setFederation] = useState("");
    const [rating, setRating] = useState("");

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const fideMode = tournament.fideMode == null ? true : tournament.fideMode;

    const onSave = () => {

        const data = {
            name: name,
            fideId: fideId,
            federation: federation,
            rating: rating
        };

        executeRequest(axios.post(findSelfLink(player), data), setSaving, setError, () => {
            setShowModal(false);
            onUpdated();
        });
    }

    useEffect(() => {

        if (showModal && player) {
            setName(player.name ?? "");
            setFideId(player.fideId ?? "");
            setFederation(player.federation ?? "");
            setRating(player.rating ?? "0");
        }

    }, [player, showModal]);

    return <>
        <EditButton onClick={() => setShowModal(true)} />
        <Modal isOpen={showModal} title="Edit player"
            saveText="Save"
            onSave={saving ? null : onSave}
            onClose={() => setShowModal(false)}>
            <div>

                <ErrorUserMessage visible={error} message="Error saving player" />

                {fideMode && <div className="-mt-3 mb-2 font-light whitespace-pre-wrap">
                    Note: Name and rating cannot be changed after the 4th round has been generated
                </div>}

                <InputTextField editMode={!tooLateToEdit} label="Name" onChange={setName} value={name} />
                <InputTextField className="mt-1" label="FIDE ID" onChange={setFideId} value={fideId} />
                <InputTextField className="mt-1" label="Federation" onChange={setFederation} value={federation} maxLength={3} />
                <InputTextField editMode={!tooLateToEdit} className="mt-1" label="Rating (for seeding)" onChange={setRating} value={rating} />
                <div className="text-sm italic font-light whitespace-pre-wrap">
                    Rating is basically used as the seeding number for the player with a higher value meaning a
                    better seed. {fideMode && "This is not the rating included in TRF16 exports."}
                </div>
            </div>
        </Modal>
    </>
}
