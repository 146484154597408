import React, {useEffect, useState} from "react";
import PageContainer from "../../components/layout/PageContainer";
import {InputTextField} from "../../components/core/InputTextField/InputTextField";
import {useSelector} from "react-redux";
import {selectServerUri} from "../../store/reducers/config";
import axios from "axios";
import {findSelfLink} from "../../util/HateoasUtil";
import {Button} from "../../components/core/Button/Button";
import {ManageClubPlayers} from "./ManageClubPlayers";
import {Tab, TabSet} from "../../components/core/Tabs";
import {ManageClubEvents} from "./ManageClubEvents";
import {hasManagementRole} from "../../util/PermissionUtil";
import {ManageClubTeams} from "./teams/ManageClubTeams";

export const ManageClubPage = ({history}) => {

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [club, setClub] = useState(null);
    const [id, setId] = useState(null);
    const [, setError] = useState(null);
    const [, setLoading] = useState(false);
    const [, setSaving] = useState(false);

    const [canManage, setCanManage] = useState(false);

    const serverUri = useSelector(selectServerUri);


    const fetchClub = () => {
        if (id) {
            setLoading(true);
            axios.get(serverUri + "/api/clubs/" + id)
                .then(res => {
                    setClub(res.data);
                    setCanManage(hasManagementRole(res.data.roles));
                    setName(res.data.name);
                    setDescription(res.data.description);
                })
                .catch(err => setError("Error fetching club details"))
                .finally(() => setLoading(false));
        }
    };

    const saveClub = () => {
        if (club) {
            setSaving(true);
            axios.post(findSelfLink(club), {name, description})
                .then(() => history.goBack())
                .catch(err => setError("Error saving club"))
                .finally(() => setSaving(false));
        }
    }

    useEffect(() =>  {
        const PREFIX = "/clubs/edit/";
        setId(history.location.pathname.replace(PREFIX, ""));
    }, [history]);

    useEffect(fetchClub, [id]);

    return <PageContainer>
        <div className="text-2xl">
            Manage Club - {name}
        </div>

        <div className="w-full">
            <TabSet>
                <Tab name="Details">
                    <div className="bg-white shadow p-4 inline-block w-full">
                        <div className="w-96">
                            <InputTextField editMode={canManage} label="Name" onChange={setName} value={name} />
                            <div className="mt-4">
                                <InputTextField editMode={canManage} label="Description" onChange={setDescription} value={description} />
                            </div>
                        </div>
                        {canManage && <div className="mt-4">
                            <Button onClick={saveClub}>Save</Button>
                        </div>}
                    </div>
                </Tab>
                {/*{canManage && <Tab name="Website">*/}
                {/*    <ManageWebsite club={club} editMode={canManage} />*/}
                {/*</Tab>}*/}
                {canManage && <Tab name="Members">
                    <ManageClubPlayers club={club} editMode={canManage} />
                </Tab>}
                {canManage && <Tab name="Teams">
                    <ManageClubTeams club={club} editMode={canManage} />
                </Tab>}
                <Tab name="Events">
                    <ManageClubEvents readonly={!canManage} club={club} history={history} />
                </Tab>
            </TabSet>
        </div>

    </PageContainer>
}

export default ManageClubPage;
