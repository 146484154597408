import {connect} from 'react-redux';
import {fetchServerUri} from "../../store/actions/config";
import {AppInitializer} from "./AppInitializer";

const mapStateToProps = (state, ownProps) => ({
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    setupServerUri: () => dispatch(fetchServerUri())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppInitializer);
