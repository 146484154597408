import React, {useCallback, useEffect, useState} from "react";
import Select from 'react-select'
import {useDispatch} from "react-redux";
import {ErrorUserMessage} from "../core/UserMessage";
import {SaveButton} from "../core/Button/SaveButton";
import {capitalize, isBlank} from "../../util/GeneralUtil";
import {findLink, findSelfLink} from "../../util/HateoasUtil";
import axios from "axios";

export const AddPlayer = ({entity, team = null, entityName = "player", placeholder, onPlayerAdded, userOnly = false, excludeUri}) => {

    const dispatch = useDispatch();

    const [dataVersion, setDataVersion] = useState(0);
    const [options, setOptions] = useState([]);
    const [searchText, setSearchText] = useState("");

    const [addingPlayer, setAddingPlayer] = useState(false);
    const [addPlayerError, setAddPlayerError] = useState(null);

    const executeAddPlayer = (player = null) => {

        if (player == null && isBlank(searchText)) {
            return;
        }

        let uri = findLink(entity, "members");

        let playerData;
        if (player) {
            if (player.uuid && player.ratings) {
                playerData = {externalPlayerId: player.uuid};
            } else {
                playerData = {membershipUri: findSelfLink(player)};
            }
        } else {
            playerData = {name : searchText};
        }

        if (team) {
            playerData["team"] = team
        }

        setAddingPlayer(true);
        setAddPlayerError(null);
        axios.post(uri.replace("{?team}", ""), playerData)
            .then((res) => {
                onPlayerAdded(res.data);
                setOptions([]);
                if (searchText.length === 0) {
                    setDataVersion(dataVersion + 1);
                } else {
                    setSearchText("");
                }
            })
            .catch(() => setAddPlayerError("Error adding player"))
            .finally(() => setAddingPlayer(false))
    };

    const onAddPlayer = () => {
        executeAddPlayer();
        setSearchText("");
    };

    const onPlayerSelect = (val, {action}) => {
        if (action === "select-option") {
            executeAddPlayer(val.player);
        }
    };

    const playerFilter = useCallback(() => (option, inputValue) => {
        if (option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
            return true;
        } else  if (option.value === "") {
            return true;
        }

        return false;
    }, []);

    // const countMatchingOptions = useMemo(() => {
    //     return options?.result?.filter(o => playerFilter(o, searchText)).length
    // }, [searchText, playerFilter, options]);

    // const hasValidMatchingOptions = useMemo(() => countMatchingOptions > 0, [countMatchingOptions]);

    // const onKeyDown = e => {
    //     if (e.key === "Enter" && !hasValidMatchingOptions) {
    //         executeAddPlayer();
    //         setSearchText("");
    //     }
    // };

    let onSearchTextUpdated =  (val, {action}) => {
        if (action !== "menu-close" && action !== "input-blur") {
            setSearchText(val);
        }
    };

    // TODO - need to debounce this
    useEffect(() => {

        if (entity == null) {

            return;
        }

        let uri = findLink(entity, "addMemberSearchV2") ?? findLink(entity, "addMemberSearch");


        if (uri == null) {
            return;
        }
        uri = uri.replace("{query}", encodeURIComponent(searchText))

        if (excludeUri) {
            uri = uri.replace("{&excludeUri}", "&excludeUri=" + excludeUri);
        } else {
            uri = uri.replace("{&excludeUri}", "");
        }


        axios.get(uri)
            .then((res) => {

                const updatedOptions = [];

                updatedOptions.push({
                    label: null,
                    options: [{
                        name: null,
                        label: "Add new player",
                        player: null
                    }]
                });

                const clubOptions = res.data?.result
                    .map(o => ({
                        name: o.id,
                        label: o.name,
                        player: o,
                    }));


                if (clubOptions.length > 0) {
                    updatedOptions.push({
                        label: "Club Members",
                        options: clubOptions
                    })
                }

                const external = res.data?.externalMatches;
                if (external) {
                    const sourceNames = [];
                    external.map(m => m.sourceName).forEach(n => {
                        if (!sourceNames.includes(n)) {
                            sourceNames.push(n);
                        }
                    });

                    sourceNames.forEach(n => {
                        const sourceOptions = external
                            .filter(m => m.sourceName === n)
                            .map(m => {

                                return {
                                    name: m.uuid,
                                    label: <div className="flex flex-row items-center">
                                        <div className="w-10 text-center font-bold text-xs font-bold text-white bg-gray-700 p-1 rounded">{m.location}</div>
                                        {/*<div className="w-10 text-center font-semibold text-base text-gray-700 font-old text-sm">{m.rating}</div>*/}
                                        <div className="ml-4 mr-2">
                                            <div>{m.name}</div>
                                            <div className="text-xs italic text-gray-600">Born: {m.dob}</div>
                                        </div>
                                        {m.ratings.map((r) => <div className="px-2 text-xs border-l border-gray-400 text-center">
                                            <div><span className="font-bold">{r.type}</span> {r.subType}</div>
                                            <div>{r.rating}</div>
                                        </div>)}
                                    </div>,
                                    player: m,
                                }
                            });

                        updatedOptions.push({
                            label: n,
                            options: sourceOptions
                        })
                    });
                }


                setOptions(updatedOptions);
            }).catch(err => {
                console.log("Error fetching options: ", err);
            });

    }, [dataVersion, searchText, dispatch, entity, userOnly, excludeUri]);

    return <div className="flex flex-row items-center">
        <Select
            placeholder={placeholder ?? "Search or create " + entityName + "..."}
            noOptionsMessage={() => ""}
            defaultValue={null}
            className="w-2/3"
            options={options}
            inputValue={searchText}
            value={searchText}
            isSearchable={true}
            onInputChange={onSearchTextUpdated}
            onChange={onPlayerSelect}
            filterOption={playerFilter}
            onFocus={() => console.log("onFocus")} />
        <div className="ml-2">
            <SaveButton
                disabled={addingPlayer || searchText == null || searchText.length === 0}
                saving={addingPlayer}
                onSave={onAddPlayer}
                title={"Add " + capitalize(entityName)}/>

            <span className="ml-4">
                <ErrorUserMessage visible={addPlayerError} message="Error adding player" />
            </span>
        </div>
    </div>
}

