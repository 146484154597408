import {Modal} from "../../../../components/core/Modal/Modal";
import React, {useState} from "react";
import {Button} from "../../../../components/core/Button/Button";
import {isBye} from "../../../../util/GeneralUtil";


export const MoreResultOptionsModal = ({tournament, visible, onClose, onDelete, onSaveResult, pairing}) => {

    const [confirmingDelete, setConfirmingDelete] = useState(false);

    const fideMode = tournament.fideMode ?? true;

    const onDeleteConfirmed = () => {
        onDelete();
    }

    const onCloseSelected = () => {
        if (confirmingDelete) {
            setConfirmingDelete(false);
        } else {
            onClose();
        }
    }

    return <Modal
        isOpen={visible}
        onSave={confirmingDelete ? onDeleteConfirmed : null}
        saveText={confirmingDelete ? "Confirm Delete" : null}
        onClose={onCloseSelected}>

        {confirmingDelete && <div>
            Are you sure you want to delete this pairing?
        </div>}

        {!confirmingDelete && <div className="flex flex-col">
            <div className="mt-2 text-sm font-bold">Forfeits</div>
            <Button className="mt-1" color="gray" onClick={() => onSaveResult("WHITE_FORFEIT_WIN")}>Black forfeits (1F - 0F)</Button>
            <Button className="mt-1" color="gray" onClick={() => onSaveResult("WHITE_FORFEIT_LOSS")}>White forfeits (0F - 1F)</Button>
            <Button className="mt-1" color="gray" onClick={() => onSaveResult("BOTH_FORFEIT_LOSS")}>Both forfeit (0F - 0F)</Button>

            <div className="mt-2 text-sm font-bold">Less than one full move</div>
            <Button className="mt-1" color="gray" onClick={() => onSaveResult("WHITE_TOO_SHORT")}>White won, insufficient moves</Button>
            <Button className="mt-1" color="gray" onClick={() => onSaveResult("BLACK_TOO_SHORT")}>Black won, insufficient moves</Button>
            <Button className="mt-1" color="gray" onClick={() => onSaveResult("DRAW_TOO_SHORT")}>Draw, insufficient moves</Button>

            <div className="mt-2 text-sm font-bold">Unusual results</div>
            <Button className="mt-1" color="gray" onClick={() => onSaveResult("HALF_ZERO")}>{"\u00BD"} - 0</Button>
            <Button className="mt-1" color="gray" onClick={() => onSaveResult("ZERO_HALF")}>0 - {"\u00BD"}</Button>
            <Button className="mt-1" color="gray" onClick={() => onSaveResult("ZERO_ZERO")}>0 - 0</Button>

            {(!fideMode || (isBye(pairing.result) && pairing.result !== "PAIRING_ALLOCATED_BYE")) && <>
                <div className="mt-2 text-sm font-bold">Other</div>
                <Button className="mt-1" color="gray" onClick={() => setConfirmingDelete(true)}>Delete pairing</Button>
            </>}
        </div>}
    </Modal>
}
