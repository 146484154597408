import React, {useEffect, useState} from "react";
import {OptionButton} from "../../core/Button/OptionButton";
import {executeRequest} from "../../../util/RequestUtil";
import axios from "axios";
import {findSelfLink} from "../../../util/HateoasUtil";

export const IncludeTournamentNameSetting = ({tournament}) => {

    const [value, setValue] = useState(null);

    const [, setSaving] = useState(false);
    const [, setError] = useState(null);

    const onChanged = (option) => {
        setValue(option);
        executeRequest(axios.post(findSelfLink(tournament) + "/settings/showName", {value: option}), setSaving, setError, () => {});
    }

    useEffect(() => {
        setValue(tournament?.showTournamentName);
    }, [tournament]);

    return <div>
        <div className="mt-4 font-bold">Show tournament name?</div>
        <div className="mt-2 flex flex-row">
            <OptionButton option={true} selectedOption={value} onClick={onChanged} title="Yes" />
            <OptionButton option={false} selectedOption={value} onClick={onChanged} title="No" className="ml-2"  />
        </div>
    </div>
}