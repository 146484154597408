import {connect} from 'react-redux';
import Menu from "./Menu";

const mapStateToProps = (state, ownProps) => ({
    user: true, //selectUser(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Menu);
