import {createSlice} from '@reduxjs/toolkit'

const lightMode = {
    color: {
        yes: "bg-green-600",
        no: "bg-red-600",
        maybe: "bg-orange-500",
    }
}

// const darkMode = {
//     ...lightMode,
// }


const theme = createSlice({
    name: 'theme',
    initialState: {theme: lightMode},
    reducers: {
    },
    extraReducers: {
    }
})

// export const {  } = theme.actions

export default theme.reducer


// Selectors
export const selectTheme = state => state.theme.theme;



