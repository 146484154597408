import React from "react";
import reactCSS from 'reactcss'
import {SketchPicker} from 'react-color'

export const PageColorSelector = ({visible, color, onChange, onClose}) => {

    // const [showPicker, setShowPicker] = useState(false);
    // const [color, setColor] = useState({
    //     r: '241',
    //     g: '112',
    //     b: '19',
    //     a: '1',
    // });

    const styles = reactCSS({
        'default': {
            // color: {
            //     width: '14px',
            //     height: '14px',
            //     borderRadius: '2px',
            //     background: `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`,
            // },
            // swatch: {
            //     // padding: '5px',
            //     // background: '#fff',
            //     borderRadius: '1px',
            //     boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            //     display: 'inline-block',
            //     cursor: 'pointer',
            // },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });

    return (
        <div>
            {/*<div style={ styles.swatch } onClick={ () => setShowPicker(true) }>*/}
            {/*    <div style={ styles.color } />*/}
            {/*</div>*/}
            { visible ? <div style={ styles.popover }>
                <div style={ styles.cover } onClick={onClose}/>
                <SketchPicker color={ color } onChange={ c => onChange(c.rgb) } />
            </div> : null }

        </div>
    );

}
