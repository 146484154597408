import React from "react";
import {PageContainer} from "../../components/layout/PageContainer/PageContainer";
import {Button} from "../../components/core/Button/Button";
import axios from "axios";

const MessagesPage = (props) => {

    // const dispatch = useDispatch();
    //
    // useEffect(() => {
    //     const urlParts = window.location.href.split("/");
    //     const id = urlParts[urlParts.length - 1];
    //     // dispatch(startEditingTournament({uuid: isUUID(id) ? id : null}));
    // }, [dispatch]);

    const randomMessage = () => {
        axios.post("http://localhost:8080/api/adhocEvents/406d2a74-d766-48c4-9846-44a65f0ecb91/messages", {
            type: "NOTIFY_ALL",
            message: "This is the message body"
        })
            .then(res => console.log("success"))
            .catch(err => console.log("error"));
    }

    return <PageContainer>
        Messages

        <Button onClick={randomMessage}>Create random message</Button>
    </PageContainer>
}

export default MessagesPage;
