import {Modal} from "../../components/core/Modal/Modal";
import React from "react";


export const FullPointByeWarningModal = ({visible, onClose, onSave}) => {
    return <Modal
        isOpen={visible}
        saveText="Assign full point bye anyway"
        onSave={onSave}
        onClose={onClose}>
        <div>
            <div><span className="font-bold">Warning:</span> Manually assigning full point byes is allowed, but is deprecated by FIDE</div>
        </div>
    </Modal>
}
