import React, {useEffect, useState} from "react";
import Modal from "../../core/Modal";
import {axiosInstance, executeRequest, toOpenResource} from "../../../util/RequestUtil";
import {findSelfLink} from "../../../util/HateoasUtil";
import {useEventListener} from "../../../util/GeneralUtil";
import {LoadingSpinner} from "../../LoadingSpinner";
import {ErrorUserMessage} from "../../core/UserMessage";
import {selectPairingAllocatedByePoints} from "../../../store/slices/editingTournament";


export const formatResult = (isWhite, result, pairingByePoints = 1) => {
    switch (result) {
        case "WHITE": return isWhite ? "1" : "0";
        case "BLACK": return isWhite ? "0" : "1";
        case "DRAW": return "\u00BD";
        case "WHITE_TOO_SHORT": return isWhite ? "1" : "0";
        case "BLACK_TOO_SHORT": return isWhite ? "0" : "1";
        case "DRAW_TOO_SHORT": return "\u00BD";
        case "FULL_BYE": return "1";
        case "PAIRING_ALLOCATED_BYE":
            return pairingByePoints === 0.5 ? "\u00BD" : "" + pairingByePoints;
        case "HALF_BYE": return "\u00BD";
        case "ZERO_BYE": return "0";
        case "WHITE_FORFEIT_WIN": return isWhite ? "1F" : "0F";
        case "WHITE_FORFEIT_LOSS": return isWhite ? "0F" : "1F";
        case "BOTH_FORFEIT_LOSS": return isWhite ? "0F" : "0F";
        case "BOTH_LOSE": return "0";
        case "HALF_ZERO": return isWhite ? "\u00BD" : "0";
        case "ZERO_HALF": return isWhite ? "0" : "\u00BD";
        case "ZERO_ZERO": return "0";
        default: return "-";
    }
}

export const PlayerResultsModal = ({tournament, player, onClose, view}) => {

    const [result, setResult] = useState(null);

    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);

    const pairingByePoints = selectPairingAllocatedByePoints(tournament);

    useEffect(() => {

        setResult(null);

        if (!player?.uuid) {
            return;
        }

        executeRequest(axiosInstance(view).get(toOpenResource(findSelfLink(tournament) + "/members/" + player.uuid + "/results", view)), setFetching, setError, data => {
            setResult(data);
        })

    }, [player, tournament, view]);

    const handleKeyPress = event => {
        if (event.keyCode === 27) {
            onClose();
        }
    }

    useEventListener('keydown', handleKeyPress);

    return <Modal isOpen={player} onClose={onClose}>


        <div className="text-center">
            <div className="text-baseline font-bold">{result?.name} ({result?.rating}): {player?.points} points</div>
            <div className="text-sm mt-2 font-light">
                <table>
                    <thead>
                        <tr>
                            <td>Rd.</td>
                            <td>Opponent</td>
                            <td>Rating</td>
                            <td>Color</td>
                            <td className="px-2">Result</td>
                        </tr>
                    </thead>
                    <tbody>
                    {result?.pairings.map(p => {

                        const isWhite = result.name === p.white.name;
                        const opponent = isWhite ? p.black : p.white;

                        return <tr>
                            <td className="border border-gray-500 pr-2">{p.roundName}</td>
                            <td className="border border-gray-500 text-left font-medium px-4 ">{opponent.name}</td>
                            <td className="border border-gray-500 text-center px-4 ">{opponent.rating}</td>
                            {isWhite && <td className="border border-gray-500 text-right px-2 bg-white "/>}
                            {!isWhite && <td className="border border-gray-500 text-right px-2 bg-black "/>}
                            <td className="px-1 bg-gray-800 text-white font-medium ">{formatResult(isWhite, p.result, pairingByePoints)}</td>
                            {/*<td className={"border border-gray-500 text-left px-2 " + (result.name === p.black.name ? "font-medium" : "")}>{p.black.name} ({p.black.rating})</td>*/}
                        </tr>
                    })}
                    </tbody>
                </table>
            </div>
            <LoadingSpinner visible={fetching} title="Loading..." className="mt-2" />
            <ErrorUserMessage visible={error} message="Error loading" className="mt-2" />
        </div>
    </Modal>

}
