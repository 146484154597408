import {Modal} from "../../components/core/Modal/Modal";
import {InputTextField} from "../../components/core/InputTextField/InputTextField";
import {ErrorUserMessage} from "../../components/core/UserMessage";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import React, {useEffect, useState} from "react";
import {SwitchField} from "../../components/core/SwitchField";
import {findSelfLink} from "../../util/HateoasUtil";
import axios from "axios";

export const ManagePlayerModal = ({visible, membership, onClose}) => {

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {

        setName(membership?.name ?? "");
        setEmail(membership?.email ?? "");
        setIsAdmin(membership?.organiser ?? false);

        setError(null);
        setSaving(false);
    }, [membership])

    const onSave = () => {

        const data = {
            name: name,
            email: email,
            organiser: isAdmin,
            metadata: {}
        }

        setSaving(true);
        setError(null);
        axios.post(findSelfLink(membership), data)
            .then(() => onClose(true))
            .catch(() => setError("Error saving"))
            .finally(() => setSaving(false));
    }


    return <Modal
        isOpen={visible}
        title={name}
        saveText="Save"
        onSave={onSave}
        onClose={() => onClose(false)}>

        <div>

            <SwitchField label="Can manage club / team" value={isAdmin} onChange={setIsAdmin} className="mt-2" />

            <InputTextField label="Name" onChange={setName} value={name} className="mt-2" />
            <InputTextField label="Email" onChange={setEmail} value={email} className="mt-2" />

            {/*<div>*/}

            {/*    <Switch*/}
            {/*        checked={isAdmin}*/}
            {/*        onChange={onSetIsAdmin}*/}
            {/*        name="admin"*/}
            {/*        color="primary"*/}
            {/*    />*/}
            {/*</div>*/}

            <ErrorUserMessage visible={error} message={error} className="mt-2" />
            <LoadingSpinner visible={saving} title="Saving..." compact={true} className="mt-4" />
        </div>
    </Modal>
}
