import React, {isValidElement, useEffect, useState} from "react";

const firstChild = (children) => {
    return React.Children.map(children, child => child).filter(child => isValidElement(child))[0];
}

const TabHeading = ({name, selected, onClick}) => {
    const classes = selected ?
        "bg-white inline-block border-l border-t border-r border-gray-400 py-2 px-4 text-gray-800 font-semibold" :
        "bg-gray-400 inline-block py-2 px-4 text-gray-600 hover:text-blue-900 font-medium cursor-pointer";

    return <li className="-mb-px mr-1 ">
        <span className={classes} onClick={onClick}>
            {name}
        </span>
    </li>
}

export const Tab = ({visible, children}) => {
    return visible ? <>{children}</> : null;
}

export const TabSet = ({children, defaultTab}) => {


    const [selectedTabName, setSelectedTabName] = useState(firstChild(children).props.name);

    const isSelected = t => selectedTabName === t.props.name;
    const name = t => t.props.name;

    useEffect(() => {

        if (defaultTab) {
            setSelectedTabName(defaultTab);
        }

    }, [defaultTab]);

    return <>
        <div className="mt-4">
            <ul className="list-reset flex border-b list-none m-0 p-0">
                {React.Children.map(children, t => {

                    return isValidElement(t) ? <TabHeading
                        name={name(t)}
                        selected={isSelected(t)}
                        onClick={() => setSelectedTabName(name(t))} /> : null;
                } )}
            </ul>
        </div>

        {React.Children.map(
            children,
            child => {

                if (!isValidElement(child)) {
                    return null;
                }

                return React.cloneElement(child, {visible: isSelected(child)})
            })}
    </>
}
