import React from "react";
import {PageContainer} from "../components/layout/PageContainer/PageContainer";

export const PrivacyPolicyPage = () => {
    return <PageContainer>

        <div className="flex align-middle justify-center content-center">

        <div className="font-light p-4 w-6/12">

            <div className="text-3xl mb-2">MoveKeep Privacy Policy</div>
            <p>We take your privacy seriously.</p>

            <p className="mt-2">This privacy policy is intended to help you understand what data we collect, why we collect it, how we manage your data, and what you can do to update or delete your data.</p>

            <div className="text-2xl mb-2 mt-4">User accounts</div>
            MoveKeep creates an account for all users who install MoveKeep. Even if you do not log in using an authentication provider, we will create an account associated with your device. If you log in using an authentication provider, we will link your device’s account with identity information passed to us by the authentication provider on your behalf. This information may include your name, profile picture, email address and phone number.

            <div className="text-2xl mb-2 mt-4">Security</div>
            All requests from your device to our servers are made over secure SSL connections to prevent unauthorised third parties from intercepting your data. All data we collect is stored in a private network that cannot be directly accessed from the internet. All requests for data pass through our application servers that enforce access control restrictions.


            <div className="text-2xl mb-2 mt-4">Data we collect</div>

            <div className="text-lg font-medium mb-2 mt-4">Analytics</div>
            <p>MoveKeep collects anonymous analytics to help us understand how people use the MoveKeep app. This includes information about how much time you spend on each screen in the app, and which user interface controls you interact with.</p>

            <p className="mt-2">Our analytics provider supplies us with aggregated demographic data.</p>

            <p className="mt-2">Analytic data is used to understand which features are used, which features are underutilised, and help us detect parts of the interface that might be causing confusion for users.</p>


            <div className="text-lg font-medium mb-2 mt-4">Metadata</div>

            <p>While using MoveKeep, your device will make requests to our servers for various purposes including (but not limited to) fetching/updating tournament information and managing your teams / clubs.</p>

            <p className="mt-2">We collect metadata from these requests including the time, your identity token, IP address, and information relating to your phone make and model.</p>

            <p className="mt-2">We collect this metadata to help us maintain the security of the system and help diagnose any bugs and issues that may be reported to us. </p>


            <div className="text-lg font-medium mb-2 mt-4">Data you supply to us</div>
            We will store data you supply to us, or that your authentication provider supplies to us on your behalf. This data includes your name and profile picture and your preferences..


            <div className="text-lg font-medium mb-2 mt-4">Data sharing</div>
            <p>In addition to cases listed above, MoveKeep may share data we hold about you with law enforcement agencies in cases where we are required by law to share your information.</p>

            <p className="mt-2">We will share anonymised data with third parties when required to deliver the service to you. For example, we share route request parameters with our routing partners. We share text you type into the search box with our place search provider.</p>


            <div className="text-lg font-medium mb-2 mt-4">Deleting data</div>
            You may request that we delete data relating to your account by emailing support@movekeep.com. We will either delete or anonymize any data we hold about you.


            <div className="text-lg font-medium mb-2 mt-4">Contacting us</div>
            If you have any questions or concerns about your privacy or believe your privacy may have been compromised,
            you may contact us at <a href="mailto:support@movekeep.com">support@movekeep.com</a>

        </div>
        </div>
    </PageContainer>
}
