import {useDispatch, useSelector} from "react-redux";
import {selectIsAnonymousUser, selectLoggedInUser, selectProfileName} from "../../store/slices/authentication";
import {InputTextField} from "../core/InputTextField/InputTextField";
import {ErrorUserMessage} from "../core/UserMessage";
import {LoadingSpinner} from '../LoadingSpinner';
import {Modal} from "../core/Modal/Modal";
import React, {useEffect, useState} from "react";
import {
    reset,
    selectUsernamePromptError,
    selectUsernamePromptSaving,
    setError,
    updateName
} from "./usernamePromptModalSlice";

export const UsernamePromptModal = () => {

    const dispatch = useDispatch();

    const [name, setName] = useState("");

    const savedName = useSelector(selectProfileName);
    const error = useSelector(selectUsernamePromptError);
    const saving = useSelector(selectUsernamePromptSaving);

    const loggedIn = useSelector(selectLoggedInUser);
    const anonymousAuth = useSelector(selectIsAnonymousUser);

    useEffect(() => {dispatch(reset())}, [dispatch]);

    const onSave = () => {
        if (name == null || name.length === 0) {
            dispatch(setError("Name cannot be blank"));
        } else {
            dispatch(updateName(name));
        }
    }

    if (!loggedIn || anonymousAuth) {
        return null;
    }

    if (savedName != null && savedName.trim().length > 0) {
        return null;
    }

    console.log("Error: ", error);

    return <Modal title="Name Required" isOpen={true} onSave={onSave}>
        <InputTextField label="What is your name?" onChange={setName} value={name} />
        <ErrorUserMessage visible={error} message={error} />
        <LoadingSpinner visible={saving} title="Updating name" />
    </Modal>;
}
