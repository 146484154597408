import React from "react";


export const Lozenge = ({title, color, compact = false, children}) => {
    const classes = ["whitespace-no-wrap", "text-white", "font-bold", "rounded-full", "inline"];

    if (compact) {
        classes.push("text-xs", "py-1", "px-2");
    } else {
        classes.push("py-2", "px-4");
    }

    switch(color) {
        case "gray":
            classes.push("bg-gray-600");
            break;
        case "red":
            classes.push("bg-red-600");
            break;
        case "green":
            classes.push("bg-green-600");
            break;
        case "orange":
            classes.push("bg-orange-500");
            break;
        default:
            if (color) {
                classes.push(color)
            } else {
                classes.push("bg-gray-600");
            }
    }

    return <div className={classes.join(" ")}>
        {title}
        {children}
    </div>
}
