import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {findLink, findSelfLink} from "../../util/HateoasUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {LoadingSpinner} from "../LoadingSpinner";
import {ErrorUserMessage} from "../core/UserMessage";

export const AssignCustomRoleOption = ({membership, role, currentRoles}) => {

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const [hasRole, setHasRole] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onToggle = useCallback(() => {

        const adding = !hasRole;
        const customRoleUri = findSelfLink(role);

        setSaving(true);
        setError(null);
        axios.post(findLink(membership, "customRoles"), {adding, customRoleUri})
            .then(() => setHasRole(adding))
            .catch(() => setError("Error updating assignment"))
            .finally(() => setSaving(false));
    }, [hasRole, membership, role]);

    useEffect(() => {
        setHasRole(currentRoles.filter(curr => curr.uuid === role.uuid).length > 0);
    }, [currentRoles, role]);

    return <div onClick={onToggle}
                className={"shadow-lg p-2 flex flex-row mb-2 " + (hasRole ? "bg-green-700 text-white" : "bg-gray-300 text-gray-600")}>
        {hasRole && <FontAwesomeIcon icon={faCheckCircle} className="mr-2 mt-1" />}
        {role.name}
        <LoadingSpinner size={20} visible={saving} compact={true} title={null} />
        <ErrorUserMessage visible={error} message={"Updating assignment"} className="ml-2" />
    </div>
}
