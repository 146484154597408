import React from "react";
import {PageContainer} from "../../components/layout/PageContainer/PageContainer";


export const FidePage = () => {


    return <PageContainer>
        <div>FIDE options</div>

    </PageContainer>
}
