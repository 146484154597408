import React, {useCallback, useEffect, useState} from "react";
import Select from 'react-select'
import {useSelector} from "react-redux";
import {findSelfLink} from "../../../util/HateoasUtil";
import {FieldLabel} from "../../core/FieldLabel/FieldLabel";
import axios from "axios";
import {selectServerUri} from "../../../store/reducers/config";
import {addPaginationParams} from "../../../util/RequestUtil";
import {ErrorUserMessage} from "../../core/UserMessage";


export const ClubSelector = ({editMode = true, selectedClubUri, onClubSelected, tournament}) => {

    const [clubs, setClubs] = useState([]);
    const [processedClubs, setProcessedClubs] = useState([]);

    const [fetchingClubs, setFetchingClubs] = useState(false);
    const [errorFetchingClubs, setErrorFetchingClubs] = useState(null);

    const serverUri = useSelector(selectServerUri);

    const fetchClubs = useCallback((value) => {
        setFetchingClubs(true);
        setErrorFetchingClubs(null);
        axios.get(serverUri + addPaginationParams("/api/clubs?relationship=MANAGING&search=" + value, 0))
            .then(res => setClubs(res.data.page))
            .catch(() => setErrorFetchingClubs("Error fetching club list"))
            .finally(() => setFetchingClubs(false));
    }, [serverUri]);

    // const fetchClub = (uri) => {
    //     setFetchingClub(true);
    //     setErrorFetchingClub(null);
    //     axios.get(uri)
    //         .then(res => setSelectedClub(res.data))
    //         .catch(() => setErrorFetchingClub("Error fetching selected club"))
    //         .finally(() => setFetchingClub(false))
    // }

    useEffect(() => {
        setProcessedClubs(clubs.map(c => ({
            value: findSelfLink(c),
            label: c.name,
            club: c
        })));
    }, [clubs]);

    // useEffect(() => {
    //     if (selectedClubUri && !editMode) {
    //         fetchClub(selectedClubUri);
    //     }
    //
    // }, [editMode, selectedClubUri])

    useEffect(() => {
        if (editMode) {
            fetchClubs("");
        }
    }, [fetchClubs, editMode]);

    const onSelected = (val, {action}) => {
        if (action === "select-option") {
            onClubSelected(val.club);
        }
    }

    return <div className="flex flex-row items-center">
        <div style={{width: "150px"}}>
            <FieldLabel label="Club" />
        </div>
        <Select
            placeholder="Club..."
            noOptionsMessage={() => ""}
            isLoading={fetchingClubs}
            className="w-64"
            value={processedClubs.filter(p => p.value === selectedClubUri)}
            options={processedClubs}
            onChange={onSelected}
            onInputChange={fetchClubs} />
        <ErrorUserMessage visible={errorFetchingClubs} message="Error fetching clubs" />
    </div>

    // if (editMode) {
    //
    // } else {
    //     return <div>
    //         <ReadonlyField name="Club" value={tournament?.clubName ?? ""} />
    //         <LoadingSpinner compact={true} visible={fetchingClub} title={"Fetching club"} />
    //         <ErrorUserMessage visible={errorFetchingClub} message="Error fetching club" />
    //     </div>
    // }
}
