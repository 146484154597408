import Button from "../../../../components/core/Button";
import React from "react";

export const RetryPairingButton = ({earlierRoundsHaveUnpaired, onClick}) => {

    const canRetry = !earlierRoundsHaveUnpaired;

    return <>
        <Button compact={true} disabled={!canRetry} color="blue" onClick={canRetry ? onClick : () => {}}>Generate pairings</Button>
        {!canRetry && <div className="text-red-700 font-light text-sm my-2">
            Cant generate pairings while earlier rounds are incomplete.
        </div>}
    </>
}
