import {PAGE} from "../../App";

export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

const initialState = {
    currentPage: null,
};

export const userContext = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_PAGE:
            return  {
                ...state,
                currentPage: action.page
            };
        default:
            return state;
    }
};

export const selectCurrentPage = (state) => state.userContext.currentPage;
export const selectIsStaticContent = (state) => {
    const currentPage = selectCurrentPage(state);

    return currentPage === "PRIVACY" || currentPage === "TOS";
}


export const selectIsOnLoginPage = state => selectCurrentPage(state) === PAGE.LOGIN.menuLocation;

