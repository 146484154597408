import React, {useEffect, useState} from "react";
import {executeRequest} from "../../../util/RequestUtil";
import axios from "axios";
import {findSelfLink} from "../../../util/HateoasUtil";
import {OptionButton} from "../../core/Button/OptionButton";

export const DefaultTabSetting = ({tournament}) => {

    const [value, setValue] = useState(null);

    const [, setSaving] = useState(false);
    const [, setError] = useState(null);

    const onChanged = (option) => {
        setValue(option);
        executeRequest(axios.post(findSelfLink(tournament) + "/settings/defaultTab", {value: option}), setSaving, setError, () => {});
    }

    useEffect(() => {
        setValue(tournament?.defaultTab);
    }, [tournament]);

    return <div>
        <div className="mt-4 font-bold">Default tab</div>
        <div className="mt-2 flex flex-row">
            {/*<OptionButton option="Details" selectedOption={value} title="Details" onClick={onChanged} />*/}
            <OptionButton option="Players" selectedOption={value} title="Players" className="ml-2" onClick={onChanged} />
            <OptionButton option="Pairings" selectedOption={value} title="Pairings" className="ml-2" onClick={onChanged} />
            <OptionButton option="Crosstable" selectedOption={value} title="Crosstable" className="ml-2" onClick={onChanged} />
        </div>
    </div>
}