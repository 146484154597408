import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {findLink} from "../../util/HateoasUtil";
import {CustomRoleTile} from "./CustomRoleTile";
import {Button} from "../core/Button/Button";
import {CustomRoleModal} from "./CustomRoleModal";
import {ErrorUserMessage} from "../core/UserMessage";
import {LoadingSpinner} from "../LoadingSpinner";

export const CustomRoleList = ({parent, editMode}) => {

    const [roles, setRoles] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);

    const [showEditModal, setShowEditModal] = useState(false);
    const [roleToUpdate, setRoleToUpdate] = useState(null);

    const onShowEditModal = () => setShowEditModal(true);
    const onHideEditModal = () => setShowEditModal(false);

    const onEditRole = (role) => {
        setRoleToUpdate(role);
        onShowEditModal();
    }

    const fetchRoles = useCallback(() => {
        setError(null);
        setFetching(true);
        axios.get(findLink(parent, "customRoles"))
            .then(res => setRoles(res.data.roles))
            .catch(() => setError("Error fetching roles"))
            .finally(() => setFetching(false))
    }, [parent]);

    useEffect(() => {
        fetchRoles();
    }, [fetchRoles]);

    return <div className="bg-gray-100 h-full">
        {editMode && <div className="pt-2 text-center">
            <Button onClick={onShowEditModal}>Add Job / Role</Button>
            <CustomRoleModal visible={showEditModal} parent={parent} editing={roleToUpdate} onUpdated={fetchRoles} onClose={onHideEditModal} />
        </div>}

        <div className="m-2">
            {roles.map(r => <CustomRoleTile onClick={editMode ? () => {onEditRole(r)} : null} role={r} />)}
        </div>

        <ErrorUserMessage visible={error} message={error} className="mt-2" />

        <LoadingSpinner visible={fetching} title="Fetching..." compact={true} className="mt-4" />
    </div>
}
