import {Modal} from "../../../components/core/Modal/Modal";
import {InputTextField} from "../../../components/core/InputTextField/InputTextField";
import {LoadingSpinner} from "../../../components/LoadingSpinner";
import {ErrorUserMessage} from "../../../components/core/UserMessage";
import React, {useState} from "react";
import {isBlank} from "../../../util/GeneralUtil";
import axios from "axios";
import {findLink} from "../../../util/HateoasUtil";

export const ManageClubTeamsModal = ({club, visible, onCreated, onClose}) => {

    const [name, setName] = useState("");

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const onCreate = () => {

        if (isBlank(name)) {
            setError("Name cannot be blank");
            return;
        }

        setError(null);
        setSaving(true);
        axios.post(findLink(club, "teams"), {name})
            .then(() => {
                setName("");
                onCreated();
            })
            .catch(() => setError("Error creating club"))
            .finally(() => setSaving(false));
    }

    const onUpdateName = (text) => {
        if (!isBlank(text)) {
            setError(null);
        }
        setName(text);
    }

    return <Modal
        isOpen={visible}
        title={"Create team"}
        saveText="Create"
        onSave={onCreate}
        onClose={onClose}>
        <div className="flex flex-col">

            <InputTextField label="Team name" onChange={onUpdateName} value={name} />

            <LoadingSpinner visible={saving} title="Creating..." compact={true} className="mt-2" />
            <ErrorUserMessage visible={error} message={error} className="mt-2" />
        </div>
    </Modal>

}
