import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Button} from "./core/Button/Button";
import React from "react";

export const DeleteButton = ({onClick, className = "", readonly = false}) => {
    return <Button
        compact={true}
        className={className}
        onClick={readonly ? null : onClick}
        color={readonly ? "gray" : "red"}
        icon={faTrash} />
}
