import {useSlate} from "slate-react";
import {Button, Icon} from "../../pages/PageEditor/editorComponents";
import React, {useState} from "react";
import {PageColorSelector} from "../../pages/PageEditor/PageColorSelector";
import {Editor, Element as SlateElement, Transforms} from "slate";


const DEFAULT_TEXT_COLOR = {
    r: '0',
    g: '0',
    b: '0',
    a: '1',
};


const LIST_TYPES = ['numbered-list', 'bulleted-list']
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

const objectToRgba = color => `rgba(${ color.r }, ${ color.g }, ${ color.b }, ${ color.a })`;



const getMarkValue = (editor, format, defaultValue) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] ?? defaultValue : defaultValue
}



const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
    )
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            !Editor.isEditor(n) &&
            SlateElement.isElement(n) &&
            LIST_TYPES.includes(n.type) &&
            !TEXT_ALIGN_TYPES.includes(format),
        split: true,
    })
    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(format)) {
        newProperties = {
            align: isActive ? undefined : format,
        }
    } else {
        newProperties = {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        }
    }
    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

export const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const setMark = (editor, format, value) => {
    Editor.removeMark(editor, format)
    Editor.addMark(editor, format, value)
}


const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor
    if (!selection) return false

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                n[blockType] === format,
        })
    )

    return !!match
}

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor)
    return marks ? marks[format] === true : false
}

export const Element = ({ attributes, children, element }) => {
    const style = { textAlign: element.align }
    switch (element.type) {
        case 'block-quote':
            return (
                <blockquote style={style} {...attributes}>
                    {children}
                </blockquote>
            )
        case 'bulleted-list':
            return (
                <ul style={style} {...attributes}>
                    {children}
                </ul>
            )
        case 'heading-one':
            return (
                <h1 style={style} {...attributes}>
                    {children}
                </h1>
            )
        case 'heading-two':
            return (
                <h2 style={style} {...attributes}>
                    {children}
                </h2>
            )
        case 'list-item':
            return (
                <li style={style} {...attributes}>
                    {children}
                </li>
            )
        case 'numbered-list':
            return (
                <ol style={style} {...attributes}>
                    {children}
                </ol>
            )
        default:
            return (
                <p style={style} {...attributes}>
                    {children}
                </p>
            )
    }
}

export const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    const textColor = leaf.text_color ?? DEFAULT_TEXT_COLOR;

    return <span {...attributes} style={{color: objectToRgba(textColor)}}>{children}</span>
}

export const BlockButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isBlockActive(
                editor,
                format,
                TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
            )}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    )
}

export const MarkButton = ({ format, icon }) => {
    const editor = useSlate()
    return (
        <Button
            active={isMarkActive(editor, format)}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <Icon>{icon}</Icon>
        </Button>
    )
}


export const TextColorButton = ({ format, icon }) => {

    const [showPicker, setShowPicker] = useState(false);

    const editor = useSlate()

    const color = getMarkValue(editor, format, DEFAULT_TEXT_COLOR)

    return (
        <>
            <Button
                active={isMarkActive(editor, format)}
                onMouseDown={event => {
                    event.preventDefault()
                    setShowPicker(true)
                }}
            >
                <Icon style={{color: objectToRgba(color)}}>{icon}</Icon>
                <PageColorSelector
                    visible={showPicker}
                    onClose={() => setShowPicker(false)}
                    color={color}
                    onChange={rgba => setMark(editor, format, rgba)}/>
            </Button>

            {/*{ showPicker ? <div style={ styles.popover }>*/}
            {/*    <div style={ styles.cover } onClick={ () => setShowPicker(false) }/>*/}
            {/*    <SketchPicker color={ color } onChange={ c => setMark(editor, format, c.rgb) } />*/}
            {/*</div> : null }*/}
        </>
    )
}
