import React from "react";

export const RadioButton = ({group, selectedValue, value, description, onClick}) => {
    // if (selectedValue === value) {
    //     return <><input type="radio" value={value} name={group} checked={selectedValue === value}/> {description}</>
    // } else {
    //     return <><input type="radio" value={value} name={group}/> {description}</>
    // }

    return <><input type="radio" value={value} name={group} checked={selectedValue === value} onClick={() => onClick(value)}/> {description}</>;
}
