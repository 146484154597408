import React from "react";
import {StandardCrosstableView} from "./StandardCrosstableView";
import {TeamVsTeamCrosstable} from "./TeamVsTeamCrosstable";
import {tournamentSize} from "../../../util/GeneralUtil";

export const CrossTable = ({tournament, viewMode = false}) => {

    return tournament.type === "TEAM_VS_TEAM" ?
        <TeamVsTeamCrosstable tournament={tournament} viewMode={viewMode} /> :
        <StandardCrosstableView tournament={tournament} viewMode={viewMode} />
}


export const Cell = ({className = "", view, children, size, onClick, bgColor = "bg-white"}) => {
    return <td onClick={onClick} className={"hover:underline border-solid border-gray-500 text-center " + className + " " + bgColor + " " + sizeClasses(view, size)}>
        {children}
    </td>
}


export const sizeClasses = (view, size) => {

    return tournamentSize(view, size, "text-xs p-1 border", "text-sm p-2 border", "text-base p-2 border");
}
