import React, {useEffect, useState} from "react";
import {EditRsvpModal} from "./EditRsvpModal";
import {RsvpBaseButton} from "./RsvpBaseButton";
import {rsvpStatus} from "../../util/EntityUtil";

export const RsvpButton = ({entity, onUpdated, className, editable = false}) => {

    const [showModal, setShowModal] = useState(false);

    const [status, setStatus] = useState(null);

    const onShowModal = () => setShowModal(true);
    const onHideModal = () => setShowModal(false);

    useEffect(() => {
        setStatus(rsvpStatus(entity));
    }, [entity])

    return status ? <span className={className}>
        <EditRsvpModal visible={showModal} entity={entity} onUpdated={onUpdated} onClose={onHideModal} />
        <RsvpBaseButton editable={editable} onClick={onShowModal} status={status} />
    </span> : null;
}
