import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {selectServerUri} from "../../store/reducers/config";
import axios from "axios";
import {query} from "../../util/RequestUtil"

const initialState = {
    analysingUsername: null,

    startAnalysisRunning: false,
    startAnalysisError: null,

    fetchAnalysisRunning: false,
    fetchAnalysisError: null,

    games: null
}


export const startAnalysis = createAsyncThunk(
    'gameAnalysisPage/startAnalysis',
    async (arg, {dispatch, getState}) => {

        dispatch(setAnalysingUsername(arg));

        const data = {
            username: arg,
            synchronous: true,
        }

        return await query(axios.post(selectServerUri(getState()) + "/api/analysis", data));
    });

export const fetchAnalysis = createAsyncThunk(
    'gameAnalysisPage/fetchAnalysis',
    async (arg, {dispatch, getState}) => {
        const username = selectAnalysingUsername(getState());
        return await query(axios.get(selectServerUri(getState()) + "/api/analysis/results/" + username));
    });

const gameAnalysisPage = createSlice({
    name: 'gameAnalysisPage',
    initialState: initialState,
    reducers: {
        setAnalysingUsername: (state, action) => {
            state.analysingUsername = action.payload;
        },
    },
    extraReducers: {
        [startAnalysis.pending]: (state, action) => {
            state.startAnalysisRunning = true;
        },
        [startAnalysis.fulfilled]: (state, action) => {
            state.games = action.payload.games;
            state.startAnalysisRunning = false;
        },
        [startAnalysis.rejected]: (state, action) => {
            state.startAnalysisRunning = false;
            state.startAnalysisError = "Error starting analysis";
        },

        [fetchAnalysis.pending]: (state) => {
            state.fetchAnalysisRunning = true;
        },
        [fetchAnalysis.fulfilled]: (state, action) => {
            state.games = action.payload.games;
            state.fetchAnalysisRunning = false;
        },
        [fetchAnalysis.rejected]: (state) => {
            state.fetchAnalysisRunning = false;
            state.fetchAnalysisError = "Error starting analysis";
        },
    }
})

export const { setAnalysingUsername } = gameAnalysisPage.actions

export default gameAnalysisPage.reducer


// Selectors
export const selectAnalysingUsername = state => state.gameAnalysisPage.analysingUsername;
export const selectAnalysingGames = state => state.gameAnalysisPage.games;


