import React, {useState} from "react";
import PageContainer from "../../components/layout/PageContainer";
import {Button} from "../../components/core/Button/Button";
import MyClubsList from "./MyClubsList";
import CreateClubModal from "./CreateClubModal";

export const ClubsHomePage = ({history}) => {

    const [creating, setCreating] = useState(false);

    return <PageContainer>
        <Button onClick={() => setCreating(true)}>Create Club</Button>
        <div>
            <MyClubsList history={history} />
        </div>
        <CreateClubModal
            visible={creating}
            onClose={() => setCreating(false)}
            history={history} />
    </PageContainer>
}

export default ClubsHomePage;
