import {CircleLoader} from "react-spinners";
import {Button} from "./Button";
import React from "react";

export const SaveButton = ({title, disabled, saving, onSave, ...props}) => {
    return <Button disabled={disabled || saving} onClick={onSave} {...props}>
        <CircleLoader
            loading={saving}
            size={25}
            color={"#4a148c"} />
        <span className={saving ? "ml-10" : ""}>{title}</span>
    </Button>
}
