import React, {useEffect, useRef, useState} from "react";
import {RoundResults} from "./RoundResults";
import {findLink, findSelfLink} from "../../../util/HateoasUtil";
import {RoundSelector} from "./RoundSelector";
import {RoundEditOptions} from "./RoundEditOptions";
import {axiosInstance, toOpenResource} from "../../../util/RequestUtil";

export const ResultsEditor = ({tournament, viewMode = false}) => {

    const [, setEditingRound] = useState(false);

    const [selectedRound, setSelectedRound] = useState(0);
    const [rounds, setRounds] = useState(null);

    const hasSetSelectedRound = useRef(false);

    const fetchRounds = () => {
        axiosInstance(viewMode).get(toOpenResource(findLink(tournament, "rounds"), viewMode))
            .then(res => {
                setRounds(res.data.rounds);
                if (rounds == null || rounds.length !== res.data.rounds.length) {

                    hasSetSelectedRound.current = true;

                    for (let i = 0; i < res.data.rounds.length; i++) {
                        const curr = res.data.rounds[i];
                        if (curr.status !== "COMPLETE") {

                            if (viewMode && !curr.hasNonByePairings && i > 0) {
                                // view mode wants to see the last round where stuff happened
                                // non view / managing mode wants to see where they need to do stuff
                                setSelectedRound(i - 1);
                            } else {
                                setSelectedRound(i);
                            }

                            return;
                        }
                    }

                    const incomplete = res.data.rounds.filter(r => !r.hasNonByePairings);

                    if (incomplete.length > 0) {
                        setSelectedRound(Math.max(0, incomplete[0].order - (viewMode ? 1 : 1)));
                    } else {
                        setSelectedRound(res.data.rounds.length - 1);
                    }
                }
            });
    }

    const onRoundChanged = idx => setSelectedRound(idx);
    const onRoundMarkedComplete = () => fetchRounds();

    useEffect(fetchRounds, [findSelfLink(tournament)]);


    return <div className="p-4 pt-2 w-full bg-white shadow-lg">
        <RoundSelector
            tournament={tournament}
            viewMode={viewMode} rounds={rounds} selectedRound={selectedRound} onRoundChanged={onRoundChanged} />

        <RoundResults
            tournament={tournament}
            onRefreshRound={fetchRounds}
            round={rounds?.[selectedRound]}
            viewMode={viewMode} />
        <RoundEditOptions
            tournament={tournament}
            rounds={rounds}
            selectedRoundIdx={selectedRound}
            onMarkedComplete={onRoundMarkedComplete}
            onEditRound={() => setEditingRound(true)}/>
    </div>
}
