import React, {useCallback, useEffect, useState} from "react";
import {AddPlayer} from "../../components/AddPlayer";
import {findLink} from "../../util/HateoasUtil";
import axios from "axios";
import {EditableTableCell} from "../../components/core/table/EditableTableCell";
import {EditButton} from "../../components/EditButton";
import {Lozenge} from "../../components/core/Lozenge/Lozenge";
import {ManagePlayerModal} from "./ManagePlayerModal";
import {RefreshButton} from "../../components/RefreshButton";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import {ErrorUserMessage} from "../../components/core/UserMessage";
import {LoggedInUserLozenge} from "../../components/LoggedInUserLozenge";
import {FieldLabel} from "../../components/core/FieldLabel/FieldLabel";

const MemberRoleLozenge = ({member}) => {

    let title = null;
    let color = null;

    if (member.admin) {
        title = "Admin";
        color = "green";
    } else if (member.organiser) {
        title = "Manager";
        color = "orange";
    }

    return title ? <span className="ml-2"><Lozenge title={title} color={color} compact={true} /></span> : null;
}

export const ManageClubPlayers = ({club, editMode}) => {

    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);

    const [players, setPlayers] = useState(null);

    const [editingPlayer, setEditingPlayer] = useState(null);

    const appendPlayer = player => {
        setPlayers([...players, player]);
    }

    const loadPlayers = useCallback(() => {
        setFetching(true);
        setError(null);
        axios.get(findLink(club, "members").replace("{?team}", ""))
            .then(res => setPlayers(res.data.filter(m => m.memberType === "USER")))
            .catch(err => setError("Error loading members"))
            .finally(() => setFetching(false));
    }, [club]);

    const onEditPlayerEnded = didEdit => {
        setEditingPlayer(null);
        if (didEdit) {
            loadPlayers();
        }
    }

    useEffect(loadPlayers, [club]);

    return <div className="p-4 w-full bg-white mb-4">
        <ManagePlayerModal visible={editingPlayer != null} onClose={onEditPlayerEnded} membership={editingPlayer} />


        <FieldLabel label="Add member" />
        <AddPlayer placeholder="Member name..." entity={club} entityName="member" onPlayerAdded={appendPlayer} />

        <div className="flex flex-row mt-4">
            <RefreshButton onClick={loadPlayers}/>
            <LoadingSpinner compact={true} visible={fetching} title={"Refreshing members"} className="ml-2" />
            <ErrorUserMessage visible={error} message={error} className="mt-2" />
        </div>

        <table className="table-auto bg-white mt-2">
            <thead>
            <tr>
                <th className="px-4 py-2 border border-gray-500 ">Name</th>
                <th className="px-4 py-2 border border-gray-500 ">Email</th>
                <th className="px-4 py-2 border border-gray-500 ">Rating</th>
                <th className="px-4 py-2 border border-gray-500 ">Registered?</th>
                {editMode && <th className="border border-gray-500 "/>}
                {/*<th className="px-4 py-2">Rating</th>*/}
            </tr>
            </thead>
            <tbody>
            {players?.map(p => <tr key={p.uuid}>
                <EditableTableCell
                    editMode={editMode}
                    entity={p}
                    field={"name"}>
                    <MemberRoleLozenge member={p} />
                    <LoggedInUserLozenge loggedInUser={p.loggedInUser} />
                </EditableTableCell>
                <EditableTableCell
                    editMode={editMode}
                    entity={p}
                    field={"email"}/>
                <EditableTableCell
                    entity={p}
                    field={"rating"}/>
                <td className="border px-4 py-2 text-center border border-gray-500 ">
                    {p.hasUser ? <Lozenge compact={true} color="green" title="Yes" /> : <Lozenge compact={true} color="red" title="No" />}
                </td>
                {/*<EditableTableCell*/}
                {/*    entity={p}*/}
                {/*    field={"rating"}/>*/}
                {editMode && <td className="border px-4 py-2 border border-gray-500 ">
                    <EditButton onClick={() => setEditingPlayer(p)} />
                </td>}
            </tr>)}
            </tbody>
        </table>
    </div>
}
