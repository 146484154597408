import {Modal} from "../core/Modal/Modal";
import {ErrorUserMessage} from "../core/UserMessage";
import {LoadingSpinner} from "../LoadingSpinner";
import React, {useCallback, useState} from "react";
import {findLink} from "../../util/HateoasUtil";
import axios from "axios";
import {InputTextareaField} from "../core/InputTextareaField";
import {Select} from "../core/Select/Select";

const DELIVERY_TYPES = [{
    value: "SILENT",
    display: "No email (message still visible in app)"
},{
    value: "NOTIFY_ALL",
    display: "Email all invited (except declined)"
},{
    value: "NOTIFY_CONFIRMED",
    display: "Email confirmed attendees"
},{
    value: "NOTIFY_UNCONFIRMED",
    display: "Email missing RSVP"
}]

export const CreateMessageModal = ({visible, horde, onSave, onClose}) => {

    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState("SILENT");

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const createMessage = useCallback(() => {

        if (message.trim().length === 0) {
            setError("Message cannot be blank");
            return;
        }

        setSaving(true);
        setError(null);
        axios.post(findLink(horde, "messages"), {type: messageType, message})
            .then(() => {
                setMessage("");
                setMessageType("SILENT")
                onSave();
            })
            .catch(() => setError("Error saving message"))
            .finally(() => setSaving(false));


    }, [horde, message, messageType, onSave]);


    return <Modal isOpen={visible} title={"Message " + horde.name}
           saveText="Send"
           onSave={createMessage}
           onClose={onClose}>
        <div>
            <InputTextareaField label="Content" onChange={setMessage} value={message} />

            <Select
                label="Send email as well?"
                editMode={true}
                emptyOption={false}
                value={messageType}
                data={DELIVERY_TYPES}
                keyValue={t => t.value}
                displayValue={t => t.display}
                onValueChanged={setMessageType}
            />

            <ErrorUserMessage visible={error} message={error} className="mt-2" />

            <LoadingSpinner visible={saving} title="Saving..." compact={true} className="mt-4" />
        </div>
    </Modal>
}
