


// export const findSelfLink = (resource) => {
//     return findLink(resource, "self");
// };
//
//
// export const findLink = (resource, name) => {
//     if (resource["_links"] !== undefined && resource["_links"][name] !== undefined) {
//         return resource["_links"][name].href;
//     } else {
//         return null;
//     }
// };


export const findSelfLink = (resource) => {
    return findLink(resource, "self");
};


export const findLink = (resource, name) => {


    if (!resource) {
        return null;
    }

    const link = resource.links?.[name]?.href;
    if (link) {
        return link;
    }

    const links = resource.links;
    if (links != null && Array.isArray(links)) {
        const link = links.find(link => link.rel === name);
        return link == null ? null : link.href;
    }

    if (resource["_links"] !== undefined && resource["_links"][name] !== undefined) {
        return resource["_links"][name].href;
    } else {
        return null;
    }
};
