import React, {Component, useEffect, useState} from 'react';

import firebase, {reloadFirebaseUser} from '../../Firebase';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {useSelector} from "react-redux";
import {selectIsAnonymousUser, selectLoggedInUser} from "../../store/slices/authentication";
import {PageContainer} from "../../components/layout/PageContainer/PageContainer";


class EmailVerificationPanel extends Component {
    render() {
        return <div className="email-verification-container">
            <div className="email-verification email-verification-message">
                An email has been sent to your email address.  Please check your email to verify your account.
            </div>
            <div className="email-verification email-verification-message">
                Once you've clicked the link in the email refresh this page to access the portal.
            </div>

            <div style={{marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', marginTop: '20px', marginBottom: '30px'}}>
                <div color="primary" onClick={() => window.location.reload()}>Refresh page</div>
            </div>

            <div style={{marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', marginTop: '20px', marginBottom: '20px'}}>
                <div color="default" onClick={() => {firebase.auth().currentUser.sendEmailVerification().then(()=>console.log("Confirmation email sent"));}}>
                    Resend email
                </div>
            </div>
        </div>;
    }
}

const LoginPanel = (props) => {
    const uiConfig = {
        signInFlow: 'redirect',
        autoUpgradeAnonymousUsers: true,
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                const user = authResult.user;
                const providerId = authResult.additionalUserInfo.providerId;
                const isNewUser = authResult.additionalUserInfo.isNewUser;
                if (providerId === "password" && isNewUser && !user.emailVerified) {
                    console.log("Sending confirmation email");
                    user.sendEmailVerification()
                        .then(() => {
                            console.log("Confirmation email sent");
                            reloadFirebaseUser();
                        });
                    return false;
                } else {
                    console.log("Not sending confirmation email for", user, authResult.additionalUserInfo);
                }
            },
            signInFailure: (error) => {

                console.log("====================");
                console.log("====================");

                const existingUser = firebase.auth().currentUser;

                console.log("existing user: ", existingUser);

                console.log("====================");
                console.log("====================");


                console.log("Sign in failure: ", error);

                if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
                    return Promise.resolve();
                }
                // The credential the user tried to sign in with.
                var cred = error.credential;



                return firebase.auth().signOut()
                    .then(async () => {

                        // if (existingUser) {
                        //     await existingUser.linkWithCredential(cred);
                        // }

                        console.log("Attempting to login with the passed in credentials");
                        return firebase.auth().signInWithCredential(cred);
                    });

                // return Promise.resolve();
            }
        }
    };

    return <div className="pt-32">

        <div style={{textAlign: 'center'}}>
            <p className="text-4xl mb-4">
                Login / Create Account
            </p>

            <p>
                Use the options below to login to or create your account.
            </p>

            <p>
                If this is the first time you have logged into the portal you may be asked to verify your email.
            </p>

        </div>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
    </div>
};

const LoginPage = () => {
    const [isCurrentUserUnverified, setIsCurrentUserUnverified] = useState(false);

    const user = useSelector(selectLoggedInUser);
    const isAnonymousUser = useSelector(selectIsAnonymousUser)

    useEffect(() => {

        console.log("User updated to: ", user);
        console.log("isAnonymousUser: ", isAnonymousUser);
        console.log("Boolean check: ", user && (!isAnonymousUser) && user.emailVerified);

        setIsCurrentUserUnverified(user && (!isAnonymousUser) && !user.emailVerified);

        if (user && (!isAnonymousUser) && user.emailVerified) {
            window.location.href = "/";
        }
    }, [user, isAnonymousUser]);

    console.log("Rendering login page");

    return <PageContainer>
        <div style={{
            width:"100%",
            display: "flex",
            justifyContent: "center"}}>
            { isCurrentUserUnverified ? <EmailVerificationPanel /> : <LoginPanel />}
        </div>
    </PageContainer>

}

export default LoginPage;
