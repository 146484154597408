import React from "react";
import {formatIsoDateTime} from "../../util/Clock";
import {Lozenge} from "../core/Lozenge/Lozenge";

const DeliveryLozenge = ({message}) => {
    switch (message.messageType) {
        case "NOTIFY_ALL":
            return <Lozenge color="orange" title="Email: All" compact={true} />;
        case "NOTIFY_CONFIRMED":
            return <Lozenge color="orange" title="Email: RSVP'd" compact={true} />;
        case "NOTIFY_UNCONFIRMED":
            return <Lozenge color="orange" title="Email: Pending" compact={true} />;
        default:
            return null;
    }
}

export const UserMessage = ({message}) => {

    return <div className="relative py-2 px-4 bg-white mt-2 mb-2 rounded shadow-lg">

        <div className="absolute top-0 right-0 mt-1 mr-2">
            <DeliveryLozenge message={message} />
        </div>
        <div className="text-xs font-light">
            <span className="font-medium">{formatIsoDateTime(message.createdTime)}</span>
            <span className="font-light ml-1 mr-1">from</span>
            <span className="font-medium mr-3">{message.createdBy}</span>
        </div>
        <div className="text-lg mt-2 font-light whitespace-pre-wrap">
            {message.message}
        </div>
    </div>

}
