import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {findLink, findSelfLink} from "../../util/HateoasUtil";
import {Modal} from "../core/Modal/Modal";
import {LoadingSpinner} from "../LoadingSpinner";
import {ErrorUserMessage} from "../core/UserMessage";
import {InputTextField} from "../core/InputTextField/InputTextField";
import {isBlank} from "../../util/GeneralUtil";

export const CustomRoleModal = ({visible, parent, editing = null, onUpdated, onClose}) => {

    const [name, setName] = useState("");
    const [count, setCount] = useState(1);

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const onSetName = (name) => {
        setName(name);
        setError(null);
    }

    const onSaveRole = useCallback(() => {

        if (isBlank(name)) {
            setError("Name cannot be blank");
            return;
        }

        const url = editing ? findSelfLink(editing) : findLink(parent, "customRoles");

        setError(null);
        setSaving(true);
        axios.post(url, {name, count})
            .then(() => {
                onUpdated();
                onClose();

                if (!editing) {
                    setName("");
                    setCount(1);
                }
            })
            .catch(() => setError("Error saving custom role"))
            .finally(() => setSaving(false));
    }, [count, editing, name, onClose, onUpdated, parent]);

    useEffect(() => {
        if (editing) {
            setName(editing.name);
            setCount(editing.count);
        } else {
            setName("");
            setCount(1);
        }
    }, [editing]);

    return <Modal
        isOpen={visible}
        title={editing ? "Update role" : "Create role"}
        saveText="Save"
        onSave={onSaveRole}
        onClose={onClose}>
        <div className="flex flex-col">

            <InputTextField label="Role / job name" onChange={onSetName} value={name} />
            <InputTextField className="mt-2" label="Number of people required" onChange={setCount} value={count} />

            <LoadingSpinner visible={saving} title="Saving..." compact={true} className="mt-2" />
            <ErrorUserMessage visible={error} message={error} className="mt-2" />
        </div>
    </Modal>
}
