import React from "react";
import {Lozenge} from "../../components/core/Lozenge/Lozenge";
import {EditButton} from "../../components/EditButton";

const MemberRole = ({role}) => {
    return <div>
        <Lozenge compact={true} color="bg-teal-700" title={role} />
    </div>
}

export const MemberRolesCell = ({roles, onEditClicked, editable}) => {
    return <div className="flex flex-row">
        <div className="flex-grow text-center mr-2">
            {roles.map(r => <MemberRole role={r} />)}
        </div>
        {editable && <div className="flex-grow-0">
            <div>
                <EditButton onClick={onEditClicked} />
            </div>
        </div>}
    </div>
}
