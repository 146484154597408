import React from "react";
import {FieldLabel} from "../FieldLabel/FieldLabel";

export const Select = ({editMode = true, label, data, value, keyValue, displayValue, onValueChanged, emptyOption = true}) => {

    const onChange = (e) => onValueChanged(e.target.value);

    return <div className="mb-6 md:mb-0 flex flex-row items-center">
        <div style={{width: "150px"}}>
            <FieldLabel label={label} />
        </div>
        <div className="relative">
            <select
                value={value}
                disabled={!editMode}
                onChange={onChange}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                {emptyOption && <option/>}
                {data.map(s => <option value={keyValue(s)} key={keyValue(s)}>{displayValue(s)}</option>)}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
            </div>
        </div>
    </div>

    // if (editMode) {
    //
    // } else {
    //     const matching = data?.filter(e => keyValue(e) === value) ?? [];
    //     const display = matching.length > 0 ? displayValue(matching[0]) : "";
    //
    //     return <ReadonlyField name={label} value={display} />
    // }

}
