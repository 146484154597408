import React, {useEffect, useState} from "react";
import {OptionButton} from "../../core/Button/OptionButton";
import {executeRequest} from "../../../util/RequestUtil";
import {findSelfLink} from "../../../util/HateoasUtil";
import axios from "axios";

export const LayoutSizeSetting = ({tournament}) => {

    const [value, setValue] = useState(null);

    const [, setSaving] = useState(false);
    const [, setError] = useState(null);

    useEffect(() => {
        setValue(tournament?.layoutSize);
    }, [tournament]);

    const onSizeSelected = (option) => {
        setValue(option);
        executeRequest(axios.post(findSelfLink(tournament) + "/settings/size", {value: option}), setSaving, setError, () => {});
    }

    return <div>
        <div className="mt-4 font-bold">Layout Size</div>
        <div className="mt-2 flex flex-row">
            <OptionButton option="LARGE" selectedOption={value} title="Large" onClick={onSizeSelected}/>
            <OptionButton option="MEDIUM" selectedOption={value}  title="Medium" className="ml-2" onClick={onSizeSelected}/>
            <OptionButton option="SMALL" selectedOption={value}  title="Small" className="ml-2" onClick={onSizeSelected}/>
        </div>
    </div>
}