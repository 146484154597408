import React from "react";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import {ErrorUserMessage} from "../../components/core/UserMessage";
import {Lozenge} from "../../components/core/Lozenge/Lozenge";
import {hasManagementRole} from "../../util/PermissionUtil";
import {EditButton} from "../../components/EditButton";

const ClubListRole = ({roles}) => {
    return hasManagementRole(roles) ? <Lozenge color="orange" title="Admin" compact={true} /> : null;
}

const ClubList = ({clubs, loading, error, history}) => {

    const manageClub = c => history.push("/clubs/edit/" + c.uuid);

    return <table className="table-auto bg-white mt-2">
        <thead>
        <tr>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2"># Members</th>
            <th className="px-4 py-2">Description</th>
            <th/>
        </tr>
        </thead>
        <tbody>
        {clubs.map(c => <tr onClick={() => manageClub(c)}>
            <td className="border px-4 py-2">{c.name} <ClubListRole roles={c.roles} /></td>
            <td className="border px-4 py-2">{c.numMembers}</td>
            <td className="border px-4 py-2">{c.description}</td>
            <td className="border px-4 py-2">
                <EditButton />
            </td>
        </tr>)}
        {(clubs == null || clubs.length === 0) && !loading && <div className="ml-4">No clubs found.</div>}
        {loading && <LoadingSpinner visible={loading} title="Loading clubs..." />}
        <ErrorUserMessage visible={error} message="Error loading clubs" />
        </tbody>
    </table>

}

export default ClubList;
