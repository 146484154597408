import React, {useEffect, useRef, useState} from "react";
import {findLink, findSelfLink} from "../../../../util/HateoasUtil";
import {formatResult, isBlank, isBye, tournamentSize, useEventListener} from "../../../../util/GeneralUtil";
import {axiosInstance, executeRequest, toOpenResource} from "../../../../util/RequestUtil";
import {LoadingSpinner} from "../../../../components/LoadingSpinner";
import {PlayerResultsModal} from "../../../../components/EditTournament/PlayerResultsModal/PlayerResultsModal";
import Button from "../../../../components/core/Button";
import axios from "axios";
import {PairingCreationManager} from "./PairingCreationManager";
import {RetryPairingButton} from "./RetryPairingButton";
import {Modal} from "../../../../components/core/Modal/Modal";
import {FullPointByeWarningModal} from "../../FullPointByeWarningModal";
import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {MoreResultOptionsModal} from "./MoreResultOptionsModal";
import {SwitchField} from "../../../../components/core/SwitchField";
import {selectPairingAllocatedByePoints} from "../../../../store/slices/editingTournament";


const RoundResultRow = ({tournament, idx, pairing, selected, onClick, viewMode, viewOnlyPage, onPlayerSelected, onRefreshRound, onResultSelected, onClose}) => {

    const [showFideModeWarning, setShowFideModeWarning] = useState(false);
    const [showMoreOptions, setShowMoreOptions] = useState(false);

    const [, setSaving] = useState(false);
    const [, setError] = useState(false);

    const editMode = true; //useSelector(selectEditingTournamentEditMode);
    // const requests = useSelector(selectEditingTournamentSavingResults);
    const layoutSize = tournament.layoutSize;

    const fideMode = tournament.fideMode == null ? true : tournament.fideMode;

    const pairingByePoints = selectPairingAllocatedByePoints(tournament);

    const basePlayerStyle = "p-2 " + tournamentSize(viewMode, layoutSize, "w-56 ", "w-56 ", "w-64 ")
    const playerStyle = selected ? basePlayerStyle + " bg-white" : basePlayerStyle + "bg-gray-300";
    const resultStyle = "w-20 text-center text-white p-2" + (selected ? " bg-gray-800" : " bg-gray-700");
    let containerStyle = selected ? " border-t border-l border-r border-gray-500 " : "";

    containerStyle += tournamentSize(viewOnlyPage, layoutSize, "text-xs", "text-sm", "text-base");

    const pairingRequest = "WHITE_WON"; //requests[findSelfLink(pairing)];

    const isByePairing = pairing.playerWithBye != null && pairing.playerWithBye !== "NONE";

    const onClickStart = (player) => {
        if (editMode && !viewMode) {
            onClick(null);
        } else if (!editMode && viewMode && player) {
            onPlayerSelected(player);
        }
    }

    const onDeletePairing = () => {

        setSaving(true);
        setError(false);
        axios.delete(findSelfLink(pairing))
            .then(() => {
                setShowMoreOptions(false);
                onRefreshRound();
            })
            .catch(() => setError(true))
            .finally(() => setSaving(false));
    }

    const onMoreOptions = () => {
        setShowMoreOptions(true);
    }

    const onExecuteWhiteSelected = () => {
        onResultSelected("WHITE");
    }

    const onWhiteSelected = () => {
        if (isByePairing && fideMode) {
            setShowFideModeWarning(true);
        } else {
            onExecuteWhiteSelected();
        }
    }

    return <>
        <tr className={containerStyle}>
            <td className="bg-gray-400 px-1 text-center"><div className="bg-gray-400">{idx + 1}</div></td>
            <td className={playerStyle} onClick={() => onClickStart(pairing.white)}>{pairing.whiteDescription} {pairing.white.rating != null ? "(" + pairing.white.rating + ")" : ""}</td>
            <td><div className="text-center px-3">{pairing.whitePoints}</div></td>
            <td className={resultStyle} onClick={() => onClickStart(null)}>{formatResult(pairingRequest?.result ?? pairing.result ?? "-", pairingByePoints)}</td>
            <td><div className="text-center px-3">{pairing.blackPoints}</div></td>
            <td className={playerStyle} onClick={() => onClickStart(pairing.black)}>
                {pairing.blackDescription} {!isBye(pairing.result) && pairing.black.rating != null ? "(" + pairing.black.rating + ")" : ""}</td>
        </tr>
        {selected && <tr className="border-b border-l border-r border-gray-500">
            <td colSpan={6} className="text-center py-1">

                <FullPointByeWarningModal
                    visible={showFideModeWarning}
                    onClose={() => setShowFideModeWarning(false)}
                    onSave={onExecuteWhiteSelected}/>

                <MoreResultOptionsModal
                    tournament={tournament}
                    onDelete={onDeletePairing}
                    visible={showMoreOptions}
                    onClose={() => setShowMoreOptions(false)}
                    onSaveResult={onResultSelected}
                    pairing={pairing} />

                <Button color="gray" compact={true} onClick={onWhiteSelected}>{isByePairing ? "Full point" : "White"}</Button>
                <Button className="ml-2" color="gray" compact={true} onClick={() => onResultSelected("DRAW")}>{isByePairing ? "Half point" : "Draw"}</Button>
                <Button className="ml-2" color="gray" compact={true} onClick={() => onResultSelected("BLACK")}>{isByePairing ? "Zero point" : "Black"}</Button>
                <Button className="ml-6" icon={faEllipsisH} color="gray" compact={true} onClick={onMoreOptions}>Other</Button>
                <Button className="ml-6" color="gray" compact={true} onClick={onClose}>Close</Button>


            </td>
        </tr>}
    </>
}

export const RoundResults = ({tournament, round, viewMode, onRefreshRound}) => {

    const pairingDataVersion = 0; // useSelector(selectPairingDataVersion);

    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const [selectedPairing, setSelectedPairing] = useState(-1);
    const [pairings, setPairings] = useState(null);
    const [unpaired, setUnpaired] = useState([]);
    const [canDeleteAll, setCanDeleteAll] = useState(false);
    const [earlierRoundsHaveUnpaired, setEarlierRoundsHaveUnpaired] = useState(false);
    const [unpairedFiltered, setUnpairedFiltered] = useState([]);

    const [includeManualByes, setIncludeManualByes] = useState(false);

    const [confirmDelete, setConfirmDelete] = useState(false);

    const [selectedPlayerId, setSelectedPlayerId] = useState(null);

    const [fetching, setFetching] = useState(false);
    const [, setSaving] = useState(false);
    const [error, setError] = useState(null);
    const [generateError, setGenerateError] = useState(null);
    const activePairingsUri = useRef(null);

    const retryPairing = () => {

        if (earlierRoundsHaveUnpaired) {
            return;
        }

        const uri = findLink(round, "generateMissing");

        executeRequest(axiosInstance(viewMode).get(uri), setFetching, setGenerateError,
            () => {
                onRefreshRound();
                getPairings();
            });
    }

    const getPairings = (clearPairings = false) => {
        if (clearPairings) {
            setPairings([]);
        }

        if (!round) {
            return;
        }

        const pairingsUri = findLink(round, "pairings");

        activePairingsUri.current = pairingsUri;

        executeRequest(axiosInstance(viewMode).get(toOpenResource(pairingsUri, viewMode)), setFetching, setError,
            data => {
                if (activePairingsUri.current === pairingsUri) {
                    setPairings(data.pairings);
                    setSelectedPairing(-1);
                    setUnpaired(data.unpairedPlayers);
                    setCanDeleteAll(data.isCanDeleteAllPairings);
                    setEarlierRoundsHaveUnpaired(data.isEarlierRoundsHaveUnpaired);
                }
            });
    }

    useEffect(() => {
        setUnpairedFiltered(unpaired
            .filter(p => !p.representsBye)
            .sort((a, b) => a.name.localeCompare(b.name)));
    }, [unpaired]);

    // useEffect(() => {
    //     dispatch(setPairingCreationRound(round));
    // }, [dispatch, round]);

    useEffect(() => {
        onRefreshRound();
        getPairings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pairingDataVersion]);

    useEffect(() => {
        getPairings(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [findSelfLink(round)]);

    const handleKeyPress = event => {

        if (!pairings) {
            return;
        }

        if (viewMode) {
            return;
        }

        if (selectedPairing < 0 || selectedPairing >= pairings?.length) {
            return;
        }

        let result = null;
        if (event.keyCode === 87) {
            result = "WHITE";
        } else if (event.keyCode === 66) {
            result = "BLACK";
        } else if (event.keyCode === 68) {
            result = "DRAW";
        }

        if (!result) {
            return;
        }

        onSaveResult(result);
    }

    const onSaveResult = (result) => {
        const pairing = pairings[selectedPairing];

        if (pairing.black.representsBye) {
            if (result === "WHITE") {
                result = "FULL_BYE"
            } else if (result === "BLACK") {
                result = "ZERO_BYE"
            } else if (result === "DRAW") {
                result = "HALF_BYE"
            }
        }


        executeRequest(axios.post(findLink(pairing, "result"), {result}), setSaving, setError, data => {
            // setShowModal(false);
            setPairings(pairings.map(pairing => pairing.uuid === data.uuid ? data : pairing))
        });

        //         const pairing = await query(axios.post(findLink(arg.pairing, "result"), {result: arg.result}));

        // dispatch(savePairingResult({pairing: pairing, result: result}));
        setSelectedPairing(selectedPairing + 1);
    }

    const onPairingSelected = idx => {
        if (idx === selectedPairing) {
            setSelectedPairing(-1);
        } else {
            setSelectedPairing(idx);
        }
    }


    const downloadPdf = () => {
        axios.get(findSelfLink(round) + "/pdf", {responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
    }

    const deletePairings = () => {
        axios.delete(findLink(round, "pairings") + "?includeManualByes=" + includeManualByes)
            .then((response) => {
                setIncludeManualByes(false);
                getPairings(true);
            });
    }

    useEventListener('keydown', handleKeyPress);

    const manualPairingActive = selectedPlayer;

    const onRefresh = () => {
        onRefreshRound();
        getPairings();
    }

    console.log("Errorx is: ", error);

    return <div>

        {!viewMode &&  <Button className="mt-2" compact={true} onClick={downloadPdf} color="gray">Download pairings PDF</Button>}
        {!viewMode && canDeleteAll && <Button className="ml-4 mt-2" compact={true} onClick={() => setConfirmDelete(true)} color="gray">Delete all pairings</Button>}

        {error != null && <div className="mt-2 text-red-700 font-light">There was an error fetching pairings</div>}

        {generateError != null && <div className="mt-2 text-red-700 font-light">There was an error generating pairings</div>}



        <div className="text-sm italic mt-2">
            {!(viewMode) && <span>To enter a result select a pairing and press W (= white won), B (= black won) or D (= draw).</span>}
        </div>


        <PlayerResultsModal
            view={viewMode}
            tournament={tournament}
            playerId={selectedPlayerId}
            onClose={() => setSelectedPlayerId(null)}
        />


        <Modal isOpen={confirmDelete} title="Confirm Delete"
               saveText="Delete"
               onSave={() => {
                   deletePairings();
                   setConfirmDelete(false);
               }}
               onClose={() => setConfirmDelete(false)}>
            <div>
                Are you sure you want to delete all pairings?
            </div>

            <SwitchField label="Also delete manually created byes?" editMode={true}
                         value={includeManualByes} onChange={setIncludeManualByes} className="mt-4" />
        </Modal>



        <div className="flex flex-row">
            <div>

                <PairingCreationManager
                    tournament={tournament}
                    round={round}
                    unpaired={unpairedFiltered}
                    pairingPlayer={selectedPlayer}
                    onCancel={() => setSelectedPlayer(null)}
                    onRefresh={onRefresh}/>

                {!manualPairingActive && pairings && pairings.length > 0 && <table className="mt-1">
                    <thead>
                        <tr className="text-center font-bold">
                            <td>Bd.</td>
                            <td>White</td>
                            <td>Pts</td>
                            <td>Res</td>
                            <td>Pts</td>
                            <td>Black</td>
                        </tr>
                    </thead>
                    <tbody>
                        {pairings?.map((p, idx) => <RoundResultRow
                                key={findSelfLink(p)}
                                tournament={tournament}
                                idx={idx}
                                viewOnlyPage={viewMode}
                                viewMode={viewMode}
                                onClick={() => onPairingSelected(idx)}
                                onPlayerSelected={player => setSelectedPlayerId(player.uuid)}
                                onRefreshRound={onRefresh}
                                pairing={p}
                                selected={idx === selectedPairing}
                                onResultSelected={onSaveResult}
                                onClose={() => setSelectedPairing(-1)}/>)}
                    </tbody>
                </table>}
                {!fetching && pairings && pairings.length === 0 && <div className="italic font-light text-sm ml-10">
                    No pairings created for round yet
                </div>}

                <LoadingSpinner visible={fetching} title="Loading pairings" />
            </div>
            {!viewMode && !manualPairingActive && unpairedFiltered.length > 0 && <div className="ml-2">
                <div className="font-bold text-sm">Unpaired players</div>
                {(tournament.type === "SWISS_SYSTEM" || tournament.type === "TEAM_VS_TEAM") && <>
                    <RetryPairingButton earlierRoundsHaveUnpaired={earlierRoundsHaveUnpaired} onClick={retryPairing} />
                    <div className="font-light italic text-sm mt-2">
                        In swiss system tournaments automated pairings are sometimes not possible,
                    </div>
                    <div className="font-light italic text-sm mb-2">
                        if there is an error try assigning some of the players a bye and retry automated pairing</div>
                </>}
                <div className="font-light italic text-sm mt-2">Click a player name to start manually pairing</div>
                {unpairedFiltered.map(p => <div
                    className="p-2 hover:bg-gray-400 rounded cursor-pointer"
                    onClick={() => setSelectedPlayer(p)}>
                    <span className="font-bold text-gray-900">{p.name}</span><span className="text-sm font-light text-gray-700"> ({p.rating})</span>
                    {!isBlank(p.team) && <div className="text-xs font-bold italic text-gray-700">
                        <span className="font-light">Team:</span> {p.team}</div>}
                </div>)}
            </div>}
        </div>
    </div>
}
