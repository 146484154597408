import React, {useEffect, useState} from "react";
import {InputTextField} from "../../core/InputTextField/InputTextField";
import {Select} from "../../core/Select/Select";
import {ClubSelector} from "../ClubSelector";
import {findLink, findSelfLink} from "../../../util/HateoasUtil";
import {isVegaTournament, tournamentStatusStartable} from "../../../util/EntityUtil";
import {RadioButton} from "../../core/RadioButton";
import {SwitchField} from "../../core/SwitchField";
import {TiebreakSelectionField} from "./TiebreakSelectionField";
import {FieldLabel} from "../../core/FieldLabel/FieldLabel";
import {RatingSourceSelectionField} from "./RatingSourceSelectionField";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {ErrorUserMessage} from "../../core/UserMessage";
import {executeRequest} from "../../../util/RequestUtil";
import axios from "axios";
import {Button} from "../../core/Button/Button";
import {LoadingSpinner} from "../../LoadingSpinner";
import {arraysEqual} from "../../../util/GeneralUtil";

const tournamentTypes = [{
    value: "SWISS_SYSTEM",
    display: "Swiss System"
},{
    value: "ROUND_ROBIN",
    display: "Round Robin"
},{
    value: "DOUBLE_ROUND_ROBIN",
    display: "Double Round Robin"
},{
    value: "TEAM_VS_TEAM",
    display: "Team Vs. Team"
}];


const sportTypes = [{
    value: "CHESS",
    display: "Chess"
},{
    value: "OTHER",
    display: "Other"
}];

// This is currently only used for Vega tournaments
const vegaRatingSourceValues = [{
    value: "INTERNATIONAL",
    display: "International",
    displayChess: "FIDE"
},{
    value: "COUNTRY",
    display: "Country",
    displayChess: "Federation"
}];


const timeControls = [{
    value: "CLASSICAL",
    display: "Standard"
},{
    value: "RAPID",
    display: "Rapid"
},{
    value: "BLITZ",
    display: "Blitz"
}];


const pairingAllocatedBye = [{
    value: "WIN",
    display: "Win"
},{
    value: "DRAW",
    display: "Draw"
},{
    value: "LOSS",
    display: "Loss"
}];

export const TournamentSummaryDetails = ({tournament, onUpdated, viewMode = false, refreshTournament}) => {

    const [tournamentName, setTournamentName] = useState("");
    const [tournamentType, setTournamentType] = useState("SWISS_SYSTEM");
    const [tournamentRounds, setTournamentRounds] = useState(5);
    const [tournamentTeamA, setTournamentTeamA] = useState("");
    const [tournamentTeamB, setTournamentTeamB] = useState("");
    const [sport, setSport] = useState("CHESS");
    const [fideMode, setFideMode] = useState(true);
    const [ratingSource, setRatingSource] = useState(null);
    const [ratingSources, setRatingSources] = useState(null);
    const [acceleratedPairings, setAcceleratedPairings] = useState(false);
    const [byeCountsAs, setByeCountsAs] = useState("WIN");
    const [firstWhite, setFirstWhite] = useState("A");
    const [city, setCity] = useState("");
    const [clubUri, setClubUri] = useState(null);
    const [federation, setFederation] = useState("");
    const [chiefArbiter, setChiefArbiter] = useState("");
    const [timeControlType, setTimeControlType] = useState("CLASSICAL");
    const [tiebreaks, setTiebreaks] = useState(null);

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const editMode = true;

    useEffect(() => {
        setTournamentName(tournament.name ?? "");
        setTournamentType(tournament.type ?? "SWISS_SYSTEM");
        setTournamentRounds(tournament.totalRounds ?? 5);
        setTournamentTeamA(tournament.teamA ?? "");
        setTournamentTeamB(tournament.teamB ?? "");
        setSport(tournament.sport ?? "CHESS");
        setFideMode(tournament.fideMode == null ? true : tournament.fideMode);
        setRatingSource(tournament.ratingSource ?? "INTERNATIONAL");
        setRatingSources(tournament.ratingSources);
        setAcceleratedPairings(tournament.accelerated == null ? false : tournament.accelerated);
        setByeCountsAs(tournament.byeCountsAs ?? "WIN");
        setFirstWhite(tournament.firstWhite ?? "A");
        setCity(tournament.city ?? "");
        setClubUri(findLink(tournament, "club"));
        setFederation(tournament.federation ?? "");
        setChiefArbiter(tournament.chiefArbiter ?? "");
        setTimeControlType(tournament.timeControl ?? "CLASSICAL");
        setTiebreaks(tournament.tiebreaks ?? []);
    }, [tournament]);

    const setClub = (club) => {
        setClubUri(club ? findSelfLink(club) : null);
    }

    const changesNeedSaving = () => {

        // console.log("need saving? ", tournamentRounds, )

        return tournamentName !== (tournament.name ?? "") ||
            tournamentType !== (tournament.type ?? "SWISS_SYSTEM") ||
            tournamentRounds !== (tournament.totalRounds ?? 5) ||
            tournamentTeamA !== (tournament.teamA ?? "") ||
            tournamentTeamB !== (tournament.teamB ?? "") ||
            sport !== (tournament.sport ?? "CHESS") ||
            fideMode !== (tournament.fideMode == null ? true : tournament.fideMode) ||
            ratingSource !== (tournament.ratingSource ?? "INTERNATIONAL") ||
            !arraysEqual(ratingSources, tournament.ratingSources) ||
            acceleratedPairings !== (tournament.accelerated == null ? false : tournament.accelerated) ||
            byeCountsAs !== (tournament.byeCountsAs ?? "WIN") ||
            firstWhite !== (tournament.firstWhite ?? "A") ||
            city !== (tournament.city ?? "") ||
            clubUri !== findLink(tournament, "club") ||
            federation !== (tournament.federation ?? "") ||
            chiefArbiter !== (tournament.chiefArbiter ?? "") ||
            timeControlType !== (tournament.timeControl ?? "CLASSICAL") ||
            !arraysEqual(tiebreaks, tournament.tiebreaks);
    }

    const onSave = () => {
        const data = {
            name: tournamentName ?? "My tournament",
            type: tournamentType ?? "SWISS_SYSTEM",
            numRounds: tournamentRounds,
            teamA: tournamentTeamA,
            teamB: tournamentTeamB,
            ratingSource: ratingSource,
            firstWhite: firstWhite,
            clubUri: clubUri,
            fideMode: fideMode,
            chiefArbiter: chiefArbiter,
            timeControls: timeControlType,
            tiebreaks: tiebreaks != null && tiebreaks.length > 0 ? tiebreaks.join(":") : null,
            ratingSources: ratingSources != null && ratingSources.length > 0 ? ratingSources.map(s => s).join(":") : null,
            byeCountsAs: byeCountsAs,
            city: city,
            federation: federation,
            accelerated: acceleratedPairings,
            sport: sport
        }

        executeRequest(axios.post(findSelfLink(tournament), data), setSaving, setError, data => {
            onUpdated(data);
        });
    }


    const isVega = isVegaTournament(tournament);
    const strictEditMode = editMode && tournamentStatusStartable(tournament);

    return <div className="bg-white shadow-lg p-4 inline-block w-full">

        <div className="mb-2 flex flex-row">
            <Button disabled={!changesNeedSaving()} compact={true} onClick={onSave} color="green">Save Changes</Button>
            <LoadingSpinner visible={saving} compact={true} title="Saving tournament" />
            <ErrorUserMessage className="ml-2" visible={error} message="Error saving tournament" />
        </div>

        <Card sx={{ minWidth: 275 }} variant="outlined">
            <CardContent>

                {!viewMode && <div className="mt-1">
                    <Select
                        label="Sport"
                        editMode={strictEditMode}
                        emptyOption={false}
                        value={sport}
                        data={sportTypes}
                        keyValue={t => t.value}
                        displayValue={t => t.display}
                        onValueChanged={setSport}
                    />
                </div>}

                {editMode && <InputTextField className="mt-1" label="Name" value={tournamentName} onChange={setTournamentName} editMode={editMode} />}
                {(editMode || clubUri != null) && <div className="mt-1 flex flex-row">
                    <ClubSelector editMode={editMode} selectedClubUri={clubUri} onClubSelected={setClub} tournament={tournament} />
                </div>}

                <div className="mt-1">
                    <Select
                        label="Type"
                        editMode={strictEditMode}
                        emptyOption={false}
                        value={tournamentType}
                        data={tournamentTypes}
                        keyValue={t => t.value}
                        displayValue={t => t.display}
                        onValueChanged={setTournamentType}
                    />

                    {tournamentType === "SWISS_SYSTEM" && <>
                        <div className="mt-1 flex flex-row">
                            <InputTextField label="#Rounds" value={tournamentRounds} onChange={setTournamentRounds} editMode={editMode && !viewMode} size={3} maxLength={3}  />
                        </div>
                        <ErrorUserMessage visible={false} message="Error changing number of rounds. Delete all pairings from rounds being removed." />
                    </>}
                </div>
            </CardContent>
        </Card>








        <Card sx={{ minWidth: 275 }} variant="outlined" className="mt-2">
            <CardContent>
                {tournamentType === "SWISS_SYSTEM" && <>
                    <div className="">
                        <TiebreakSelectionField tournament={tournament} editMode={editMode} onChange={setTiebreaks} />
                    </div>
                </>}

                {!viewMode && sport === "CHESS" && <div className="mt-2">
                    {!isVega && <RatingSourceSelectionField tournament={tournament} editMode={editMode} onChange={setRatingSources} />}
                    {isVega && <Select
                        label="Rating Source"
                        emptyOption={false}
                        value={ratingSource}
                        data={vegaRatingSourceValues}
                        keyValue={t => t.value}
                        displayValue={t => sport === "CHESS" ? t.displayChess : t.display}
                        onValueChanged={setRatingSource}
                    />}
                </div>}

                {tournamentType === "SWISS_SYSTEM" && (!viewMode) && <div className="mt-1">
                    <div>
                        <SwitchField
                            label="Accelerated pairings (Baku)"
                            editMode={!viewMode && tournament.generatedRounds === 0}
                            value={acceleratedPairings}
                            onChange={setAcceleratedPairings}
                             />
                    </div>
                </div>}

                {!viewMode && tournamentType === "SWISS_SYSTEM" && <div className="mt-4">
                    <div style={{maxWidth: "300px"}}>
                        <Select
                            label="Pairing allocated bye counts as"
                            emptyOption={false}
                            value={byeCountsAs}
                            data={pairingAllocatedBye}
                            keyValue={t => t.value}
                            displayValue={t => t.display}
                            onValueChanged={setByeCountsAs}
                        />
                    </div>
                </div>}

                {tournamentType === "TEAM_VS_TEAM" && <div className="mt-1">
                    <InputTextField label="Team A" value={tournamentTeamA} onChange={setTournamentTeamA} editMode={editMode} />
                </div>}

                {tournamentType === "TEAM_VS_TEAM" && <div className="mt-1">
                    <InputTextField label="Team B" value={tournamentTeamB} onChange={setTournamentTeamB} editMode={editMode} />
                </div>}

                {tournamentType === "TEAM_VS_TEAM" && editMode && <div className="mt-2 flex flex-row items-center">
                    <div style={{width: "150px"}}>
                        <FieldLabel label="Who gets first white" />
                    </div>
                    <div className="ml-2">
                        <RadioButton type="radio" value="A" selectedValue={firstWhite} onClick={setFirstWhite} group="firstWhite" description={tournamentTeamA ?? "A"}/>
                    </div>
                    <div className="ml-2">
                        <RadioButton type="radio" value="B" selectedValue={firstWhite} onClick={setFirstWhite} group="firstWhite" description={tournamentTeamB ?? "B"}/>
                    </div>
                </div>}
            </CardContent>
        </Card>


        {!viewMode && sport === "CHESS" && <>

            <Card sx={{ minWidth: 275 }} variant="outlined" className="mt-2">
                <CardContent>

                    <SwitchField
                        label="FIDE Mode"
                        editMode={editMode}
                        value={fideMode}
                        onChange={setFideMode} />


                    {!viewMode && fideMode && <div>


                        <div className="mt-1 flex flex-row items-center">
                            <InputTextField label="Federation" value={federation} onChange={setFederation} size={3} maxLength={3} />
                            <div className="ml-2 text-gray-700 italic text-sm">(3 letter country code)</div>
                        </div>

                        <InputTextField className="mt-1" label="City" value={city} onChange={setCity} />

                    </div>}

                    {fideMode && <div className="mt-1">
                        <InputTextField label="Chief Arbiter" value={chiefArbiter} onChange={setChiefArbiter} editMode={editMode && !viewMode} />
                    </div>}


                    {!viewMode && fideMode && sport === "CHESS" && <div className="mt-1">
                        <Select
                            label="Time Control"
                            emptyOption={false}
                            value={timeControlType}
                            data={timeControls}
                            keyValue={t => t.value}
                            displayValue={t => t.display}
                            onValueChanged={setTimeControlType}
                        />
                    </div>}
                </CardContent>
            </Card>
        </>}

    </div>
}
