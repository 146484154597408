import React, {useEffect, useState} from "react";
import {FieldLabel} from "../../core/FieldLabel/FieldLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faPen} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "../../core/Modal/Modal";
import {Button} from "../../core/Button/Button";


export const RATING_SOURCES = {

    // older, deprecated, values
    INTERNATIONAL: {
        name: "FIDE standard",
        intl: true
    },
    COUNTRY: {
        name: "Tournament federation standard",
        intl: true
    },

    FIDE_STD: {
        name: "FIDE standard",
        intl: true
    },
    FIDE_RAPID: {
        name: "FIDE rapid",
        intl: true
    },
    FIDE_BLITZ: {
        name: "FIDE blitz",
        intl: true
    },
    TOURNAMENT_FED_STD: {
        name: "Tournament federation standard",
        intl: true
    },
    TOURNAMENT_FED_RAPID: {
        name: "Tournament federation rapid",
        intl: true
    },
    TOURNAMENT_FED_BLITZ: {
        name: "Tournament federation blitz",
        intl: true
    },
};

const SelectedRatingSource = ({name, onUp, onDown}) => {
    return <div className="p-2 m-1 font-bold">
        <Button disabled={onUp == null} color="blue" compact={true} onClick={() => {
            if (onUp) {
                onUp(name)
            }}
        } icon={faArrowUp}/>
        <Button disabled={onDown == null} className="ml-1" color="blue" compact={true} onClick={() => {
            if (onDown) {
                onDown(name)
            }
        }} icon={faArrowDown}/>
        <span className="ml-2">{RATING_SOURCES[name].name}</span>
    </div>
}

const UnselectedRatingSource = ({name, onAdd}) => {
    return <div className="p-2 m-1 font-bold">
        <Button color="blue" compact={true} onClick={() => onAdd(name)}>Add</Button>
        <span className="ml-2">{RATING_SOURCES[name].name}</span>
    </div>
}


export const RatingSourceSelectionField = ({tournament, editMode, onChange}) => {

    const [localSources, setLocalSources] = useState([]);
    const [showEditor, setShowEditor] = useState(false);

    useEffect(() => {
        setLocalSources(tournament.ratingSources ?? []);
    }, [tournament]);

    const updateRatingSources = () => {
        onChange(localSources);
        setShowEditor(false);
    }

    const onAdd = (t) => {
        setLocalSources([...localSources, t]);
    }

    const available = Object.keys(RATING_SOURCES)
        .filter(t => t !== "INTERNATIONAL")
        .filter(t => t !== "COUNTRY")
        .filter(t => localSources == null || !localSources.includes(t));

    const onUp = (t) => {
        const idx = localSources.indexOf(t);
        if (idx > 0) {
            const copy = [...localSources];
            const element = copy.splice(idx, 1)[0];
            copy.splice(idx - 1, 0, element);
            setLocalSources(copy);
        }
    }

    const onDown = (t) => {

        const idx = localSources.indexOf(t);

        if (idx + 1 < localSources.length) {

            const copy = [...localSources];

            const element = copy.splice(idx, 1)[0];

            copy.splice(idx + 1, 0, element);

            setLocalSources(copy);

        }
    }

    return <div className="flex flex-row items-center">
        <div className="flex flex-row items-center" onClick={() => setShowEditor(true)} style={{width: "150px"}}>
            <FieldLabel label="Rating Source" />
            {editMode && <div className="ml-2">
                <FontAwesomeIcon icon={faPen} />
            </div>}
        </div>
        {localSources && localSources.length > 0 && <ol style={{paddingInlineStart: "20px", marginBlockStart: "0px", marginBlockEnd: "0px"}}>
            {localSources && localSources.map(t => <li className=" text-sm text-gray-900 ml-4 list-decimal">{RATING_SOURCES[t].name}</li>)}
        </ol>}
        {(localSources == null || localSources.length === 0) && <div className="ml-2 text-sm text-gray-700 italic">
            Rating sources not selected
        </div>}

        <Modal isOpen={editMode && showEditor} title="Edit rating sources"
               closeText="Done"
               onClose={updateRatingSources}>
            <div>
                <div>
                    <span className="font-bold text-lg">Current rating sources</span>
                    {localSources.length > 0 && <span
                        className="font-light text- text-blue-600 underline italic ml-4 cursor-pointer"
                        onClick={() => setLocalSources([])}>clear</span>}
                </div>
                {localSources && localSources.map((t, idx) => <SelectedRatingSource
                    key={t}
                    name={t}
                    onUp={idx > 0 ? onUp : null}
                    onDown={idx + 1 < localSources.length ? onDown : null} />)}
                {(localSources == null || localSources.length === 0) && <div className="ml-2">No rating sources selected</div>}

                <div className="font-bold text-lg mt-2">Available rating sources</div>
                {available
                    .map(t => <UnselectedRatingSource name={t} onAdd={onAdd} />)}
                {available.length === 0 && <div className="ml-2">All rating sources added</div>}
            </div>
        </Modal>

    </div>


}
