import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import React, {useState} from "react";
import {FieldLabel} from "./FieldLabel/FieldLabel";
import {ReadonlyField} from "./ReadonlyField";
import {formatIsoDateTime} from "../../util/Clock";

const bonusStyles = "shadow appearance-none border rounded p-1"

export const DateTimeField = ({editMode = true, label, className, onChange, value}) => {

    const [additionalStyles, setAdditionalStyles] = useState(bonusStyles);

    const onTimeOpen = () => {
        setAdditionalStyles("");
    }

    const onTimeClose = () => {
        setAdditionalStyles(bonusStyles);
    }

    const onDateChanged = (value) => {
        onChange(value);
    }

    if (editMode) {
        return <div className={"" + className}>
            <FieldLabel label={label} />
            <Datetime
                className={additionalStyles}
                dateFormat={"D/M/YYYY"}
                onOpen={onTimeOpen}
                onClose={onTimeClose}
                value={value}
                input={true}
                onChange={onDateChanged} />
        </div>
    } else {

        return <div className={"" + className}><ReadonlyField name={label} value={formatIsoDateTime(value)} /></div>
    }

}
