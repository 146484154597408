import React from "react";
import PageContainer from "../../components/layout/PageContainer";

export const TournamentSearchPage = (props) => {

    return <PageContainer>
        <div>
            Find tournament
        </div>
    </PageContainer>
}

export default TournamentSearchPage;
