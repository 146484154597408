import React from "react";
import {CircleLoader} from "react-spinners";


export const LoadingSpinner = ({size = 50, title, compact = false, visible = true, className = ""}) => {

    if (visible) {
        if (compact) {
            return <span className={"flex " + className}>
                <div className="ml-12">
                    <CircleLoader
                        size={size}
                        color={"#4a148c"} />
                </div>
                {title && <div className="ml-16">
                    <p className="mt-2 text-lg italic">{title}...</p>
                </div>}
            </span>;
        } else {
            return <div className={"flex " + className}>
                <div className="m-auto">
                    <div className="ml-12">
                        <CircleLoader
                            size={50}
                            color={"#4a148c"} />
                    </div>
                    <p className="mt-8 text-lg">{title}</p>
                </div>
            </div>;
        }
    }

    return null;
}
