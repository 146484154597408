import React from "react";
import {UserMessage} from "./UserMessage";

export const DefaultUserMessage = (props) => {
    return <UserMessage
        {...props}
        showNext={false}
        backgroundClass="bg-indigo-800 text-indigo-100"
        lozengeClass="bg-indigo-500"
        />
}

export const SuccessUserMessage = (props) => {
    return <UserMessage
        {...props}
        showNext={false}
        backgroundClass="bg-green-600 text-green-100"
        lozengeClass="bg-green-600"
    />
}

export const ErrorUserMessage = (props) => {
    return <UserMessage
        {...props}
        lozengeMessage="Error"
        showNext={false}
        backgroundClass={"bg-red-800 text-red-100 " + props.className}
        lozengeClass="bg-red-500"
    />
}


export const WarningUserMessage = (props) => {
    return <UserMessage
        {...props}
        lozengeMessage="Warning"
        showNext={false}
        backgroundClass="bg-orange-800 text-white"
        lozengeClass="bg-orange-500"
    />
}
