import React, {useCallback, useEffect, useState} from "react";
import {Button} from "../../../components/core/Button/Button";
import {RefreshButton} from "../../../components/RefreshButton";
import {ManageClubTeamsModal} from "./ManageClubTeamsModal";
import axios from "axios";
import {findLink} from "../../../util/HateoasUtil";
import {EditButton} from "../../../components/EditButton";
import {EditClubTeam} from "./EditClubTeam";
import {ErrorUserMessage} from "../../../components/core/UserMessage";


export const ManageClubTeams = ({club}) => {

    const [teams, setTeams] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);

    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);

    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);

    const onShowCreateTeamModal = () => {
        setShowCreateTeamModal(true);
    }

    const onCloseCreateTeamModal = () => {
        setShowCreateTeamModal(false);
    }

    const fetchTeams = useCallback(() => {
        setError(null);
        setFetching(true);
        axios.get(findLink(club, "teams"))
            .then(res => setTeams(res.data.page))
            .catch(() => setError("Error fetching teams"))
            .finally(() => setFetching(false));
    }, [club]);

    const onTeamCreated = () => {
        fetchTeams();
        onCloseCreateTeamModal();
    }

    const onEditTeam = (t) => {
        setSelectedTeam(t);
    }

    const onEditDone = () => {
        setSelectedTeam(null);
        fetchTeams();
    }

    useEffect(() => {
        fetchTeams();
    }, [fetchTeams]);

    return <div className="p-4 w-full bg-white mb-4">

        {selectedTeam == null && <>
            <div className="font-light">
               You can create (sub) teams here to be included in tournaments / competitions you want to run.
            </div>

            <div className="mt-2 flex flex-row">
                <RefreshButton onClick={fetchTeams} />
                <Button className="ml-4" onClick={onShowCreateTeamModal}>Create Team</Button>
            </div>

            <table className="table-auto bg-white mt-2">
                <thead>
                <tr>
                    <th className="px-4 py-2">Name</th>
                    <th className="px-4 py-2"># Members</th>
                    <th className="px-4 py-2"/>
                </tr>
                </thead>
                <tbody>
                {teams?.map(t => <tr key={t.uuid}>
                    <td className="border px-4 py-2 text-center">
                        {t.name}
                    </td>
                    <td className="border px-4 py-2 text-center">
                        {t.numMembers}
                    </td>
                    <td className="border px-4 py-2 text-center">
                        <EditButton onClick={() => onEditTeam(t)} />
                    </td>
                </tr>)}
                </tbody>
            </table>

            {!fetching && teams.length === 0 && <div className="ml-4">No teams added yet</div>}

            <ManageClubTeamsModal
                club={club}
                visible={showCreateTeamModal}
                onCreated={onTeamCreated}
                onClose={onCloseCreateTeamModal} />
        </>}

        <ErrorUserMessage visible={error} message={error} />

        <EditClubTeam club={club} team={selectedTeam} onEditDone={onEditDone} />

    </div>
}
