import moment from "moment-timezone";

export function nowIsoString() {
    return moment().toISOString();
}

export function serializeDate(date) {
    return date ? moment(date).toISOString() : null;
}

export function nowMoment() {
    return moment();
}

export function formatIsoDate(iso) {
    if (!iso) {
        return "";
    }
    return moment(iso).format("D/M/YYYY")
}

export function formatIsoDateTime(iso) {
    if (!iso) {
        return "";
    }
    return moment(iso).format("D/M/YYYY h:mma")
}

export function secondsSince(iso) {
    const now = nowMoment();
    const other = moment(iso);

    return Math.abs(now.diff(other, 'seconds'));
}

export function jsDateStartOfDay(date) {
    const result = new Date(date.getFullYear()
        ,date.getMonth()
        ,date.getDate()
        ,0,0,0);

    return result;
}

export function jsDateEndOfDay(date) {
    return new Date(date.getFullYear()
        ,date.getMonth()
        ,date.getDate()
        ,23,59,59);
}
