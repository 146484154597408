import React from 'react';
import Routes from "./navigation/Routes";
import Menu from "./navigation/Menu";
import {BrowserRouter} from "react-router-dom";
import Header from "./components/layout/Header";
import TournamentHomePage from "./pages/TournamentHomePage";
import LoginPage from "./pages/LoginPage";
import TournamentEditPage from "./pages/TournamentEditPage";
import TournamentSearchPage from "./pages/TournamentSearchPage";
import ClubsHomePage from "./pages/ClubsHomePage";
import ManageClubPage from "./pages/ManageClubPage/ManageClubPage";
import HomePage from "./pages/HomePage";
import {UsernamePromptModal} from "./components/UsernamePromptModal/UsernamePromptModal";
import {GameAnalysisPage} from "./pages/GameAnalysisPage/GameAnalysisPage";
import {InvitationsPage} from "./pages/InvitationsPage/InvitationsPage";
import {AdhocEventDetailPage} from "./pages/AdhocEventDetailPage/AdhocEventDetailPage";
import {useSelector} from "react-redux";
import {selectLoggedInUser} from "./store/slices/authentication";
import {LoggedInMenuOptions} from "./navigation/Menu/LoggedInMenuOptions";

import logo from "./logo.png";
import MessagesPage from "./pages/MessagesPage/MessagesPage";
import {SchedulePage} from "./pages/SchedulePage/SchedulePage";
import {PrivacyPolicyPage} from "./pages/PrivacyPolicyPage";
import {selectIsStaticContent} from "./store/reducers/userContext";
import {ToSPage} from "./pages/ToSPage";
import {TeamsLandingPage} from "./pages/LandingPage/Teams";
import {TournamentsLandingPage} from "./pages/LandingPage/Tournaments";
import TournamentPublicViewPage from "./pages/TournamentPublicViewPage/TournamentPublicViewPage";
import {AdminPage} from "./pages/AdminPage/AdminPage";
import {FidePage} from "./pages/FidePage/FidePage";
import {PageEditor} from "./pages/PageEditor/PageEditor";

const packageJson = require("../package.json");

export const PAGE = {
    PRIVACY: {
        menuLocation: "PRIVACY",
        path: "/privacy",
        component: PrivacyPolicyPage
    },
    FIDE: {
        menuLocation: "FIDE",
        path: "/fide",
        component: FidePage
    },
    TOS: {
        menuLocation: "TOS",
        path: "/tos",
        component: ToSPage
    },
    LOGIN: {
        menuLocation: "LOGIN",
        path: "/login",
        component: LoginPage
    },
    HOME: {
        menuLocation: "ADHOC_EVENTS",
        path: "/",
        component: HomePage
    },
    ADMIN: {
        menuLocation: "ADMIN",
        path: "/yarrichar/admin",
        component: AdminPage
    },

    LANDING_TEAMS: {
        menuLocation: "LANDING_TEAMS",
        path: "/welcome/teams",
        component: TeamsLandingPage
    },
    LANDING_TOURNAMENTS: {
        menuLocation: "LANDING_TOURNAMENTS",
        path: "/welcome/tournaments",
        component: TournamentsLandingPage
    },

    USER_PROFILE: {
        menuLocation: "USER_PROFILE",
        path: "/profile",
        component: null //UserProfilePage
    },
    CREATE_TOURNAMENT: {
        menuLocation: "LIST_TOURNAMENT",
        path: "/tournaments",
        component:  TournamentHomePage
    },
    MESSAGES: {
        menuLocation: "MESSAGES",
        path: "/messages",
        component:  MessagesPage
    },
    INVITATIONS: {
        menuLocation: "INVITATIONS",
        path: "/invitations",
        component:  InvitationsPage
    },
    EDIT_TOURNAMENT: {
        menuLocation: "LIST_TOURNAMENT",
        path: "/tournaments/edit/",
        component: TournamentEditPage
    },
    FIND_TOURNAMENT: {
        menuLocation: "FIND_TOURNAMENT",
        path: "/search",
        component: TournamentSearchPage
    },
    CLUBS_HOME: {
        menuLocation: "CLUB_HOME",
        path: "/clubs",
        component: ClubsHomePage
    },
    EDIT_CLUB: {
        menuLocation: "CLUB_HOME",
        path: "/clubs/edit/",
        component: ManageClubPage
    },
    ADHOC_EVENTS: {
        menuLocation: "ADHOC_EVENTS",
        path: "/events",
        component: SchedulePage
    },
    ADHOC_EVENTS_EDIT: {
        menuLocation: "ADHOC_EVENTS",
        path: "/events/edit/",
        component: AdhocEventDetailPage
    },

    TOURNAMENT_VIEW: {
        menuLocation: "TOURNAMENT_VIEW",
        path: "/view/",
        component: TournamentPublicViewPage
    },

    PAGE_EDITOR: {
        menuLocation: "PAGE_EDITOR",
        path: "/pageEditor",
        component: PageEditor
    },

    GAME_ANALYSIS: {
        menuLocation: "GAME_ANALYSIS",
        path: "/gameAnalysis",
        component: GameAnalysisPage
    },
}

function App() {



    const loggedInUser = useSelector(selectLoggedInUser);
    const isStaticContent = useSelector(selectIsStaticContent);

    const isViewOnlyPage = window.location.pathname.startsWith("/view/");

    console.log("Subdomain: " + window.location.host);

    let subdomain = null
    let host = window.location.host;
    let idx = host.indexOf(".movekeep");
    if (idx > 0) { // deliberate > rather than >= since we're looking for stuff before the .movekeep
        subdomain = host.substring(0, idx);
    }

    console.log("Subdomain is: ", subdomain);

    console.log("Path: ", window.location.pathname);

    // if (subdomain) {
    //     return <html><body><div>{subdomain}</div></body></html>
    // }

    if (window.location.pathname && window.location.pathname.startsWith("/site/")) {
        return <div>
            Hello
        </div>
    }


    return <BrowserRouter>
        <Header />

        {!isStaticContent && loggedInUser && <div className="flex flex-col">

            {!isViewOnlyPage && <div className="flex-grow-0 flex flex-row shadow-2xl relative bg-gray-900 items-center ">
                <div className="text-white ">

                    <div className="py-2 md:p-2 md:pr-4 gradient flex flex-row">
                        <div className="hidden md:contents">
                            <img alt="Logo" src={logo} width={40} height={100} className="m-auto" />
                        </div>
                        <div className="ml-2">
                            <div className="justify-center text-sm md:text-xl text-white text-center font-light">
                                Move Keep
                            </div>
                            <div className="text-center text-xs text-gray-500 font-light">
                                v{packageJson.version}
                            </div>
                        </div>
                    </div>
                </div>

                <LoggedInMenuOptions visible={true} />
            </div>}
            <div className="w-0 bg-gray-500"></div>
            <div className="flex-1">
                <Routes />
            </div>

        </div>}


        {(isStaticContent || !loggedInUser) && <div className="leading-normal tracking-normal text-white">
            <UsernamePromptModal />
            {!isViewOnlyPage && <Menu />}
            <Routes />
        </div>}
    </BrowserRouter>
}

export default App;
