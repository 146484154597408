import React from "react";
import PageContainer from "../../components/layout/PageContainer";
import TournamentList from "../../components/TournamentList";

export const CreateTournamentPage = (props) => {



    return <PageContainer>
        <TournamentList title="My tournaments" {...props} />
    </PageContainer>
}

export default CreateTournamentPage;
