import React from "react";
import {useSelector} from "react-redux";
import {selectIsAnonymousUser} from "../../store/slices/authentication";
import TournamentHomePage from "../TournamentHomePage";
import {TournamentsLandingPage} from "../LandingPage/Tournaments";

export const HomePage = (props) => {

    const anonymousUser = useSelector(selectIsAnonymousUser);

    return anonymousUser ? <TournamentsLandingPage {...props} /> : <TournamentHomePage {...props} />
}

export default HomePage;
