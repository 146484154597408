import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Editable, Slate, withReact} from "slate-react";
import {createEditor, Transforms} from "slate";
import {Element, Leaf} from "../../components/slate/SlatePrimitives";
import {withHistory} from "slate-history";
import Container from "@mui/material/Container";

export const ClubWebsitePage = ({page}) => {

    const [content, setContent] = useState(null);

    const editor = useMemo(() => withHistory(withReact(createEditor())), [])

    const renderElement = useCallback(props => <Element {...props} />, []);
    const renderLeaf = useCallback(props => <Leaf {...props} />, []);

    const displayedValidData = useRef(false);

    useEffect(() => {
        if (page) {
            setContent(JSON.parse(page.sections[0].columns[0].content));
        }
    }, [page]);

    useEffect(() => {
        if (editor && content) {
            if (displayedValidData.current) {
                Transforms.select(editor, [0, 0]);
                editor.children = content;
                editor.onChange();
            } else {
                displayedValidData.current = true;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);


    return content ? <Container maxWidth="l" >
        <Slate editor={editor} value={content}>
            <Editable
                renderElement={renderElement}
                renderLeaf={renderLeaf}
                readOnly placeholder="Enter some plain text..." />
        </Slate>
    </Container>: null;
}
