
export const SET_SERVER_URI = "SET_SERVER_URI";

const initialState = {
    serverUri: null,
};

export const config = (state = initialState, action) => {
    switch (action.type) {
        case SET_SERVER_URI:

            return  {
                ...state,
                serverUri: action.serverUri
            };
        default:
            return state;
    }
};

export const selectServerUri = (state) => state.config.serverUri;

