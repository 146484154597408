import {SET_SERVER_URI} from "../reducers/config";


export const fetchServerUri = () => {
    return async (dispatch, getState) => {

        const hostname = window.location.hostname;

        let serverUri;
        if (hostname.indexOf(".stage.") >= 0) {
            serverUri = "https://move-keep.stage.movekeep.com";
        } else if (hostname.indexOf("localhost") >= 0) {
            serverUri = "http://localhost:8080";
        } else {
            serverUri = "https://api.movekeep.com";
        }

        console.log("Setting server URI to: ", serverUri);

        dispatch({type: SET_SERVER_URI, serverUri: serverUri});
    }
};
