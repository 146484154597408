import React from "react";
import {FieldLabel} from "./FieldLabel/FieldLabel";
import Switch from "@material-ui/core/Switch";

export const SwitchField = ({editMode = true, value, className, label, onChange, ...props}) => {

    const onSwitchChanged = event => onChange(event.target.checked);

    return <div className={"flex flex-row items-center " + className}>
        <div style={{width: "150px"}}>
            <FieldLabel label={label} mb={false}/>
        </div>
        <Switch
            disabled={!editMode}
            color="primary"
            {...props}
            checked={value}
            onChange={onSwitchChanged}
        />
    </div>

    // if (editMode) {
    //
    // } else {
    //     return <div className={className}><ReadonlyField name={label} value={value ? "Yes" : "No"} /></div>
    // }
}
