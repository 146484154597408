import {combineReducers} from 'redux'
import {userContext} from "./userContext";
import {config} from "./config";
import authentication from "../slices/authentication";
import usernamePromptModal from "../../components/UsernamePromptModal/usernamePromptModalSlice";
import gameAnalysisPage from "../../pages/GameAnalysisPage/GameAnalysisPageSlice";
import invitationsPage from "../../pages/InvitationsPage/InvitationsPageSlice";
import theme from "../slices/theme";

export default combineReducers({
    userContext: userContext,
    config: config,
    authentication: authentication,
    usernamePromptModal: usernamePromptModal,
    gameAnalysisPage: gameAnalysisPage,
    invitationsPage: invitationsPage,
    theme: theme,
});
