import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectServerUri} from "../../store/reducers/config";
import {addPaginationParams} from "../../util/RequestUtil";
import * as axios from "axios";
import {findLink, findSelfLink} from "../../util/HateoasUtil";
import {Modal} from "../core/Modal/Modal";
import {Button} from "../core/Button/Button";
import {ErrorUserMessage} from "../core/UserMessage";
import {LoadingSpinner} from "../LoadingSpinner";
import {AdhocEventCreateButton} from "../AdhocEventCreateButton/AdhocEventCreateButton";
import {formatIsoDateTime} from "../../util/Clock";
import {EditButton} from "../EditButton";
import {DeleteButton} from "../DeleteButton";


export const AdhocEventList = ({readonly = true, title, history, runByExpected = true, runBy}) => {

    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [events, setEvents] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [deletingEvent, setDeletingEvent] = useState(null);

    const serverUri = useSelector(selectServerUri);

    const showEvent = (event) => {
        history.push("/events/edit/" + event.uuid);
    }

    const fetchEvents = useCallback(() => {

        if (runByExpected && !runBy) {
            return;
        }

        const uri = runBy != null ? findLink(runBy, "events") : serverUri + "/api/adhocEvents"

        setLoading(true);
        setErrorLoading(false);
        axios.get(addPaginationParams(uri, page))
            .then(res => {
                setTotalPages(res.data.totalPages)
                setEvents(res.data.page)
            })
            .catch(err => {
                setErrorLoading(true);
                console.log("Error fetching events: " + err)
            })
            .finally(() => setLoading(false));
    }, [page, runBy, runByExpected, serverUri]);

    const startConfirmDelete = event => setDeletingEvent(event);

    const cancelDelete = () => setDeletingEvent(null)

    const deleteEvent = useCallback(()=> {

        axios.delete(findSelfLink(deletingEvent))
            .then(() => {
                setDeletingEvent(null);
                fetchEvents();
            })
    }, [fetchEvents, deletingEvent]);

    const canGoNext = () => page + 1 < totalPages;
    const canGoPrev = () => page > 0;

    const nextPage = () => {
        if (canGoNext()) {
            setPage(page + 1);
        }
    }

    const prevPage = () => {
        if (canGoPrev()) {
            setPage(page - 1);
        }
    }

    const onEventCreated = (event) => {
        fetchEvents();
        showEvent(event);
    }

    useEffect(() => {
        fetchEvents();
    }, [page, fetchEvents, runBy]);

    return <div>

        {!readonly && <AdhocEventCreateButton runBy={runBy} onCreated={onEventCreated} />}

        {title && <div className="">
            <p className="text-3xl">{title}</p>
        </div>}

        <Modal isOpen={deletingEvent != null} title="Confirm Delete"
               saveText="Delete"
               onSave={deleteEvent}
               onClose={cancelDelete}>
            <div>
                Are you sure you want to delete event:
                <br/>
                <b>{deletingEvent?.name}</b>
            </div>
        </Modal>

        <div className="mt-2 ">
            <Button disabled={!canGoPrev()} color="gray" onClick={prevPage}>Prev Page</Button>
            <Button disabled={!canGoNext()} className="ml-2" color="gray" onClick={nextPage}>Next Page</Button>
        </div>

        <table className="bg-white mt-2">
            <thead>
            <tr>
                <th className="px-4 py-2">Scheduled time</th>
                <th className="px-4 py-2">Name</th>
                <th className="px-4 py-2">Location</th>
                <th className="px-4 py-2"># Invited</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {!loading && !errorLoading && (!events || events.length === 0) && <div className="ml-4 pb-2">No events found.</div>}
            {events?.map(t => <tr key={t.uuid}>
                <td className="border px-4 py-2">{formatIsoDateTime(t.scheduledTime)}</td>
                <td className="border px-4 py-2" onClick={() => showEvent(t)}>{t.name}</td>
                <td className="border px-4 py-2">{t.locationDescription}</td>
                <td className="border px-4 py-2">{t.numberInvited}</td>
                <td className="border px-4 py-2">
                    <EditButton onClick={() => showEvent(t)} readonly={readonly}/>
                    {!readonly && <DeleteButton className="ml-2" onClick={() => startConfirmDelete(t)}>Delete</DeleteButton>}

                    {/*<Button className="ml-2" color="red" onClick={() => startConfirmDelete(t)}>Delete</Button>*/}
                </td>
            </tr>)}
            </tbody>
        </table>

        <div className="mt-2 ">
            <ErrorUserMessage visible={errorLoading} message="Loading results"/>
        </div>

        <LoadingSpinner visible={loading} compact={true} title="Loading events" />
    </div>
}
