import {useEffect, useRef} from "react";

export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isBlank(string) {
    return string == null || string.trim().length === 0;
}

export function arraysEqual(arr1, arr2) {
    arr1 = arr1 == null ? [] : arr1;
    arr2 = arr2 == null ? [] : arr2;

    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }

    return true;
}

export function useEventListener(eventName, handler, element = window){
    // Create a ref that stores handler
    const savedHandler = useRef();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            // Create event listener that calls handler function stored in ref
            const eventListener = event => savedHandler.current(event);

            // Add event listener
            element.addEventListener(eventName, eventListener);

            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element] // Re-run if eventName or element changes
    );
}

export const tournamentSize = (viewMode, layoutSize, small, medium, large) => {
    if (viewMode) {
        return layoutSize === "SMALL" ? small : layoutSize === "MEDIUM" ? medium : large;
    } else {
        return large;
    }
}


export const formatResult = (result, pairingByePoints = 1) => {
    switch (result) {
        case "WHITE": return "1 - 0";
        case "BLACK": return "0 - 1";
        case "DRAW": return "\u00BD - \u00BD";
        case "WHITE_TOO_SHORT": return "1 - 0";
        case "BLACK_TOO_SHORT": return "0 - 1";
        case "DRAW_TOO_SHORT": return "\u00BD - \u00BD";
        case "FULL_BYE": return "1";
        case "PAIRING_ALLOCATED_BYE":
            return pairingByePoints === 0.5 ? "\u00BD" : "" + pairingByePoints;
        case "HALF_BYE": return "\u00BD";
        case "ZERO_BYE": return "0";
        case "WHITE_FORFEIT_WIN": return "1F - 0F";
        case "WHITE_FORFEIT_LOSS": return "0F - 1F";
        case "BOTH_FORFEIT_LOSS": return "0F - 0F";
        case "BOTH_LOSE": return "0 - 0";
        case "HALF_ZERO": return "\u00BD - 0";
        case "ZERO_HALF": return "0 - \u00BD";
        case "ZERO_ZERO": return "0 - 0";
        default: return "-";
    }
}



export const isBye = result => {
    switch (result) {
        // case "WHITE": return false;
        // case "BLACK": return false;
        // case "DRAW": return false;
        // case "BOTH_LOSE": return false;
        // case "WHITE_FORFEIT_WIN": return false;
        // case "WHITE_FORFEIT_LOSS": return false;
        // case "BOTH_FORFEIT_LOSS": return false;
        case "FULL_BYE": return true;
        case "PAIRING_ALLOCATED_BYE": return true;
        case "HALF_BYE": return true;
        case "ZERO_BYE": return true;
        default: return false;
    }
}
