import React from "react";
import {LayoutSizeSetting} from "./LayoutSizeSetting";
import {DefaultTabSetting} from "./DefaultTabSetting";
import {IncludeTournamentNameSetting} from "./IncludeTournamentNameSetting";

export const TournamentSettings = ({tournament}) => {
    return <div className="p-2">

        <div className="mt-4 font-bold text-2xl">Public View Settings</div>

        <div className="font-light text-sm mt-2">
            <div>These settings affect the public view of this tournament only (see the "Public link" at the top of the page).</div>
            <div>This is useful if you want to embed this tournament info in another site.</div>
            <div>Let us know if there are other settings you would like!</div>
        </div>

        <LayoutSizeSetting tournament={tournament} />

        <DefaultTabSetting tournament={tournament} />

        <IncludeTournamentNameSetting tournament={tournament} />

    </div>
}
