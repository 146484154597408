import {Button} from "../core/Button/Button";
import React, {useState} from "react";
import {Modal} from "../core/Modal/Modal";
import {InputTextField} from "../core/InputTextField/InputTextField";
import {ErrorUserMessage} from "../core/UserMessage";
import axios from "axios";
import {useSelector} from "react-redux";
import {selectServerUri} from "../../store/reducers/config";
import {LoadingSpinner} from "../LoadingSpinner";
import {findSelfLink} from "../../util/HateoasUtil";

export const AdhocEventCreateButton = ({runBy, onCreated}) => {

    const [showCreate, setShowCreate] = useState(false);
    const [name, setName] = useState("");
    const [modalError, setModalError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [savingError, setSavingError] = useState(null);

    const showCreateModal = () => { setShowCreate(true) }
    const hideCreateModal = () => { setShowCreate(false) };

    const serverUri = useSelector(selectServerUri);

    const onCreateBasic = () => {

        if (name.trim().length === 0) {
            return;
        }

        setSaving(true);
        const uri = serverUri + "/api/adhocEvents";
        axios.post(uri, {name, runByUri: findSelfLink(runBy)})
            .then(res => {
                setShowCreate(false);
                setName("");
                onCreated(res.data);
            })
            .catch(err => {
                console.log("Error creating event: ", err);
                setSavingError("Error creating event");
            })
            .finally(() => setSaving(false));
    }

    const onSetName = (name) => {
        if (name.trim().length === 0) {
            setModalError("Name cannot be blank");
        } else {
            setModalError(null);
        }

        setName(name);
    }

    return <span>
        <Button onClick={showCreateModal}>Add Event</Button>

        <Modal isOpen={showCreate} title="Add Event"
               saveText="Next"
               onSave={onCreateBasic}
               onClose={hideCreateModal}>
            <div>


                <div>Start by entering the name of the event (you can change this later).</div>

                <InputTextField label="Name" onChange={onSetName} value={name} className="mt-2" />


                {runBy && <div className="italic mt-2">
                    Event is being run by: {runBy.name}
                </div>}

                <div className="font-light my-2 text-sm">
                    Note that this is for events like a meeting or BBQ. To create a tournament
                    please navigate to the tournament section in the side bar.
                </div>

                <ErrorUserMessage visible={modalError} message={modalError} className="mt-2" />
                <ErrorUserMessage visible={savingError} message={savingError} className="mt-2" />

                <LoadingSpinner visible={saving} title="Creating..." compact={true} className="mt-4" />
            </div>
        </Modal>
    </span>
}
