// import * as firebase from 'firebase';
import firebase from "firebase/app"
import "firebase/auth"
import {Observable} from 'rxjs/Rx';
import {Config} from "./config";
import {onUserLoggedIn, userLoggedOut, userReloaded} from "./store/slices/authentication";


const config = {
    apiKey: Config.firebase.apiKey,
    authDomain: Config.firebase.authDomain
};

firebase.initializeApp(config);
console.log("Initialized the Firebase app");

// An Observable of FirebaseUser for use in real code.
export const firebaseUserProvider = () => new Observable(emitter => {
    return firebase.auth().onAuthStateChanged(user => emitter.next(user));
});

export const firebaseAuthActions = userProvider => (userProvider || firebaseUserProvider()).switchMap(user => {
    if (!user) {
        return Observable.of(userLoggedOut());
    } else {
        // TODO consider if this needs to be changed to an interval or something.  It's not clear we will see the
        // token refresh on expiry.  This needs to be tested.
        return new Observable(emitter => {
            user.getIdToken(false)
                .then(token => {
                    emitter.next(onUserLoggedIn({user, token}));
                    emitter.complete();
                })
                .catch(err => {
                    console.log("Error: ", err);
                });
        });
    }
}).shareReplay(1);

export const reloadFirebaseUser = () => {
    if(firebase.auth() && firebase.auth().currentUser){
        const currentUser = firebase.auth().currentUser;
        return currentUser.reload()
            .then(() =>
                currentUser.getIdToken(true))
            .then(token => {
                // eslint-disable-next-line no-undef
                userReloaded(currentUser, token);
            })
            .catch(err => {
                console.log("Error: ", err);
            });
    }
};

export default firebase;
