import React, {useEffect, useState} from "react";
import moment from "moment-timezone";

export const BaseTile = ({event, children, onClick}) => {

    const [time, setTime] = useState(null);

    useEffect(() => {
        setTime(event.scheduledStart ? moment(event.scheduledStart) : null);
    }, [event])

    return <div className="bg-white shadow-lg flex flex-row mb-2" onClick={onClick}>

        {time && <div className="w-20 bg-blue-800 mr-2 text-white flex flex-col text-center align-middle justify-center pb-3" style={{verticalAlign: "middle"}}>
            <div className="text-3xl font-light">{time.format("D")}</div>
            <div className="text-sm font-extrabold">{time.format("MMM")}</div>
            <div className="font-light">{time.format("h:mma")}</div>
        </div>}
        <div className="relative p-3">
            <div>
                <span className="font-light text-lg mr-3">{event.eventName}</span>
            </div>
            <div className="mt-2">
                {children}
            </div>
        </div>
    </div>
}
