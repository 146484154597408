import React, {useCallback, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectServerUri} from "../../store/reducers/config";
import * as axios from "axios";
import {ErrorUserMessage} from "../../components/core/UserMessage";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import {PageContainer} from "../../components/layout/PageContainer/PageContainer";
// import {useHistory} from "react-router-dom";
import {AdhocEventTile} from "./AdhocEventTile";
import {PairingEventTile} from "./PairingEventTile";
import {RefreshButton} from "../../components/RefreshButton";


export const SchedulePage = () => {

    // const history = useHistory();

    const [events, setEvents] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);

    const serverUri = useSelector(selectServerUri);

    // const showEvent = (event) => {
    //     history.push("/events/edit/" + event.uuid);
    // }

    const fetchEvents = useCallback(() => {

        setLoading(true);
        setErrorLoading(false);
        axios.get(serverUri + "/api/schedule")
            .then(res => {
                setEvents(res.data.entries)
            })
            .catch(() => {
                setErrorLoading(true);
            })
            .finally(() => setLoading(false));
    }, [serverUri]);

    useEffect(() => {
        fetchEvents();
    }, [fetchEvents]);

    const renderEvent = (e) => {
        if (e.type === "PAIRING") {
            return <PairingEventTile event={e} />
        } else if (e.type === "ADHOC_EVENT") {
            return <AdhocEventTile event={e} onUpdated={fetchEvents} />
        } else {
            return null;
        }
    }

    return <PageContainer>

        <div className="mb-3 ml-2">
            <div className="flex flex-row">
                <div className="text-3xl">Upcoming</div>
                <RefreshButton onClick={fetchEvents} className="ml-2" />
                <LoadingSpinner visible={loading} compact={true} title="Loading schedule" />
            </div>
            <p className="text-lg my-2 font-light">See what you have coming up, whether it's a team event or a game in a tournament</p>
        </div>

        <div className="pb-4">
            {!loading && !errorLoading && (!events || events.length === 0) && <div className="ml-4 pb-2">No events found.</div>}
            {events?.map(e => renderEvent(e))}
        </div>

        <div className="mt-2 ">
            <ErrorUserMessage visible={errorLoading} message="Loading schedule"/>
        </div>

    </PageContainer>
}
