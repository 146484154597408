import React, {useState} from "react";
import {executeRequest} from "../../util/RequestUtil";
import {LoadingSpinner} from "../LoadingSpinner";
import {ErrorUserMessage} from "../core/UserMessage";
import {Modal} from "../core/Modal/Modal";
import {findLink} from "../../util/HateoasUtil";
import axios from "axios";
import {DeleteButton} from "../DeleteButton";

export const RemovePlayerButton = ({player, onUpdated, className}) => {

    const [showConfirm, setShowConfirm] = useState(false);

    const [removing, setRemoving] = useState(false);
    const [error, setError] = useState(null);

    const onRemove = () => {
        executeRequest(axios.delete(findLink(player, "delete")), setRemoving, setError, () => {
            setShowConfirm(false);
            onUpdated();
        });
    }

    return <div className={className}>
        <Modal
            isOpen={showConfirm}
            title="Confirm"
            saveText="Confirm"
            onSave={onRemove}
            onClose={() => setShowConfirm(false)}>
            <div>
                <div className="text-center">
                    <div>Are you sure you want to remove the following player from the tournament?</div>
                    <div className="font-bold mt-3">{player?.name}</div>
                </div>

                <LoadingSpinner visible={removing} compact={true} title="Removing..." />
                <ErrorUserMessage visible={error} message={error} className="mt-2" />
            </div>
        </Modal>
        <DeleteButton compact={true} onClick={() => setShowConfirm(true)} color="red">Remove</DeleteButton>
    </div>
}
