import {Modal} from "../../../../components/core/Modal/Modal";
import {isBlank} from "../../../../util/GeneralUtil";
import React from "react";

export const SelectOpponentModal = ({unpaired, onSelected, onClose}) => {

    return <Modal
        isOpen={true}
        onClose={onClose}>

        <div style={{height: "80vh"}} className="overflow-y-scroll">

            {unpaired.map(p => <div
                className="p-2 hover:bg-gray-400 rounded cursor-pointer"
                onClick={() => {
                    onSelected(p);
                    onClose();
                }}>
                <span className="font-bold text-gray-900">{p.name}</span><span className="text-sm font-light text-gray-700"> ({p.rating})</span>
                {!isBlank(p.team) && <div className="text-xs font-bold italic text-gray-700">
                    <span className="font-light">Team:</span> {p.team}</div>}
            </div>)}
        </div>

    </Modal>
}
