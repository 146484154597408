import {PlayerResultsModal} from "../PlayerResultsModal/PlayerResultsModal";
import React, {useEffect, useState} from "react";
import {axiosInstance, toOpenResource} from "../../../util/RequestUtil";
import {findLink, findSelfLink} from "../../../util/HateoasUtil";
import {Cell, sizeClasses} from "./index";
import Button from "../../core/Button";
import axios from "axios";
import {formatTiebreakShort} from "../../../util/EntityUtil";
import {isBlank} from "../../../util/GeneralUtil";


const PlayerCell = ({view, children, size, onClick}) => {
    return <td onClick={onClick} className={"border-solid border-gray-500 bg-white " + sizeClasses(view, size)}>
        {children}
    </td>
}

const ResultsCell = ({cell, view, size, onClick}) => {
    if (cell.results.length === 1 && cell.results[0] === "X") {
        return <td className={"border-solid border-gray-500 text-center bg-gray-600 w-12 " + sizeClasses(view, size)}>
        </td>
    } else {
        return <Cell view={view} size={size} onClick={onClick}>
            {cell.results.map(entry => <RoundScore value={entry}/>)}
        </Cell>
    }
}

const TotalsCell = ({view, children, size}) => {
    return <td className={"border-solid border-gray-500 font-bold text-center bg-white " + sizeClasses(view, size)}>
        {children}
    </td>
}

const RoundScore = ({value}) => {
    if (value === "1.0") {
        return <span>1</span>
    } else if (value === "0.0") {
        return <span>0</span>
    } else if (value === "0.5") {
        return <span>&#xBD;</span>
    } else if (value === "X") {
        return <span/>
    } else {
        return <span>{value}</span>
    }
}

const countResults = r => {
    return r.results
        .map(r => r.results
            .filter(r => r !== "X" && !isBlank(r))
            .length)
        .reduce((a, b) => a + b, 0)
}

export const StandardCrosstableView = ({tournament, viewMode}) => {

    const layoutSize = tournament.layoutSize;

    const [crosstable, setCrosstable] = useState(null);
    const [tiebreaks, setTiebreaks] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    const fetchCrosstable = () => {
        axiosInstance(viewMode).get(toOpenResource(findLink(tournament, "crosstable"), viewMode))
            .then(res => {
                setCrosstable(res.data);
                if (res.data.rows && res.data.rows.length > 0) {
                    setTiebreaks(res.data.rows[0].tiebreaks?.map(t => t.type));
                } else {
                    setTiebreaks([]);
                }
            })
            .catch(err => console.log("Error fetching tournament crosstable: " + err))
    }


    const downloadPdf = () => {
        axios.get(findSelfLink(tournament) + "/standingsPdf", {responseType: 'blob'})
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            });
    }


    useEffect(fetchCrosstable, [tournament]);

    return <div className="p-4 pt-2 w-full bg-white shadow-lg">

        {selectedPlayer && <PlayerResultsModal
            view={viewMode}
            tournament={tournament}
            player={selectedPlayer}
            onClose={() => setSelectedPlayer(null)}
        />}

        {!viewMode && <Button className="mb-2" compact={true} onClick={downloadPdf} color="gray">Download standings PDF</Button>}


        {crosstable && <table>
            <thead>
            <tr>
                <td/>
                <td/>
                {crosstable.rounds.map(r => <Cell className="font-bold" view={viewMode} size={layoutSize}>{r}</Cell>)}
                <TotalsCell view={viewMode} size={layoutSize}>Score</TotalsCell>
                <TotalsCell view={viewMode} size={layoutSize}># Games</TotalsCell>
                {tiebreaks.map(t => <Cell size={layoutSize} view={viewMode} className="font-light italic" bgColor="bg-gray-200">{formatTiebreakShort(t)}</Cell>)}
            </tr>
            </thead>
            <tbody>
            {crosstable.rows.map((r, idx) => <tr>
                <Cell view={viewMode} size={layoutSize}>{idx + 1}</Cell>
                <PlayerCell view={viewMode} size={layoutSize} onClick={() => setSelectedPlayer(r)}>{r.playerName}</PlayerCell>
                {r.results.map(cell => <ResultsCell view={viewMode} size={layoutSize} cell={cell} onClick={() => {
                    const matching = crosstable.rows.filter(p => p.memberUuid === cell.opponentMemberId);
                    if (matching.length > 0) {
                        setSelectedPlayer(matching[0]);
                    }
                }}/>)}
                <Cell view={viewMode} size={layoutSize} className="font-bold">{r.points}</Cell>
                <Cell view={viewMode} size={layoutSize} className="font-light">{countResults(r)}</Cell>
                {r.tiebreaks.map(t => <Cell bgColor="bg-gray-200" size={layoutSize} view={viewMode}><span className="font-light italic">{t.score == null ? 0 : +t.score.toFixed(1)}</span></Cell>)}
            </tr>)}
            </tbody>
        </table>}
    </div>
}
