import React, {useEffect, useMemo, useState} from "react";
import Button from "../../../../components/core/Button";
import {FullPointByeWarningModal} from "../../FullPointByeWarningModal";
import {SelectOpponentModal} from "./SelectOpponentModal";
import {executeRequest} from "../../../../util/RequestUtil";
import axios from "axios";
import {findLink, findSelfLink} from "../../../../util/HateoasUtil";


export const PairingCreationManager = ({tournament, pairingPlayer, round, unpaired, onCancel, onRefresh}) => {

    const [white, setWhite] = useState(null);
    const [black, setBlack] = useState(null);
    const [bye, setBye] = useState(false);

    const [showFideModeWarning, setShowFideModeWarning] = useState(false);
    const [selectOpponent, setSelectOpponent] = useState(false);

    const [, setSaving] = useState(false);
    const [, setError] = useState(null);

    const active = white || black;
    const fideMode = tournament.fideMode ?? true;

    useEffect(() => {
        setWhite(pairingPlayer);
        setBlack(null);
        setBlack(false);
        setShowFideModeWarning(false);
        setSelectOpponent(false);
    }, [pairingPlayer]);

    const filteredPlayers = useMemo(() => unpaired
            .filter(p => !p.representsBye)
            .filter(p => white == null || p.uuid !== white.uuid)
            .filter(p => black == null || p.uuid !== black.uuid)
            .sort((a, b) => a.name.localeCompare(b.name))
        , [unpaired, white, black])


    const onSave = (result = null) => {
        const data = {
            whiteUri: findSelfLink(white),
            blackUri: findSelfLink(black),
            result: result
        }

        console.log("About to save: ", data);

        executeRequest(axios.post(findLink(round, "pairings"), data), setSaving, setError, () => {
            onRefresh();
            onCancel();
        });
    }

    const saveFullPointBye = () => {
        onSave("FULL_BYE");
    }

    const swapColors = () => {
        if (white && black) {
            const tmp = white;
            setWhite(black);
            setBlack(tmp);
        }
    }

    const onFullPointByeSelected = () => {
        if (fideMode) {
            setShowFideModeWarning(true);
        } else {
            saveFullPointBye();
        }
    }

    const onPlayerSelected = (player) => {
        if (!white) {
            setWhite(player);
        } else {
            setBlack(player);
        }
    }

    return active ? <div className="bg-gray-400 p-4 mb-2 mr-2 rounded">

        <FullPointByeWarningModal
            visible={showFideModeWarning}
            onClose={() => setShowFideModeWarning(false)}
            onSave={saveFullPointBye}/>

        {selectOpponent && <SelectOpponentModal unpaired={filteredPlayers} onClose={() => setSelectOpponent(false)} onSelected={onPlayerSelected} />}

        <div className="font-bold mb-4">
            Create pairing
        </div>
        <div className="mb-4 text-sm">
            <div className="mb-2">
                <span className="font-bold">White:</span>
                {white && <span className="ml-2">{white?.name}</span>}
                {!white && <Button className="ml-2" color="gray" compact={true} onClick={() => setSelectOpponent(true)}>Select</Button>}
            </div>
            <div>
                <span className="font-bold">Black:</span>
                {black && <span className="ml-2">{black?.name}</span>}
                {!black && <Button className="ml-2" color="gray" compact={true} onClick={() => setSelectOpponent(true)}>Select</Button>}
            </div>
        </div>

        <Button compact={true} color={bye ? "gray" : "green"} onClick={() => onSave()}>Save</Button>
        {white && black && <Button className="ml-4" color="gray" compact={true} onClick={swapColors}>Swap colours</Button>}
        {(!white || !black) && <Button className="ml-2" color="gray" compact={true} onClick={() => setBye(!bye)}>Give player bye</Button>}
        <span className="ml-6 text-blue-700 underline italic cursor-pointer font-light text-sm " onClick={onCancel}>Cancel</span>

        {bye && <div className="text-center mt-2">
            <div className="font-light">Select the type of bye</div>
            <Button className="" color="green" compact={true} onClick={() => onSave("ZERO_BYE")}>0 point bye</Button>
            <Button className="ml-2" color="green" compact={true} onClick={() => onSave("HALF_BYE")}>Half point bye</Button>
            <Button className="ml-2" color="green" compact={true} onClick={onFullPointByeSelected}>Full point bye</Button>
        </div>}
    </div> : null;

}
