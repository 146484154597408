import React from "react";
import {Route, Switch} from "react-router-dom";
import {PAGE} from "../../App";
import {useSelector} from "react-redux";
import {selectIsAnonymousUser, selectLoggedInUser} from "../../store/slices/authentication";

const PortalRoute = (props) => {

    const {page, onPageChange, requiresAuth} = props;

    const loggedInUser = useSelector(selectLoggedInUser);
    const isAnonymousUser = useSelector(selectIsAnonymousUser);

    if (requiresAuth && (!loggedInUser || isAnonymousUser)) {
        return <PortalRoute
            page={{
                ...PAGE.HOME,
                path: page.path
            }}
            onPageChange={onPageChange}
            requiresAuth={false} />
    }

    return <Route exact {...props} path={page.path} render={props => {
        onPageChange(page.menuLocation);
        const Page = page.component;
        return <Page {...props} />
    }}/>
}

export const Routes = ({onPageChange}) => {

    return <Switch>
        <>

            <PortalRoute page={PAGE.LANDING_TEAMS} requiresAuth={false} onPageChange={onPageChange} exact />
            <PortalRoute page={PAGE.LANDING_TOURNAMENTS} requiresAuth={false} onPageChange={onPageChange} exact />

            <PortalRoute page={PAGE.PRIVACY} requiresAuth={false} onPageChange={onPageChange} exact />
            <PortalRoute page={PAGE.FIDE} requiresAuth={false} onPageChange={onPageChange} exact />
            <PortalRoute page={PAGE.TOS} requiresAuth={false} onPageChange={onPageChange} exact />
            <PortalRoute page={PAGE.LOGIN} requiresAuth={false} onPageChange={onPageChange} exact />
            <PortalRoute page={PAGE.HOME} requiresAuth={false} onPageChange={onPageChange} exact />

            <PortalRoute page={PAGE.USER_PROFILE} requiresAuth={true} onPageChange={onPageChange} exact />
            <PortalRoute page={PAGE.CREATE_TOURNAMENT} requiresAuth={true} onPageChange={onPageChange} exact />
            <PortalRoute page={PAGE.INVITATIONS} requiresAuth={true} onPageChange={onPageChange} exact />
            <PortalRoute page={PAGE.MESSAGES} requiresAuth={true} onPageChange={onPageChange} exact />
            <PortalRoute page={PAGE.FIND_TOURNAMENT} requiresAuth={true} onPageChange={onPageChange} exact />
            <PortalRoute page={PAGE.CLUBS_HOME} requiresAuth={true} onPageChange={onPageChange} exact />
            <PortalRoute page={PAGE.EDIT_CLUB} requiresAuth={true} onPageChange={onPageChange} exact={false} />
            <PortalRoute page={PAGE.EDIT_TOURNAMENT} requiresAuth={true} onPageChange={onPageChange} exact={false} />
            <PortalRoute page={PAGE.GAME_ANALYSIS} requiresAuth={true} onPageChange={onPageChange} exact={true} />
            <PortalRoute page={PAGE.ADHOC_EVENTS} requiresAuth={true} onPageChange={onPageChange} exact={true} />
            <PortalRoute page={PAGE.ADHOC_EVENTS_EDIT} requiresAuth={true} onPageChange={onPageChange} exact={false} />
            <PortalRoute page={PAGE.ADMIN} requiresAuth={true} onPageChange={onPageChange} exact={true} />
            <PortalRoute page={PAGE.PAGE_EDITOR} requiresAuth={true} onPageChange={onPageChange} exact={true} />

            <PortalRoute page={PAGE.TOURNAMENT_VIEW} requiresAuth={false} onPageChange={onPageChange} exact={false} />

        </>
    </Switch>
}
