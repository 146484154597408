import React from "react";
import {Lozenge} from "../core/Lozenge/Lozenge";

export const CustomRoleTile = ({role, onClick}) => {

    return <div onClick={onClick} className="bg-white shadow-lg flex flex-row mb-2 p-3 flex flex-row">
        <div className="flex-1">
            {role.name} ({role.count})
        </div>
        <div className="flex-grow-0">
            <Lozenge compact={true} color={role.count <= role.numAssigned ? "green" : "orange"} title={role.numAssigned + " Assigned"} />
        </div>
    </div>

}
