import React, {useCallback, useEffect, useState} from "react";
import EditTournament from "../../components/EditTournament";
import {PageContainer} from "../../components/layout/PageContainer/PageContainer";
import {useSelector} from "react-redux";
import {selectServerUri} from "../../store/reducers/config";
import axios from "axios";
import {executeRequest} from "../../util/RequestUtil";
import isUUID from "validator/lib/isUUID";


const TournamentEditPage = (props) => {

    const [tournament, setTournament] = useState({});

    const [, setFetching] = useState(false);
    const [, setError] = useState(null);

    const serverUri = useSelector(selectServerUri);

    const refreshTournament = useCallback(() => {
        const urlParts = window.location.href.split("/");
        const id = urlParts[urlParts.length - 1];

        if (isUUID(id)) {
            executeRequest(axios.get(serverUri + "/api/tournaments/" + id), setFetching, setError, data => {
                setTournament(data);
            });
        } else {
            executeRequest(axios.post(serverUri + "/api/tournaments", {name: "My tournament"}), setFetching, setError, data => {
                setTournament(data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        refreshTournament();
    }, [refreshTournament]);

    return <PageContainer>
        <EditTournament
            {...props}
            tournament={tournament}
            refreshTournament={refreshTournament}
            onUpdated={setTournament}/>
    </PageContainer>
}

export default TournamentEditPage;
