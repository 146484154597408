import React from 'react';
import ReactDOM from 'react-dom';
import './assets/main.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {loadState, saveState} from "./store/LocalStorage";
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk'

import WaveApp from './store/reducers';
import AppInitializer from "./components/AppInitializer";
import {Firebase} from "./components/auth/Firebase";
import {ClubWebsite} from "./pages/ClubWebsite/ClubWebsite";

const persistedState = loadState();

const store = createStore(
    WaveApp, persistedState,
    applyMiddleware(thunk)
);

store.subscribe(() => {
    saveState(store.getState());
});

if (window.location.pathname && window.location.pathname.startsWith("/site/")) {

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <AppInitializer />
                <ClubWebsite />
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
} else {

    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <AppInitializer />
                <Firebase>
                    <App />
                </Firebase>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

// ReactDOM.render(
//     <React.StrictMode>
//         <Provider store={store}>
//             <AppInitializer />
//             <Firebase>
//                 <App />
//             </Firebase>
//         </Provider>
//     </React.StrictMode>,
//     document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
