import {Modal} from "../core/Modal/Modal";
import React, {useState} from "react";
import {RSVP_STATUSES, RsvpBaseButton} from "./RsvpBaseButton";
import axios from "axios";
import {ErrorUserMessage} from "../core/UserMessage";
import {LoadingSpinner} from "../LoadingSpinner";
import {rsvpLink} from "../../util/EntityUtil";

export const EditRsvpModal = ({visible, entity, onUpdated, onClose, editable = true}) => {

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const onAccept = () => onUpdateStatus(RSVP_STATUSES.ACCEPTED);
    const onUnsure = () => onUpdateStatus(RSVP_STATUSES.PENDING);
    const onDecline = () => onUpdateStatus(RSVP_STATUSES.DECLINED);

    const onUpdateStatus = (status) => {

        setError(null);
        setSaving(true);
        axios.post(rsvpLink(entity), {response: status})
            .then(() => {
                onUpdated();
                onClose();
            })
            .catch(() => setError("Error updating RSVP status"))
            .finally(() => setSaving(false));
    }

    return <Modal
        isOpen={visible}
        title="Update RSVP"
        saveText=""
        onClose={onClose}>
        <div className="flex flex-col">

            <RsvpBaseButton onClick={onAccept} status={RSVP_STATUSES.ACCEPTED} textOverride="Accept" editable={editable} />
            <div className="h-2"/>
            <RsvpBaseButton onClick={onUnsure} status={RSVP_STATUSES.PENDING} textOverride="Unsure" editable={editable} />
            <div className="h-2"/>
            <RsvpBaseButton onClick={onDecline} status={RSVP_STATUSES.DECLINED} textOverride="Decline" editable={editable} />

            <LoadingSpinner visible={saving} title="Saving..." compact={true} className="mt-2" />
            <ErrorUserMessage visible={error} message={error} className="mt-2" />
        </div>
    </Modal>
}
