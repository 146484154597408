import React from "react";
import {BaseTile} from "./BaseTile";
import {Lozenge} from "../../components/core/Lozenge/Lozenge";
import {useHistory} from "react-router-dom";

const isBye = (pairing) => pairing.playerWithBye !== "NONE";

const ResultSummary = ({pairing}) => {
    if (isBye(pairing) || !pairing.playerResult) {
        return null;
    }

    let color;
    let text;

    switch (pairing.playerResult) {
        case "WIN":
            color = "green"
            text = "You won"
            break;
        case "LOSS":
            color = "red"
            text = "You lost"
            break;
        case "DRAW":
            color = "orange"
            text = "Draw"
            break;
        default:
            color = "gray"
            text = "" + pairing.playerResult
    }

    return <span className="ml-3 pb-2"><Lozenge compact={true} color={color} title={text} /></span>;
}

export const PairingEventTile = ({event}) => {

    const history = useHistory();

    const pairing = event.source;

    const bye = isBye(pairing);

    return <BaseTile event={event} onClick={() => history.push("/tournaments/edit/" + event.source.tournamentId)}>
        <div className="text-xl">
            Round {pairing.roundName}
            <ResultSummary pairing={pairing} />
        </div>
        {bye && <div className="mt-2">
            No match - you have a bye
        </div>}
        {!bye && <div className="mt-2">
            <span className="font-medium">{pairing.whiteDescription}</span>
            <span className="font-light ml-1 mr-1"> vs. </span>
            <span className="font-medium mr-3">{pairing.blackDescription}</span>
        </div>}

    </BaseTile>
}
