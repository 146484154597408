import React, {useCallback, useEffect, useState} from "react";
import {Button} from "../core/Button/Button";
import * as axios from "axios";
import {useSelector} from "react-redux";
import {selectServerUri} from "../../store/reducers/config";
import {addPaginationParams} from "../../util/RequestUtil";
import {findSelfLink} from "../../util/HateoasUtil";
import {ErrorUserMessage} from "../core/UserMessage";
import {LoadingSpinner} from "../LoadingSpinner";
import {Modal} from "../core/Modal/Modal";
import {DeleteButton} from "../DeleteButton";
import {EditButton} from "../EditButton";
import {TournamentImportModal} from "../TournamentImportModal";
import {CopyButton} from "../CopyButton";


const tournamentName = t => {

    if (!t) {
        return "";
    }

    return t.name ? t.name : "Unnamed - Created: " + t.created;
}

const TournamentNameCell = ({t, onClick}) => {

    const niceName = tournamentName(t);

    if (t.name) {
        return <td className="border px-4 py-2" onClick={onClick}>{niceName}</td>
    } else {
        return <td className="border px-4 py-2 italic text-gray-600" onClick={onClick}>
            Unnamed - Created: {niceName}
        </td>
    }
}

/**
 * Show a list of tournaments matching the following criteria
 * @param title The title to display about the tournament list (if any)
 * @param tournamentStatus An array of statuses the tournaments are allowed to be in
 * @param currentUsers boolean indicating whether it should be limited to tournaments of the current user
 */
const TournamentList = ({title, tournamentStatus, currentUsers, history}) => {

    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [tournaments, setTournaments] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorLoading, setErrorLoading] = useState(false);
    const [deletingTournament, setDeletingTournament] = useState(null);

    const [showImportModal, setShowImportModal] = useState(false);

    const serverUri = useSelector(selectServerUri);

    const showTournament = (tournament) => {
        history.push("/tournaments/edit/" + tournament.uuid);
    }

    const copyTournament = (tournament) => {

        axios.get(findSelfLink(tournament) + "/copy")
            .then(res => {
                history.push("/tournaments/edit/" + res.data.uuid);
            })
            .catch(err => {
                setErrorLoading(true);
                console.log("Error copying tournaments: " + err)
            })
            .finally(() => setLoading(false));
    }

    const createTournament = () => {
        history.push("/tournaments/edit/");
    }

    const fetchTournaments = useCallback(() => {
        setLoading(true);
        setErrorLoading(false);

        axios.get(serverUri + addPaginationParams("/api/tournaments", page))
            .then(res => {
                setTotalPages(res.data.totalPages)
                setTournaments(res.data.page)
            })
            .catch(err => {
                setErrorLoading(true);
                console.log("Error fetching tournaments: " + err)
            })
            .finally(() => setLoading(false));
    }, [page, serverUri]);

    const startConfirmDelete = tournament => setDeletingTournament(tournament);

    const cancelDelete = () => setDeletingTournament(null)

    const deleteTournament = useCallback(()=> {

        axios.delete(findSelfLink(deletingTournament))
            .then(() => {
                setDeletingTournament(null);
                fetchTournaments();
            })
    }, [fetchTournaments, deletingTournament]);

    const canGoNext = () => page + 1 < totalPages;
    const canGoPrev = () => page > 0;

    const nextPage = () => {
        if (canGoNext()) {
            setPage(page + 1);
        }
    }

    const prevPage = () => {
        if (canGoPrev()) {
            setPage(page - 1);
        }
    }

    useEffect(() => {
        fetchTournaments();
    }, [page, fetchTournaments]);

    return <div>


        <div className="mt-4 flex flex-row">
            <Button onClick={createTournament}>Create Tournament</Button>
            <Button className="ml-2" color="gray" onClick={() => setShowImportModal(true)}>Import tournament</Button>
            <TournamentImportModal
                creating={true}
                visible={showImportModal}
                onUpdated={() => {
                    fetchTournaments();
                    setShowImportModal(false);
                }}
                onClose={() => setShowImportModal(false)}  />
        </div>

        <div className="mt-4">
            <p className="text-3xl">{title}</p>
        </div>

        <Modal isOpen={deletingTournament != null} title="Confirm Delete"
               saveText="Delete"
               onSave={deleteTournament}
               onClose={cancelDelete}>
            <div>
                Are you sure you want to delete tournament:
                <br/>
                <b>{tournamentName(deletingTournament)}</b>
            </div>
        </Modal>

        <div className="mt-2 ">
            <Button disabled={!canGoPrev()} color="gray" onClick={prevPage}>Prev Page</Button>
            <Button disabled={!canGoNext()} className="ml-2" color="gray" onClick={nextPage}>Next Page</Button>
        </div>

        <table className="bg-white mt-2">
            <thead>
            <tr>
                <th className="px-4 py-2">Name</th>

                <th className="px-4 py-2"># Rounds</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {!loading && !errorLoading && (!tournaments || tournaments.length === 0) && <div>No tournaments found.</div>}
            {tournaments?.map(t => <tr key={t.uuid}>

                <TournamentNameCell t={t} onClick={() => showTournament(t)}/>

                <td className="border px-4 py-2">{"" + (t.totalRounds ? t.totalRounds : "")}</td>
                <td className="border px-4 py-2">
                    <EditButton onClick={() => showTournament(t)} />
                    <CopyButton className="ml-2" onClick={() => copyTournament(t)} />
                    <DeleteButton className="ml-2" onClick={() => startConfirmDelete(t)}/>
                </td>
            </tr>)}
            </tbody>
        </table>

        <div className="mt-2 ">
            <ErrorUserMessage visible={errorLoading} message="Loading results"/>
        </div>

        <LoadingSpinner visible={loading} compact={true} title="Loading tournaments" />
    </div>
}

export default TournamentList;
