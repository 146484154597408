import React, {useEffect, useState} from "react";
import {InputTextField} from "../../components/core/InputTextField/InputTextField";
import {DateTimeField} from "../../components/core/DateTimeField";
import moment from "moment";
import {Button} from "../../components/core/Button/Button";
import {findSelfLink} from "../../util/HateoasUtil";
import axios from "axios";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import {ErrorUserMessage} from "../../components/core/UserMessage";
import {CustomRoleList} from "../../components/CustomRoles/CustomRoleList";

export const AdhocEventSummaryDetails = ({canEdit = false, event, onUpdated}) => {

    const [name, setName] = useState("");
    const [startTime, setStartTime] = useState(null);
    const [location, setLocation] = useState("");
    const [dirty, setDirty] = useState(false);

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const onSave = () => {

        const data = {
            name: name,
            startTime: startTime?.toISOString() ?? null,
            locationDesc: location
        }

        setSaving(true);
        setError(null);
        axios.post(findSelfLink(event), data)
            .then(res => {
                onUpdated();
            })
            .catch(err => setError(err))
            .finally(() => setSaving(false));
    };

    useEffect(() => {
        setName(event?.name ?? "");
        setStartTime(event?.scheduledTime ? moment(event?.scheduledTime) : null);
        setLocation(event?.locationDescription ?? "");
        setDirty(false);
    }, [event]);

    const onSetName = (value) => {
        setName(value);
        setDirty(true);
    }

    const onSetStartTime = (value) => {
        setStartTime(value);
        setDirty(true);
    }

    const onSetNameLocation = (value) => {
        setLocation(value);
        setDirty(true);
    }

    return <div className="bg-white shadow-lg p-4 inline-block w-full flex flex-row">
        <div className="flex-1">
            <InputTextField editMode={canEdit} label="Event Name" onChange={onSetName} value={name} />
            <DateTimeField editMode={canEdit} label="Start time" className="mt-3" onChange={onSetStartTime} value={startTime} />
            <InputTextField editMode={canEdit} label="Location" className="mt-3" onChange={onSetNameLocation} value={location} />
            {canEdit && dirty && <div>
                <Button onClick={onSave} color="green" className="mt-3">Save</Button>
                <span className="ml-4 text-orange-600  italic">Details updated, don't forget to save!</span>
            </div>}
            <LoadingSpinner visible={saving} compact={true} title="Updating details..." />
            <ErrorUserMessage visible={error} message="Error updating details" />
        </div>
        <div style={{width: "1px"}} className="bg-gray-300 mx-1"></div>
        <div className="flex-1">
            <CustomRoleList parent={event} editMode={canEdit} />
        </div>
    </div>
}
