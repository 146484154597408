import React, {useState} from "react";
import {Modal} from "../../components/core/Modal/Modal";
import {InputTextField} from "../../components/core/InputTextField/InputTextField";
import {useSelector} from "react-redux";
import {selectServerUri} from "../../store/reducers/config";
import axios from "axios";
import {ErrorUserMessage} from "../../components/core/UserMessage";
import {LoadingSpinner} from "../../components/LoadingSpinner";

const CreateClubModal = ({visible, onClose, history}) => {

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    const serverUri = useSelector(selectServerUri);

    const save = () => {
        setSaving(true);
        const uri = serverUri + "/api/clubs";
        axios.post(uri, {name, description})
            .then(res => {
                onClose();
                history.push("/clubs/edit/" + res.data.uuid);
            })
            .catch(err => setError(err))
            .finally(() => setSaving(false));
    }

    return <Modal title="Create Club" isOpen={visible} onSave={save} onClose={onClose}>

        <InputTextField label="Name" onChange={setName} value={name} />
        <div className="mt-4">
            <InputTextField label="Description" onChange={setDescription} value={description} />
        </div>

        {error && <ErrorUserMessage title="Error creating club" />}
        <LoadingSpinner visible={saving} title="Creating club" />
    </Modal>
}

export default CreateClubModal;
