import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {query} from "../../util/RequestUtil";
import {selectUpdateNameLink, setUserProfile} from "../../store/slices/authentication";

export const updateName = createAsyncThunk(
    'usernamePromptModal/updateName',
    async (arg, {dispatch, getState}) => {
        const profile = await query(axios.post(selectUpdateNameLink(getState()), {name: arg}));

        dispatch(setUserProfile(profile));

        return profile;
    });

const initialState = {
    saving: false,
    error: null,
}

const usernamePromptModal = createSlice({
    name: 'authentication',
    initialState: {user: null},
    reducers: {
        reset: () => initialState,
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
    extraReducers: {
        [updateName.pending]: (state) => {
            state.saving = true;
        },
        [updateName.fulfilled]: (state, action) => {
            state.saving = false;
        },
        [updateName.rejected]: (state, error) => {

            console.log("Error updating name: ", error);

            state.saving = false;
            state.error = "Error updating name";
        },
    }
})

export const { reset, setError } = usernamePromptModal.actions

export default usernamePromptModal.reducer

export const selectUsernamePromptSaving = state => state.usernamePromptModal.saving;
export const selectUsernamePromptError = state => state.usernamePromptModal.error;

