import React from "react";
import {FieldLabel} from "./FieldLabel/FieldLabel";
import {ReadonlyField} from "./ReadonlyField";


// Error example
// <input
//     className="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
//     id="password" type="password" placeholder="******************"/>
// <p className="text-red-500 text-xs italic">Please choose a password.</p>

export const InputTextareaField = ({editMode = true, label, onChange, ...props}) => {
    if (editMode) {
        return <div className={"" + props.className}>
            <FieldLabel label={label}/>
            <textarea
                {...props}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                onChange={e => onChange(e.target.value)}/>
        </div>
    } else {
        return <div className={"" + props.className}><ReadonlyField name={label} value={props.value} /></div>
    }
}
