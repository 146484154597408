import React from "react";
import {MenuItem} from "./MenuItem";
import {faUser} from '@fortawesome/free-solid-svg-icons'
import {PAGE} from "../../App";


export const LoggedOutMenuOptions = ({visible, ...props}) => {

    // const dispatch = useDispatch();
    // const history = useHistory();
    //
    // const currentPage = useSelector(selectCurrentPage);

    if (!visible) {
        return null;
    }

    // const baseOptionStyle = "mx-auto lg:mx-0 bg-white bg-opacity-50 text-gray-800 hover:bg-opacity-100 font-bold rounded-full py-2 px-4 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-125 duration-300 ease-in-out";
    // const selectedOptions = " bg-opacity-100"
    //
    // const showingTeams = currentPage !== "LANDING_TOURNAMENTS";

    return <div
        className="flex-grow flex items-center w-auto mt-2 mt-0 bg-transparent text-black p-0 z-20"
        id="nav-content">
        <div className="flex-grow grid-cols-3 grid justify-items-center">
            {/*<div className={baseOptionStyle + (showingTeams ? selectedOptions : "")} onClick={() => history.push("/welcome/teams")}>Teams</div>*/}
            {/*<div className={baseOptionStyle + selectedOptions} onClick={() => history.push("/welcome/tournaments")}>Tournaments</div>*/}
            {/*<div className={baseOptionStyle + (showingTeams ? "" : selectedOptions)} onClick={() => history.push("/welcome/tournaments")}>Pricing</div>*/}
            {/*<div className="text-white px-4 py-2 rounded-full hover:bg-white hover:bg-opacity-75 hover:text-gray-800">Tournaments</div>*/}
        </div>
        <ul className="list-reset flex justify-end flex-1 items-center">
            <MenuItem title="Create Account / Login" icon={faUser} page={PAGE.LOGIN} {...props} />
        </ul>
        {/*<PrimaryCtaButton title="Manage Tournaments" onClick={createTournament} />*/}
    </div>
}
