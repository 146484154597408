//import * as axios from "axios";

// const serverUri = "http://localhost:8080"
// const serverUri = "http://localhost:8080"

// console.info("Server URI: ", serverUri);
//
// const lookupServerUri = async () => {
//
//     // if we are running on an AWS EC2 instance this will be the public hostname
//     const hostname = await axios.get("http://169.254.169.254/latest/meta-data/public-hostname")
//         .then(res => res.data)
//         .catch(err => "localhost");
//
//     console.log("Found hostname: " + hostname);
//
//     return "http://" + hostname + ":8080";
// }
//
// export const Config = {
//     serverUri: lookupServerUri(),
// };

const firebase = {
    apiKey: "AIzaSyDgY3bdauHtpRQetvS7Uag7IkA29sIJMJQ",
    authDomain: "movekeep-ae48d.firebaseapp.com",
}

const firebaseDev = {
    apiKey: "AIzaSyC9-dmkdfmWQi0CvhdkbalhMM_DZwuYeQQ",
    authDomain: "movekeep-dev.firebaseapp.com",
}

const isDev = window.location.href.indexOf("http://localhost:3000") >= 0;

export const Config = {
    firebase: isDev ? firebaseDev : firebase
};
