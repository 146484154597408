import React, {useCallback, useEffect, useState} from "react";
import {AddPlayer} from "../../AddPlayer";
import {findLink} from "../../../util/HateoasUtil";
import {LoadingSpinner} from "../../LoadingSpinner";
import {ErrorUserMessage} from "../../core/UserMessage";
import {RefreshButton} from "../../RefreshButton";
import {axiosInstance, toOpenResource} from "../../../util/RequestUtil";
import {RadioButton} from "../../core/RadioButton";
import {isBlank, tournamentSize} from "../../../util/GeneralUtil";

const sortMembers = (members, sort) => {
    if (!members) {
        return members;
    }

    const copy = [...members];
    copy.sort((a, b) => {
        if (sort === "name") {
            return a.name.localeCompare(b.name);
        } else if (sort === "rating") {
            return b.rating - a.rating;
        } else {
            return (b.score - a.score) || a.name.localeCompare(b.name);
        }
    });
    return copy;
}

export const PlayerList = ({size = "LARGE", team = null, entity, entityName, memberDesc, renderHeader, renderRow, editMode = false, canAddPlayers = true, viewMode = false}) => {

    const [members, setMembers] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(null);
    const [sort, setSort] = useState("name");

    const refreshMembers = useCallback(() => {

        if (entity == null) {
            setMembers([]);
            return;
        }

        setFetching(true);
        setError(null);

        const suffix = team == null ? "" : "?team=" + team

        const baseUri = findLink(entity, "members");

        if (isBlank(baseUri)) {
            return;
        }

        axiosInstance(viewMode).get(toOpenResource(baseUri.replace("{?team}", "") + suffix, viewMode))
            .then(res => {
                setMembers(sortMembers(res.data.page, sort));
            })
            .catch(err => {
                setError("Error fetching " + (memberDesc ?? "player") + "s");
            })
            .finally(() => {
                setFetching(false);
            });
    }, [entity, memberDesc, viewMode, sort, team]);

    useEffect(() => {
        setMembers([]);
        refreshMembers();
    }, [entity, refreshMembers]);

    useEffect(() => {
        setMembers(m => sortMembers(m, sort));

    }, [sort]);

    return <div className="p-2 bg-white">

        {editMode && canAddPlayers && <>
            <AddPlayer
                entityName={memberDesc}
                entity={entity}
                team={team}
                onPlayerAdded={refreshMembers} />
            <div className="text-sm font-light italic">Search for an existing {memberDesc ?? "player"} or add a new {memberDesc ?? "player"}</div>
        </>}



        <div className="flex flex-row mt-2">
            <RefreshButton onClick={refreshMembers} compact={tournamentSize(viewMode, size, true, true, false)} />
            <LoadingSpinner visible={fetching} title={"Refreshing members"} className="ml-2" />

            <div className="flex flex-row items-center ml-4">
                <div className="font-bold">Sort</div>
                <div className="ml-2"><RadioButton type="radio" value="name" selectedValue={sort} onClick={setSort} group="sort" description="Name"/></div>
                <div className="ml-2"><RadioButton type="radio" value="score" selectedValue={sort} onClick={setSort} group="sort" description="Score"/></div>
                <div className="ml-2"><RadioButton type="radio" value="rating" selectedValue={sort} onClick={setSort} group="sort" description="Rating"/></div>
            </div>
        </div>

        <ErrorUserMessage visible={error} message={error} />

        <div className="mt-2">
            {members?.length === 0 && <div>No {memberDesc ?? "player"} in the {entityName ?? "tournament"} yet</div>}

            {members?.length > 0 && <table className="table-fixed bg-white">
                {renderHeader()}
                <tbody>
                {members?.filter(p => p != null).map(p => renderRow(p, refreshMembers))}
                </tbody>
            </table>}
        </div>
    </div>
}
