import React from "react";
import {MenuItem} from "./MenuItem";
import {faChess, faSignOutAlt, faUsers} from '@fortawesome/free-solid-svg-icons'
import {PAGE} from "../../App";
import firebase from "../../Firebase";
import {useSelector} from "react-redux";
import Avatar from "react-avatar";
import {selectProfileName} from "../../store/slices/authentication";
import {useHistory} from "react-router-dom";
import {useComponentVisible} from "../../util/Hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const UserMenu = ({history}) => {
    const {
        ref,
        isComponentVisible,
        setIsComponentVisible
    } = useComponentVisible(false);



    const onLogout = () => {
        firebase.auth().signOut()
            .then(() => window.location.href = "/");
    }

    const usersName = useSelector(selectProfileName);

    return <div className="flex-row bg-gray-900 text-white p-2 py-2 relative" onClick={() => setIsComponentVisible(true)}>
        <div className="justify-self-center inline">
            <Avatar  name={usersName} size={30} round="30px" />
        </div>
        <span className="ml-2 font-light text-sm">{usersName}</span>
        {isComponentVisible && <div ref={ref} className="absolute bg-white shadow-lg rounded top-0 right-0 mt-8 ml-2 text-gray-800 p-4 pt-2 hover:bg-gray-400 cursor-pointer">
            {/*<div className="pt-2" onClick={() => {*/}
            {/*    console.log("showing profile");*/}
            {/*    history.push("/profile");*/}
            {/*    setIsComponentVisible(false);*/}
            {/*}}>*/}
            {/*    <FontAwesomeIcon icon={faUserCircle} />*/}
            {/*    <span className="ml-3">User profile</span>*/}
            {/*</div>*/}
            <div className="pt-2" onClick={onLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                <span className="ml-3">Logout</span>
            </div>
        </div>}
    </div>



}

export const LoggedInMenuOptions = ({visible, ...props}) => {
    const history = useHistory();

    if (!visible) {
        return null;
    }

    // return <div
    //     className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
    //     id="nav-content">

    return <div className="flex flex-row flex-grow">
        <MenuItem title="Tournaments" icon={faChess} page={PAGE.CREATE_TOURNAMENT} {...props} />
        <MenuItem title="Clubs" icon={faUsers} page={PAGE.CLUBS_HOME} {...props} />
        {/*<hr className="border-b border-gray-100 opacity-25 my-0 py-0"/>*/}
        <div>&nbsp;</div>
        {/*<MenuItem title="Upcoming" icon={faCalendar} page={PAGE.ADHOC_EVENTS} {...props} />*/}
        {/*<div>&nbsp;</div>*/}
        {/*<MenuItem title="Messages" icon={faComment} page={PAGE.MESSAGES} {...props} />*/}
        {/*<InvitationsMenuItem title="Invitations" icon={faUserPlus} page={PAGE.INVITATIONS} {...props} />*/}
        <div className="flex-grow" />
        <UserMenu history={history} />
        {/*<hr className="border-b border-gray-100 opacity-25 my-0 py-0"/>*/}
        {/*<MenuItem title="Logout" icon={faSignOutAlt} onClick={onLogout} {...props} />*/}
    </div>
}
