import React, {useEffect, useState} from "react";
import {Modal} from "./core/Modal/Modal";
import axios from "axios";
import {findSelfLink} from "../util/HateoasUtil";
import {useSelector} from "react-redux";
import {selectServerUri} from "../store/reducers/config";
import {executeRequest} from "../util/RequestUtil";
import {ErrorUserMessage} from "./core/UserMessage";
import {LoadingSpinner} from "./LoadingSpinner";
import {Select} from "./core/Select/Select";
import {FieldLabel} from "./core/FieldLabel/FieldLabel";


const tournamentFileTypes = [{
    value: "",
    display: "Please select"
},{
    value: "TRF16",
    display: "FIDE TRF16 file"
},{
    value: "VEGA",
    display: "Vega file"
}];

export const TournamentImportModal = ({tournament, visible, onUpdated, onClose, creating = false}) => {

    const [file, setFile] = useState(null);
    const [pairingsFile, setPairingsFile] = useState(null);
    const [playersFile, setPlayersFile] = useState(null);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(false);

    const [fileType, setFileType] = useState("");

    const serverUri = useSelector(selectServerUri);

    const onFileChange = event => {
        if (event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    }
    const onPairingsFileChange = event => {
        if (event.target.files.length > 0) {
            setPairingsFile(event.target.files[0]);
        }
    }
    const onPlayersFileChange = event => {
        if (event.target.files.length > 0) {
            setPlayersFile(event.target.files[0]);
        }
    }

    const onSave = () => {
        const formData = new FormData();
        formData.append("fileType", fileType);
        formData.append("file", file);
        formData.append("pairingsFile", pairingsFile);
        formData.append("playersFile", playersFile);

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const uri = tournament ?
            findSelfLink(tournament) + "/vega" :
            serverUri + "/api/tournaments/fromVega";

        executeRequest(axios.post(uri, formData, config), setSaving, setError, () => {
            onUpdated();
        });
    }

    useEffect(() => {
        setError(null);
    }, [visible]);

    return <Modal
        isOpen={visible}
        title={tournament ? "Update tournament" : "Create tournament"}
        saveText={tournament ? "Update" : "Create"}
        onSave={onSave}
        onClose={onClose}>

        <div className="mb-4">
            <Select
                label="File type"
                emptyOption={false}
                value={fileType}
                data={tournamentFileTypes}
                keyValue={t => t.value}
                displayValue={t => t.display}
                onValueChanged={setFileType}
            />
        </div>

        {fileType === "VEGA" && <>
            <FieldLabel label="Select Vega tournament file (the extension is .vegx)" />
            <div className="mt-1">
                <input type="file" accept=".vegx" onChange={onFileChange} />
            </div>
        </>}

        {fileType === "TRF16" && <>
            <FieldLabel label="Select TRF16 file (optional)" />
            <div className="mt-1">
                <input type="file" onChange={onFileChange} />
            </div>
            {!creating && <>
                <div className="mt-3">
                    <FieldLabel label="Select pairings file (optional)" />
                    <div className="mt-1">
                        <input type="file" onChange={onPairingsFileChange} />
                    </div>
                </div>
                <div className="mt-3">
                    <FieldLabel label="Select players export (optional)" />
                    <div className="mt-1">
                        <input type="file" onChange={onPlayersFileChange} />
                    </div>
                </div>
            </>}
        </>}

        <LoadingSpinner visible={saving} title="Saving..." compact={true} className="mt-4" />

        <ErrorUserMessage visible={error} message={error ? "Error uploading new file" : null} className="mt-2" />
    </Modal>
}
