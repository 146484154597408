import {findLink} from "./HateoasUtil";

export const rsvpLink = (entity) => {
    return findLink(entity, "rsvp") ?? findLink(entity, "replyToInvite");
}

export const rsvpStatus = (entity) => {
    return entity?.rsvpStatus ?? entity?.userStatus;
}

export const isVegaTournament = (tournament) => {

    return tournament?.source === "VEGA";
}


export const tournamentStatusStartable = (tournament) => {

    const VALID_STATUSES = ["CREATING", "ACCEPTING_ENTRIES"];
    return VALID_STATUSES.indexOf(tournament.status) >= 0;
}


const TIEBREAK_SHORT_NAMES = {
    ARO: "ARO",
    AROC_CUT1: "ARO1",
    BUCHHOLZ: "Buc",
    BUCHHOLZ_CUT1: "Buc1",
    DIRECT_ENCOUNTER: "DirE",
}

export const formatTiebreakShort = (tiebreak) => {
    return TIEBREAK_SHORT_NAMES[tiebreak] ?? tiebreak;
}
