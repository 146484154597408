import axios from "axios";

export const executeRequest = (requestPromise, setLoading, setError, setData) => {
    setLoading(true);
    setError(null);
    requestPromise
        .then(res => {
            setData(res.data);
            setLoading(false);
        })
        .catch(err => {
            setError(err);
            setLoading(false);
        });
}

export const HEADER_CONTENT_JSON = {
    headers: {
        'Content-Type': 'application/json'
    }
};

export const addPaginationParams = (uri, page) => {
    const separator = uri.indexOf("?") >= 0 ? "&" : "?";
    return uri + separator + "page=" + page;
}

export const query = async (queryPromise) => {
    const response = await queryPromise;
    return response.data;
};


export const toOpenResource = (uri, convert) => {
    return (uri && convert) ? uri.replace("/api/", "/open/") : uri;
}

export const axiosInstance = (openResource) => {
    return openResource ? axios.create() : axios;
}
