import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {selectServerUri} from "../../store/reducers/config";
import axios from "axios";
import {query} from "../../util/RequestUtil"
import {findLink} from "../../util/HateoasUtil";

const initialState = {
    invitations: [],
    fetching: false,
    fetchingError: null,
    saving: false,
    savingError: null,
}


export const replyToInvitation = createAsyncThunk(
    'invitationsPage/replyToInvitation',
    async (arg, {dispatch, getState}) => {

        const data = {
            response: arg.response,
        }

        const result = await query(axios.post(findLink(arg.invitation, "reply"), data));

        dispatch(fetchInvitations());

        return result;
    });

export const fetchInvitations = createAsyncThunk(
    'invitationsPage/fetchInvitations',
    async (arg, {dispatch, getState}) => {
        return await query(axios.get(selectServerUri(getState()) + "/api/invites"));
    });

const invitationsPage = createSlice({
    name: 'invitationsPage',
    initialState: initialState,
    reducers: {
        clear: (state, action) => {
            return initialState;
        },
    },
    extraReducers: {
        [replyToInvitation().pending]: (state, action) => {
            state.saving = true;
            state.savingError = null;
        },
        [replyToInvitation.fulfilled]: (state, action) => {
            state.saving = false;
        },
        [replyToInvitation.rejected]: (state, action) => {
            state.saving = false;
            state.savingError = "Error sending response";
        },

        [fetchInvitations.pending]: (state) => {
            state.fetching = true;
            state.fetchingError = null;
        },
        [fetchInvitations.fulfilled]: (state, action) => {

            state.invitations = action.payload.invitations;
            state.fetching = false;
        },
        [fetchInvitations.rejected]: (state) => {
            state.fetching = false;
            state.fetchingError = "Error fetching invitations";
        },
    }
})

export const { clear } = invitationsPage.actions

export default invitationsPage.reducer


export const selectInvitationsPageInvitations = state => state.invitationsPage.invitations;
export const selectInvitationsPageFetching = state => state.invitationsPage.fetching;
export const selectInvitationsPageFetchingError = state => state.invitationsPage.fetchingError;
export const selectInvitationsPageSaving = state => state.invitationsPage.saving;
export const selectInvitationsPageSavingError = state => state.invitationsPage.savingError;




