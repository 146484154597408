import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    clear,
    fetchInvitations,
    replyToInvitation,
    selectInvitationsPageFetching,
    selectInvitationsPageFetchingError,
    selectInvitationsPageInvitations,
    selectInvitationsPageSaving,
    selectInvitationsPageSavingError
} from "./InvitationsPageSlice";
import {PageContainer} from "../../components/layout/PageContainer/PageContainer";
import {Button} from "../../components/core/Button/Button";
import {ErrorUserMessage} from "../../components/core/UserMessage";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import {RefreshButton} from "../../components/RefreshButton";


const InviteList = ({invitations, nameHeader, onResponse}) => {

    if (invitations.length === 0) {
        return null;
    }

    return <div>
        <div className="mt-4">
            <p className="text-xl font-bold">{nameHeader} Invitations</p>
        </div>

        <table className="bg-white mt-2">
            <thead>
            <tr>
                <th className="px-4 py-2">{nameHeader} Name</th>
                <th className="px-4 py-2">Invited by</th>
                <th className="px-4 py-2"/>
                <th/>
            </tr>
            </thead>
            <tbody>
            {invitations.map(i => <tr key={i.uuid}>

                <td className="border px-4 py-2">{i.entityName}</td>
                <td className="border px-4 py-2">{i.invitedBy}</td>
                <td>
                    <Button color="green" onClick={() => onResponse(i, "ACCEPTED")}>Accept</Button>

                    <Button className="ml-2" color="red" onClick={() => onResponse(i, "DECLINED")}>Decline</Button>
                </td>
            </tr>)}
            </tbody>
        </table>
    </div>

}

export const InvitationsPage = () => {

    const dispatch = useDispatch();

    const [tournamentInvites, setTournamentInvites] = useState([]);
    const [clubInvites, setClubInvites] = useState([]);
    const [adhocEventInvites, setAdhocEventInvites] = useState([]);

    const invitations = useSelector(selectInvitationsPageInvitations);
    const fetching = useSelector(selectInvitationsPageFetching);
    const fetchingError = useSelector(selectInvitationsPageFetchingError);
    const saving = useSelector(selectInvitationsPageSaving);
    const savingError = useSelector(selectInvitationsPageSavingError);


    const onReplyToInvite = (invite, response) => {
        dispatch(replyToInvitation({
            invitation: invite,
            response
        }))
    }

    const onFetchInvitations = useCallback(() => {
        dispatch(clear());
        dispatch(fetchInvitations());
    }, [dispatch]);

    useEffect(() => {
        onFetchInvitations();
    }, [onFetchInvitations]);

    useEffect(() => {
        setTournamentInvites(invitations.filter(i => i.entityType.startsWith("Tournament")));
        setClubInvites(invitations.filter(i => i.entityType.startsWith("Club")));
        setAdhocEventInvites(invitations.filter(i => i.entityType.startsWith("AdhocEvent")));
    }, [invitations]);


    return <PageContainer>
        <div className="mt-4 flex-row">
            <p className="text-3xl">Invitations</p>
            <RefreshButton onClick={() => onFetchInvitations()}/>
        </div>

        {invitations.length === 0 && <p className="font-bold">No pending invitations</p>}

        <div className="mt-4">
            <ErrorUserMessage visible={fetchingError != null} message={fetchingError} />
            <ErrorUserMessage visible={savingError != null} message={savingError} />

            <LoadingSpinner visible={fetching} title="Fetching invitations" />
            <LoadingSpinner visible={saving} title="Saving response" />
        </div>

        <InviteList invitations={tournamentInvites} nameHeader={"Tournament"} onResponse={onReplyToInvite} />
        <InviteList invitations={clubInvites} nameHeader={"Club"} onResponse={onReplyToInvite} />
        <InviteList invitations={adhocEventInvites} nameHeader={"Events"} onResponse={onReplyToInvite} />

    </PageContainer>
}
