import {InputTextField} from "../../components/core/InputTextField/InputTextField";
import React, {useState} from "react";
import {Button} from "../../components/core/Button/Button";
import {PageContainer} from "../../components/layout/PageContainer/PageContainer";
import {useDispatch} from "react-redux";
import {startAnalysis} from "./GameAnalysisPageSlice";
import {UserGameList} from "./UserGameList";


export const GameAnalysisPage = () => {

    const dispatch = useDispatch();

    const [username, setUsername] = useState("");

    const onAnalyse = () => {
        dispatch(startAnalysis(username));
    }

    return <PageContainer>
        <div>
            <InputTextField label="Chess.com username" value={username} onChange={setUsername} />
            <Button onClick={onAnalyse} color="green">Analyse Games</Button>
        </div>
        <div>
            <UserGameList />
        </div>
    </PageContainer>
}
