import React from "react";
import {AdhocEventList} from "../../components/AdhocEventList/AdhocEventList";

export const ManageClubEvents = ({readonly = true, club, history}) => {

    return <div className="p-4 mb-4 bg-white">
        <AdhocEventList readonly={readonly} history={history} runBy={club} />
    </div>

}
