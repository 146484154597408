import React from "react";
import {BaseTile} from "./BaseTile";
import {RsvpButton} from "../../components/Rsvp/RsvpButton";
import {useHistory} from "react-router-dom";


export const AdhocEventTile = ({event, onUpdated}) => {

    const history = useHistory();

    const adhocEvent = event.source;

    return <BaseTile event={event} onClick={() => history.push("/events/edit/" + event.source.uuid)}>
        <div>
            <span className="font-bold">Location:</span>
            <span className="ml-3">{adhocEvent.locationDesc ? adhocEvent.locationDesc : "Not specified"}</span>
        </div>
        <div className="mt-2 flex-row">
            {adhocEvent.numberInvited} invited
            <RsvpButton className="ml-4" event={adhocEvent} onUpdated={onUpdated} />
        </div>
    </BaseTile>

}
