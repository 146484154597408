import React, {useState} from "react";
import {ErrorUserMessage} from "../../core/UserMessage";
import {CircleLoader} from "react-spinners";
import {Button} from "../../core/Button/Button";
import {tournamentStatusStartable} from "../../../util/EntityUtil";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {executeRequest} from "../../../util/RequestUtil";
import axios from "axios";
import {findLink} from "../../../util/HateoasUtil";

export const StartTournamentButton = ({tournament, refreshTournament}) => {

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);


    const tournamentType = tournament.type ?? "SWISS_SYSTEM";

    const onStartTournament = () => {
        executeRequest(axios.post(findLink(tournament, "status"), {status: "STARTED"}), setSaving, setError, () => {
            refreshTournament();
        });
    }

    return tournamentStatusStartable(tournament) && <>
        <div className="flex flex-row items-center">
            <Button compact={true} saving={saving} onClick={onStartTournament}>Create Rounds</Button>

            {!saving && <>
                {(tournamentType === "ROUND_ROBIN" || tournamentType === "DOUBLE_ROUND_ROBIN") && <div className="ml-2">
                    <FontAwesomeIcon icon={faExclamationTriangle} color="#dd6b20" />
                    <span className="font-light text-sm">You won't be able to add players after creating the rounds</span>
                </div>}
            </>}


            <div className="ml-2 -mt-4">
                <CircleLoader
                    loading={saving}
                    size={25}
                    color={"#4a148c"} />
            </div>

        </div>

        <div className="mt-2">
            <ErrorUserMessage visible={error} message="Error starting tournament, check that all details are filled in, and that you've added players" />
        </div>
    </>;
}
