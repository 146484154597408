import React, {useCallback, useEffect, useMemo, useState} from "react";
import isHotkey from 'is-hotkey'

import {createEditor, Transforms,} from 'slate'
import {Editable, Slate, withReact} from 'slate-react'
import {Toolbar} from "./editorComponents";
import {withHistory} from "slate-history";
import {
    BlockButton,
    Element,
    Leaf,
    MarkButton,
    TextColorButton,
    toggleMark
} from "../../components/slate/SlatePrimitives";

// Add the initial value.
export const INITIAL_VALUE = [
    {
        type: 'paragraph',
        children: [{ text: ' ' }],
    },
];

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

export const PageEditor = ({content, onChange}) => {

    const [readonly, ] = useState(false);

    // const [editor] = useState(() => withReact(createEditor()))

    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])
    const editor = useMemo(() => withHistory(withReact(createEditor())), [])

    useEffect(() => {
        if (editor) {

            Transforms.select(editor, [0, 0]);
            editor.children = content;
            editor.onChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);

    return <div>

        {/*<ToggleButton title="Readonly" onChange={setReadonly} compact={true} />*/}

        <div>

        </div>


        <div className="p-4 pt-2" style={{width: "800px", fontSize: "small"}}>
            <Slate
                editor={editor}
                value={content ?? INITIAL_VALUE}
                onChange={value => {
                    const isAstChange = editor.operations.some(
                        op => 'set_selection' !== op.type
                    )
                    if (isAstChange) {
                        onChange(value);
                    }
                }}>
                {!readonly && <Toolbar>
                    <MarkButton format="bold" icon="format_bold" />
                    <MarkButton format="italic" icon="format_italic" />
                    <MarkButton format="underline" icon="format_underlined" />
                    <MarkButton format="code" icon="code" />
                    <BlockButton format="heading-one" icon="looks_one" />
                    <BlockButton format="heading-two" icon="looks_two" />
                    <BlockButton format="block-quote" icon="format_quote" />
                    <BlockButton format="numbered-list" icon="format_list_numbered" />
                    <BlockButton format="bulleted-list" icon="format_list_bulleted" />
                    <BlockButton format="left" icon="format_align_left" />
                    <BlockButton format="center" icon="format_align_center" />
                    <BlockButton format="right" icon="format_align_right" />
                    <BlockButton format="justify" icon="format_align_justify" />

                    {/**/}
                    <TextColorButton format="text_color" icon="format_color_text" />
                    <TextColorButton format="background_color" icon="format_color_fill" />
                </Toolbar>}
                <Editable
                    readOnly={readonly}
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder="Enter page contents…"
                    spellCheck
                    autoFocus
                    onKeyDown={event => {
                        for (const hotkey in HOTKEYS) {
                            if (isHotkey(hotkey, event)) {
                                event.preventDefault()
                                const mark = HOTKEYS[hotkey]
                                toggleMark(editor, mark)
                            }
                        }
                    }}/>
            </Slate>
        </div>
    </div>
}


//
// const initialValue: Descendant[] = [
//     {
//         type: 'paragraph',
//         children: [
//             { text: 'This is editable ' },
//             { text: 'rich', bold: true },
//             { text: ' text, ' },
//             { text: 'much', italic: true },
//             { text: ' better than a ' },
//             { text: '<textarea>', code: true },
//             { text: '!' },
//         ],
//     },
//     {
//         type: 'paragraph',
//         children: [
//             {
//                 text:
//                     "Since it's rich text, you can do things like turn a selection of text ",
//             },
//             { text: 'bold', bold: true },
//             {
//                 text:
//                     ', or add a semantically rendered block quote in the middle of the page, like this:',
//             },
//         ],
//     },
//     {
//         type: 'block-quote',
//         children: [{ text: 'A wise quote.' }],
//     },
//     {
//         type: 'paragraph',
//         align: 'center',
//         children: [{ text: 'Try it out for yourself!' }],
//     },
// ]
