import React, {useCallback, useEffect, useState} from 'react';

import firebase, {firebaseAuthActions} from '../../../Firebase';
import * as axios from "axios";
import {LoadingSpinner} from "../../LoadingSpinner";
import {useDispatch} from "react-redux";

export const Firebase = ({children}) => {
    const dispatch = useDispatch();

    const [waitingForAuth, setWaitingForAuth] = useState(true);

    const onAuthChanged = useCallback(event => {

        setWaitingForAuth(false);
        dispatch(event);
    }, [dispatch]);

    useEffect(() => {
        return firebaseAuthActions()
            .subscribe(
                onAuthChanged,
                err => {throw err},
                () => {}
            );
    }, [onAuthChanged]);

    useEffect(() => {

        axios.interceptors.request.use(config => {
            const user = firebase.auth().currentUser;
            if (user) {
                return user.getIdToken()
                    .then(token => {
                        config.headers.authorization = 'Bearer ' + token;
                        return Promise.resolve(config);
                    })
            } else {
                return firebase.auth()
                    .signInAnonymously()
                    .then(cred => cred.user.getIdToken())
                    .then(token => {
                        config.headers.authorization = 'Bearer ' + token;
                        return Promise.resolve(config);
                    })

            }
        }, error => {Promise.reject(error)})
    }, [])


    if (waitingForAuth) {
        return <LoadingSpinner title="Refreshing details..."/>
    } else {
        return <>
            {children}
        </>
    }

}
