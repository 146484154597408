import React, {useEffect, useState} from "react";
import {FieldLabel} from "../../core/FieldLabel/FieldLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDown, faArrowUp, faPen} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "../../core/Modal/Modal";
import {Button} from "../../core/Button/Button";


const TIEBREAKS = {
    ARO: "Average rating of opponents",
    AROC_CUT1: "Average rating of opponents - cut 1",
    BUCHHOLZ: "Buchholz",
    BUCHHOLZ_CUT1: "Buchholz - cut 1",
    DIRECT_ENCOUNTER: "Direct encounter",
};

const SelectedTiebreak = ({name, onUp, onDown}) => {
    return <div className="p-2 m-1 font-bold">
        <Button disabled={onUp == null} color="blue" compact={true} onClick={() => {
            if (onUp) {
                onUp(name)
            }}
        } icon={faArrowUp}/>
        <Button disabled={onDown == null} className="ml-1" color="blue" compact={true} onClick={() => {
            if (onDown) {
                onDown(name)
            }
        }} icon={faArrowDown}/>
        <span className="ml-2">{TIEBREAKS[name]}</span>
    </div>
}

const UnselectedTiebreak = ({name, onAdd}) => {
    return <div className="p-2 m-1 font-bold">
        <Button color="blue" compact={true} onClick={() => onAdd(name)}>Add</Button>
        <span className="ml-2">{TIEBREAKS[name]}</span>
    </div>
}


export const TiebreakSelectionField = ({tournament, editMode, onChange}) => {

    const [tiebreaks, setTiebreaks] = useState([]);

    const [showEditor, setShowEditor] = useState(false);

    const updateTiebreaks = () => {
        setShowEditor(false);
        setTiebreaks(tiebreaks);
        onChange(tiebreaks);
    }

    useEffect(() => {
        setTiebreaks(tournament.tiebreaks ?? []);
    }, [tournament]);

    const onAdd = (t) => {
        setTiebreaks([...tiebreaks, t]);
    }

    const available = Object.keys(TIEBREAKS)
        .filter(t => tiebreaks == null || !tiebreaks.includes(t));

    const onUp = (t) => {

        const idx = tiebreaks.indexOf(t);

        if (idx > 0) {

            const copy = [...tiebreaks];

            const element = copy.splice(idx, 1)[0];

            copy.splice(idx - 1, 0, element);

            setTiebreaks(copy);

        }
    }

    const onDown = (t) => {

        const idx = tiebreaks.indexOf(t);

        if (idx + 1 < tiebreaks.length) {

            const copy = [...tiebreaks];

            const element = copy.splice(idx, 1)[0];

            copy.splice(idx + 1, 0, element);

            setTiebreaks(copy);

        }
    }

    return <div className="flex flex-row items-center">
        <div className="flex flex-row items-center" onClick={() => setShowEditor(true)} style={{width: "150px"}}>
            <FieldLabel label="Tiebreaks" />
            {editMode && <div className="ml-2">
                <FontAwesomeIcon icon={faPen} />
            </div>}
        </div>
        {tiebreaks && tiebreaks.length > 0 && <ol style={{paddingInlineStart: "20px", marginBlockStart: "0px", marginBlockEnd: "0px"}}>
            {tiebreaks.map(t => <li className="text-sm text-gray-900 ml-4 list-decimal">{TIEBREAKS[t]}</li>)}
        </ol>}
        {(tiebreaks == null || tiebreaks.length === 0) && <div className="ml-2 text-sm text-gray-700 italic">
            Tiebreaks not selected
        </div>}

        <Modal isOpen={editMode && showEditor} title="Edit tiebreaks"
               closeText="Done"
               onClose={updateTiebreaks}>
            <div>
                <div>
                    <span className="font-bold text-lg">Current tiebreaks</span>
                    {tiebreaks?.length > 0 && <span className="font-light text- text-blue-600 underline italic ml-4 cursor-pointer" onClick={() => setTiebreaks([])}>clear</span>}
                </div>
                {tiebreaks && tiebreaks.map((t, idx) => <SelectedTiebreak
                    key={t}
                    name={t}
                    onUp={idx > 0 ? onUp : null}
                    onDown={idx + 1 < tiebreaks.length ? onDown : null} />)}
                {(tiebreaks == null || tiebreaks.length === 0) && <div className="ml-2">No tiebreaks selected</div>}

                <div className="font-bold text-lg mt-2">Available tiebreaks</div>
                {available
                    .map(t => <UnselectedTiebreak name={t} onAdd={onAdd} />)}
                {available.length === 0 && <div className="ml-2">All tiebreaks added</div>}
            </div>
        </Modal>

    </div>


}
