import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios";
import {selectServerUri} from "../reducers/config";
import {query} from "../../util/RequestUtil";
import {findLink} from "../../util/HateoasUtil";

const applyUserDetails = (state, payload) => {
    // state.user.name = payload.username;
    // state.user.email = payload.email;
    //state.user.pictureUri = payload.pictureUri;

    state.user.uid = payload.user.uid;
    state.user.emailVerified = payload.user.emailVerified;
    state.user.token = payload.token;
    state.user.isAnonymous = payload.user.isAnonymous;

};

export const onUserLoggedIn = createAsyncThunk(
    'authentication/onUserLoggedIn',
    async (arg, {dispatch, getState}) => {

        dispatch(userLoggedIn(arg))

        return await query(axios.get(selectServerUri(getState()) + "/api/users"));
    });

const authentication = createSlice({
    name: 'authentication',
    initialState: {user: null},
    reducers: {
        userLoggedIn: (state, action) => {
            state.user = {};
            state.user.roles = [];
            applyUserDetails(state, action.payload);
        },
        userReloaded: (state, action) => {
            console.log("User reloaded: ", action.payload);
            applyUserDetails(state, action.payload);
        },
        userLoggedOut: (state) => {
            console.log("User logged out");
            state.user = null;
        },
        setUserProfile: (state, action) => {
            state.profile = action.payload;
        }
    },
    extraReducers: {

        [onUserLoggedIn.pending]: (state) => {
            state.saving = true;
        },
        [onUserLoggedIn.fulfilled]: (state, action) => {
            state.saving = false;
            state.profile = action.payload;
        },
        [onUserLoggedIn.rejected]: (state) => {
            state.saving = false;
        },
    }
})

export const { userLoggedIn, userReloaded, userLoggedOut, setUserProfile } = authentication.actions

export default authentication.reducer

export const selectLoggedInUser = state => state.authentication.user;
export const selectIsAnonymousUser = state => selectLoggedInUser(state)?.isAnonymous ?? true;

export const selectProfile = state => state.authentication.profile ?? null;
export const selectProfileEmail = state => state.authentication.profile?.email ?? null;
export const selectProfileName = state => state.authentication.profile?.name ?? null;
export const selectUpdateNameLink = state => findLink(state.authentication.profile, "name");
